import React from "react";

function HomeCallto({ homesliders,pdf_file }) {
  return (
    <div>
      {homesliders.map((homeslider) => (
        <div className="full-banner">
          {homeslider.banner_id == "2" && homeslider.banner_status == 1 ? (
            <div className="full-banner">
              <div className="container container-full">
                <div className="banner-img">
                  <a className="hover-images">
                    <img
                      src={homeslider.banner_image_url}
                      alt=""
                      className="img-responsive"
                    />
                  </a>
                  <div className="box-center content ">
                    <a className="tag-title">
                      {homeslider.banner_name}
                    </a>
                    <h3 style={{ color: "#0a3a32" }}>
                      {homeslider.banner_title1}
                    </h3>
                    <h3 style={{ color: "#0a3a32" }}>
                      {homeslider.banner_title2}
                    </h3>
                    <a href={pdf_file.image_url} target="_blank" className="zoa-btn btn-dis">
                      Discover Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default HomeCallto;
