import React, { useEffect, useState } from "react";
import axios from "axios";

import $ from "jquery";
import { useForm } from "react-hook-form";
import { Router, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartTotalCount } from "../../redux/actions/cartActions";
import { baseUrl } from '../../constants';

function Login() {
  const history = useHistory();
  const [logemailmessage, setLogemailmessage] = useState("");
  const [logpasswordmessage, setLogpasswordmessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [emailreg, setEmailReg] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [name, setUsernameReg] = useState("");
  const [passwordreg, setPasswordReg] = useState("");
  const [confirm_password, setCpasswordReg] = useState("");
  const [regemailmessage, setRegemailmessage] = useState("");
  const [regnamemessage, setRegnamemessage] = useState("");
  const [regphonemessage, setregphonemessage] = useState("");
  const [regpasswordmessage, setRegpasswordmessage] = useState("");
  const [regcpasswordmessage, setRegcpasswordmessage] = useState("");
  const [tab, setTab] = useState("login");
  const dispatch = useDispatch();
  const login = (e) => {
    e.preventDefault();
    const cartid = localStorage.getItem("cartdeviceid");
    if (email == "") {
      setLogemailmessage("Please enter Email Id");
    } else {
      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2
          )
        ) {
          setLogemailmessage("Email is not valid");
        } else {
          setLogemailmessage("");
        }
      }
    }
    if (password == "") {
      setLogpasswordmessage("Please enter Password");
    } else {
      setLogpasswordmessage("");
    }

    if (email == "" || password == "") {
      return false;
    }
    axios
      .post(baseUrl+"/login", {
        email: email,
        password: password,
        cart_device_id: cartid,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.token) {
          const tok = response.data.token.replace(
            /^["'](.+(?=["']$))["']$/,
            "$1"
          );
          //localStorage.setItem("user",response.data.token);
          localStorage.setItem("user", tok);
          dispatch(cartTotalCount(response.data.cart_count));
          setLoggedIn(true);
          $(".btn-search-close").click();
          setEmail('')
          setPassword('')
          history.push("/profile");
        } else {
          setLogpasswordmessage(response.data.message);
        }
      })
      .catch(function (error) {
      });
  };

  const register = (e) => {
    e.preventDefault();

    const cartids = localStorage.getItem("cartdeviceid");
    var isValid = 1;
    var reg = /[^0-9a-zA-Z]/;
    if (emailreg == "") {
      setRegemailmessage("Please enter Email Id");
      isValid = 0;
    } else {
      if (typeof emailreg !== "undefined") {
        let lastAtPos = emailreg.lastIndexOf("@");
        let lastDotPos = emailreg.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            emailreg.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            emailreg.length - lastDotPos > 2
          )
        ) {
          setRegemailmessage("Email is not valid");
          isValid = 0;
        } else {
          setRegemailmessage("");
        }
      }
    }
    if (passwordreg == "") {
      setRegpasswordmessage("Please enter Password");
      isValid = 0;
    } else {
      if (reg.test(passwordreg)) {
        if (passwordreg.length < 8) {
          setRegpasswordmessage("Password requires minimum 8 characters");
          isValid = 0;
        } else {
          setRegpasswordmessage("");
        }
      } else {
        setRegpasswordmessage(
          "Password should contain one Capital, one small letter and a special character"
        );
      }
    }

    if (name == "") {
      setRegnamemessage("Please enter Name");
      isValid = 0;
    } else {
      setRegnamemessage("");
    }
    if (phone == "") {
      setregphonemessage("Please enter phone number");
      isValid = 0;
    } else {
      var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      if (!phone.match(phoneno)) {
        setregphonemessage("Please enter a valid phone number(10 digits)");
        isValid = 0;
      } else {
        setregphonemessage("");
      }
    }
    if (confirm_password == "") {
      setRegcpasswordmessage("Please enter Confirm Password");
      isValid = 0;
    } else if (passwordreg != confirm_password) {
      setRegcpasswordmessage("Passwords don't match");
      isValid = 0;
     }else if (confirm_password.length < 5) {
      setRegcpasswordmessage("Confirm Password requires minimum 6 characters");
      isValid = 0;
    } else {
      setRegcpasswordmessage("");
    }

    if (isValid == 0) {
      return false;
    }
    axios
      .post(baseUrl+"/register", {
        name: name,
        email: emailreg,
        password: passwordreg,
        confirm_password: confirm_password,
        cart_device_id: cartids,
        mobile: phone,
      })
      .then(function (response) {
        if(!response.data.success){
        }
        if (response.data.token) {
          const tok = response.data.token.replace(
            /^["'](.+(?=["']$))["']$/,
            "$1"
          );
          localStorage.setItem("user", tok);
          dispatch(cartTotalCount(response.data.cart_count));
          setLoggedIn(true);
          $(".btn-search-close").click();
          setUsernameReg('');
          setEmailReg('');
          setPasswordReg('');
          setCpasswordReg('');
          setPhoneNumber('');
          history.push("/profile");
        } else {
          if (response.data.error.name != "") {
            setRegnamemessage(response.data.error.name);
          }
          if (response.data.error.email != "") {
            setRegemailmessage(response.data.error.email);
          }
          if (response.data.error.password != "") {
            setRegpasswordmessage(response.data.error.password);
          }
          if (response.data.error.mobile != "") {
            setregphonemessage(response.data.error.mobile);
          }
          if (response.data.message != "") {
            setRegcpasswordmessage(response.data.message);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const showTab = (tab) => {
    setTab(tab);
  };
  // const closeLogin = () => {
  //   props.handleClose();
  // };
  //https://backend.shtdevops.xyz/v1/register
  return (
    <div>
      <div className="" style={{ zIndex: 9999, marginTop: "50px" }}>
        <div className="container">
          <div className="search-results-wrapper">
            {/* <div className="btn-search-close" onClick={closeLogin}>
              <i className="ion-ios-close-empty black"></i>
            </div> */}
          </div>
          <div className="account-wrapper">
            <ul className="account-tab text-center">
              <li className={tab == "login" ? "active" : ""}>
                <a data-toggle="tab" onClick={() => showTab("login")}>
                  Login
                </a>
              </li>
              <li className={tab == "register" ? "active" : ""}>
                <a data-toggle="tab" onClick={() => showTab("register")}>
                  Register
                </a>
              </li>
            </ul>
            <div className="tab-content" style={{ border: "none !important" }}>
              <div

                id="login"
                className={
                  "tab-pane fade in " + (tab == "login" ? "active" : "")
                }
              >
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <form
                      onSubmit={login}
                      className="form-customer form-register"
                    >
                      <div className="form-group">
                        <label for="exampleInputEmail2">E-mail</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control form-account"
                          id="exampleInputEmail2"
                          value={email ?? ''}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {logemailmessage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="exampleInputPassword3">Password</label>
                        <input
                          type="password"
                          className="form-control form-account"
                          id="exampleInputPassword3"
                          value={password ?? ''}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {logpasswordmessage}
                        </div>
                      </div>
                      <div className="flex justify-content-between mg-30">
                        {/* <div className="checkbox">
                          <input
                            data-val="true"
                            data-val-required="The Remember me? field is required."
                            id="RememberMe"
                            name="RememberMe"
                            type="checkbox"
                            value="true"
                          />
                          <input
                            name="RememberMe"
                            type="hidden"
                            value="false"
                          />
                          <label for="RememberMe">Remember me</label>
                        </div> */}
                        <a
                          onClick={() => {
                            history.push("/forgot-password");
                            window.location.reload();
                          }}
                          className="text-note no-mg"
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <div className="btn-button-group mg-top-30 mg-bottom-15">
                        <button
                          type="submit"
                          className="zoa-btn btn-login hover-white"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    {/* <div className="social-group-button">
                      <a href="" className="twitter button">
                        <div className="slide">
                          <p>Connect with Twitter</p>
                        </div>
                        <div className="icon">
                          <i className="fa fa-twitter"></i>
                        </div>
                      </a>
                      <a href="" className="facebook button">
                        <div className="slide">
                          <p>Connect with Facebook</p>
                        </div>
                        <div className="icon">
                          <i className="fa fa-facebook"></i>
                        </div>
                      </a>
                    </div> */}
                    <span className="text-note">
                      Don’t have an account?{" "}
                      <a onClick={() => showTab("register")}>Register!</a>
                    </span>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
              <div
                id="register"
                className={
                  "tab-pane fade in " + (tab == "register" ? "active" : "")
                }
              >
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <form
                      onSubmit={register}
                      className="form-customer form-login"
                    >
                      <div className="form-group">
                        <label for="exampleInputEmail7">E-mail *</label>
                        <input
                          type="email"
                          name="emailreg"
                          className="form-control form-account"
                          id="exampleInputEmail7"
                          value={emailreg ?? ''}
                          onChange={(e) => setEmailReg(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {regemailmessage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="zoaname2">Name</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control form-account"
                          id="zoaname2"
                          value={name?? ''}
                          onChange={(e) => setUsernameReg(e.target.value)}
                        />
                        <div className="invalid-feedback">{regnamemessage}</div>
                      </div>
                      <div className="form-group">
                        <label for="zoaname2">Phone Number</label>
                        <input
                          type="text"
                          name="phone"
                          className="form-control form-account"
                          id="zoaname2"
                          value={phone ?? ''}
                          onChange={(e) => {setregphonemessage('');setPhoneNumber(e.target.value)}}
                        />
                        <div className="invalid-feedback">
                          {regphonemessage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="exampleInputPassword2">Password *</label>
                        <input
                          type="password"
                          name="passwordreg"
                          className="form-control form-account"
                          id="exampleInputPassword2"
                          value={passwordreg ?? ''}
                          onChange={(e) => setPasswordReg(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {regpasswordmessage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="exampleInputPassword2">
                          Confirm Password *
                        </label>
                        <input
                          type="password"
                          name="confirm_password"
                          className="form-control form-account"
                          id="exampleInputPassword23"
                          value={confirm_password ?? ''}
                          onChange={(e) => setCpasswordReg(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {regcpasswordmessage}
                        </div>
                      </div>
                      <div className="btn-button-group mg-top-30 mg-bottom-15">
                        <button
                          type="submit"
                          className="zoa-btn btn-login hover-white"
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                    {/* <div className="social-group-button">
                      <a href="" className="twitter button">
                        <div className="slide">
                          <p>Connect with Twitter</p>
                        </div>
                        <div className="icon">
                          <i className="fa fa-twitter"></i>
                        </div>
                      </a>
                      <a href="" className="facebook button">
                        <div className="slide">
                          <p>Connect with Facebook</p>
                        </div>
                        <div className="icon">
                          <i className="fa fa-facebook"></i>
                        </div>
                      </a>
                    </div> */}
                    <span className="text-note">
                      Already have an account?{" "}
                      <a onClick={() => showTab("login")}>Sign In!</a>
                    </span>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
