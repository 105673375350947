import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateSideCartDetails } from "../../redux/actions/cartActions";
import axios from "axios";

import { Link } from "react-router-dom";
import { cartTotalCount } from "../../redux/actions/cartActions";
import { baseUrl } from '../../constants';
import './cart.css'

function Checkout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState("");
  const [price, setPrice] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [placeOrder, setPlaceOrder] = useState(false);
  const [showError, setShowError] = useState(false);
  let errorMsg = {
    firstName: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    //zip: "",
    town: "",
  };
  const [billingError, setBillingError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    //zip: "",
    town: "",
  });
  const [shipingError, setShipingError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    //zip: "",
    town: "",
  });
  // const[addresslist,setaddressList] = useState('')
  let cartid = "";
  let token = "";
  if (typeof window !== "undefined") {
    cartid = localStorage.getItem("cartdeviceid");
    token = localStorage.getItem("user");
    // console.log(token)
  }
  // const state = useSelector(state=>state);
  const [userDetails, setuserDetails] = useState(null);
  const [showErrorPopup,setShowErrorPopup] = useState(false);
              // setErrorPopupMessage(response.data.message)
  const [errorPopupMessage,setErrorPopupMessage] = useState('');
  useEffect(() => {
    axios
      .get(baseUrl+"/getUserAccount", config)
      .then(function (res) {
        // handle success
        // console.log(response.data);
        setuserDetails(res.data.user);
        setAddressType("billing");
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);
  const [valid, setValid] = useState(0);
  const [addressType, setAddressType] = useState("billing");
  const [billingAddressList, setBillingAddressList] = useState(null);
  const [shippingAddressList, setShippingAddressList] = useState(null);
  const [createAccount, setCreateAccount] = useState(true);
  const [shippingAddressForm, setShippingAddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
  const [billingAddressForm, setBillingAddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
 const [termsAgreed, setTermsAgreed] = useState(false);
  const [notCompletelyFilled, setNotCompletelyFilled] = useState(false);
  useEffect(() => {
    axios
      .get(
        baseUrl+`/listAddress?type=billing`,
        config
      )
      .then(function (res) {
        // handle success
        setBillingAddressList(res.data.address_list);
        if(res.data.success===true){//console.log("bpppp")
          setCreateAccount(false);
        }console.log("aaaaaaaaaaaa",createAccount)
        // if(addressType==='shipping') setShippingAddressList(res.data.address_list)
        // setaddressList(res.data.address_list);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    axios
      .get(
        baseUrl+`/listAddress?type=shipping`,
        config
      )
      .then(function (res) {
        // handle success
        setShippingAddressList(res.data.address_list);
        // if(addressType==='shipping') setShippingAddressList(res.data.address_list)
        // setaddressList(res.data.address_list);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [createAccount]);
  useEffect(() => {
    if (billingAddressList !== null) {
      let defaultAddress = billingAddressList.filter((obj) => {
        return obj.default_address === 1;
      });
      if (defaultAddress.length > 0) {
        // setaddressId(defaultAddress[0].address_id);
        // setNotCompletelyFilled(true);
        setBillingAddressForm(defaultAddress[0]);
      }
      else if(billingAddressList.length>0){
        setBillingAddressForm(billingAddressList[0]);
      }
    } else {
      //setaddressId(0);
    }

    if (shippingAddressList !== null) {
      let defaultAddressShipping = shippingAddressList.filter((obj) => {
        return obj.default_address === 1;
      });
      if (defaultAddressShipping.length > 0) {
        // setNotCompletelyFilled(true);
        // setaddressId(defaultAddressShipping[0].address_id);
        setShippingAddressForm(defaultAddressShipping[0]);
      }
      else if(shippingAddressList.length>0){
        setShippingAddressForm(shippingAddressList[0]);
      }
    } else {
      // setaddressId(0);
    }
  }, [billingAddressList, shippingAddressList]);

  const config = { headers: { Authorization: `Bearer ${token}` } };
  const bodyParameters = {
    cart_device_id: cartid,
  };

  useEffect(() => {
    if (token == null) {
      axios
        .post(baseUrl+"/cartList", {
          cart_device_id: cartid,
        })
        .then(function (response) {
          //isLoaded: true,
          setItems(response.data.cart_list.items);
          setPrice(response.data.cart_list);
          localStorage.setItem("cartcount", price.cart_count);

          //    console.log(items)
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(
          baseUrl+"/cartList?token=true",
          bodyParameters,
          config
        )
        .then(function (response) {
          //isLoaded: true,
          setItems(response.data.cart_list.items);
          setPrice(response.data.cart_list);

          localStorage.setItem("cartcount", price.cart_count);

          //    console.log(items)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);
  const fillBillingForm = (type, value) => {
    let temp = { ...billingAddressForm };
    console.log(type,value,value.replace(/\D/g,''));
    if(type==='phone') temp[type] = value.replace(/\D/g,'');
    else temp[type] = value;
    setBillingAddressForm(temp);
  };
  const fillShippingForm = (type, value) => {
    let temp = { ...shippingAddressForm };
    if(type==='phone') temp[type] = value.replace(/\D/g,'');
    else temp[type] = value;
    setShippingAddressForm(temp);
  };
  const setFormError = () => {
    let isValid = 1;
      if (billingAddressForm.first_name == null) {
        setBillingError((prevState) => ({
          ...prevState,
          firstName: "Please enter your first name",
        }));
        isValid = 0;
      } else {
        setBillingError((prevState) => ({
          ...prevState,
          firstName: "",
        }));
        // isValid = 1;
      }
      if (billingAddressForm.last_name == null) {
        setBillingError((prevState) => ({
          ...prevState,
          lastName: "Please enter your Last name",
        }));
        isValid = 0;
      } else {
        setBillingError((prevState) => ({
          ...prevState,
          lastName: "",
        }));
        // isValid = 1;
      }
      if (billingAddressForm.email == null) {
        setBillingError((prevState) => ({
          ...prevState,
          email: "Please enter your email",
        }));
        isValid = 0;
      }else{
        let emailreg = /^\S+@\S+\.\S+$/;
        if(!billingAddressForm.email.match(emailreg)){
          setBillingError((prevState) => ({
            ...prevState,
            email: "email not in correct format",
          }));
          isValid = 0;
        }
      else {
        setBillingError((prevState) => ({
          ...prevState,
          email: "",
        }));
        // isValid = 1;
      }}
      if (billingAddressForm.phone == null) {
        setBillingError((prevState) => ({
          ...prevState,
          phone: "Please enter your phone number",
        }));
        isValid = 0;
      } else {
        var phoneno = /^\d{10}$/;
        if (!billingAddressForm.phone.match(phoneno)) {
          setBillingError((prevState) => ({
            ...prevState,
            phone: "Please enter a valid phone number(10 digits)",
          }));
          isValid = 0;
        } else {
          setBillingError((prevState) => ({
            ...prevState,
            phone: "",
          }));
          // isValid = 1;
        }
      }
      if (billingAddressForm.country == null) {
        setBillingError((prevState) => ({
          ...prevState,
          country: "Please enter your country",
        }));
        isValid = 0;
      } else {
        setBillingError((prevState) => ({
          ...prevState,
          country: "",
        }));
        // isValid = 1;
      }
      if (billingAddressForm.street == null) {
        setBillingError((prevState) => ({
          ...prevState,
          address: "Please enter your address",
        }));
        isValid = 0;
      } else {
        setBillingError((prevState) => ({
          ...prevState,
          address: "",
        }));
        // isValid = 1;
      }
      // if (billingAddressForm.zip == null) {
      //   // setBillingError((prevState) => ({
      //   //   ...prevState,
      //   //   zip: "Please enter your zip code",
      //   // }));
      //   // isValid = 0;
      // } else {
      //   setBillingError((prevState) => ({
      //     ...prevState,
      //     zip: "",
      //   }));
      //   // isValid = 1;
      // }
      if (billingAddressForm.city == null) {
        setBillingError((prevState) => ({
          ...prevState,
          town: "Please enter your town",
        }));
        isValid = 0;
      } else {
        setBillingError((prevState) => ({
          ...prevState,
          town: "",
        }));
        // isValid = 1;
      }

      if (shippingAddressForm.first_name == null) {
        setShipingError((prevState) => ({
          ...prevState,
          firstName: "Please enter your first name",
        }));
        isValid = 0;
      } else {
        setShipingError((prevState) => ({
          ...prevState,
          firstName: "",
        }));
        // isValid = 1;
      }
      if (shippingAddressForm.last_name == null) {
        setShipingError((prevState) => ({
          ...prevState,
          lastName: "Please enter your Last name",
        }));
        isValid = 0;
      } else {
        setShipingError((prevState) => ({
          ...prevState,
          lastName: "",
        }));
        // isValid = 1;
      }
      if (shippingAddressForm.email == null) {
        setShipingError((prevState) => ({
          ...prevState,
          email: "Please enter your email",
        }));
        isValid = 0;
      } else {
        let emailreg = /^\S+@\S+\.\S+$/;
        if(!shippingAddressForm.email.match(emailreg)){
          setShipingError((prevState) => ({
            ...prevState,
            email: "email not in correct format",
          }));
          isValid = 0;
        }else
          setShipingError((prevState) => ({
          ...prevState,
          email: "",
        }));
        // isValid = 1;
      }
      if (shippingAddressForm.phone == null) {
        setShipingError((prevState) => ({
          ...prevState,
          phone: "Please enter your phone number",
        }));
        isValid = 0;
      } else {
        var phoneno = /^\d{10}$/;
        if (!shippingAddressForm.phone.match(phoneno)) {
          setShipingError((prevState) => ({
            ...prevState,
            phone: "Please enter a valid phone number(10 digits)",
          }));
          isValid = 0;
        } else {
          setShipingError((prevState) => ({
            ...prevState,
            phone: "",
          }));
          // isValid = 1;
        }
      }

      if (shippingAddressForm.street == null) {
        setShipingError((prevState) => ({
          ...prevState,
          address: "Please enter your address",
        }));
        isValid = 0;
      } else {
        setShipingError((prevState) => ({
          ...prevState,
          address: "",
        }));
        // isValid = 1;
      }

      if (shippingAddressForm.country == null) {
        setShipingError((prevState) => ({
          ...prevState,
          country: "Please enter your country",
        }));
        isValid = 0;
      } else {
        setShipingError((prevState) => ({
          ...prevState,
          country: "",
        }));
        // isValid = 1;
      }
      // if (shippingAddressForm.zip == null) {
      //   // setShipingError((prevState) => ({
      //   //   ...prevState,
      //   //   zip: "Please enter your zip code",
      //   // }));
      //   //isValid = 0;
      // } else {
      //   setShipingError((prevState) => ({
      //     ...prevState,
      //     zip: "",
      //   }));
      //   // isValid = 1;
      // }
      if (shippingAddressForm.city == null) {
        setShipingError((prevState) => ({
          ...prevState,
          town: "Please enter your town",
        }));
        isValid = 0;
      } else {
        setShipingError((prevState) => ({
          ...prevState,
          town: "",
        }));
        // isValid = 1;
      }
      setValid(isValid);
      // if (isValid == 0) {
      //   setPlaceOrder(false);
      // }
      console.log('isValid   ',isValid);
      return isValid;
	  
  }
  useEffect(() => {
    console.log('placeOrder ',placeOrder)
    if (placeOrder) {
    setFormError();
  }
  }, [placeOrder,billingAddressForm,shippingAddressForm]);
  const [paymentOptionSelected,setpaymentOptionSelected] = useState(false);
  const [noPaymentOptionSelected,setNoPaymentOptionSelected] = useState(false);
  const setPaymentTrue = ()=>{
    setNoPaymentOptionSelected(false);
    setpaymentOptionSelected(true)
  }
  const payOnline = ()=>{
    setPaymentType("pay_online");
    setNoPaymentOptionSelected(false);
    setpaymentOptionSelected(true)
  }
  const cashOnDelivery = ()=>{
    setPaymentType("cash_on_delivery");
    setNoPaymentOptionSelected(false);
    setpaymentOptionSelected(true)
  }
  const placeorder = () => {
    
//console.log('cccc',termsAgreed)
if(!setFormError()){
  setNotCompletelyFilled(true)
  console.log("placecom1",notCompletelyFilled)
  return;
}else{
  setNotCompletelyFilled(false)
  console.log("placecom2",notCompletelyFilled)
}
if(!paymentOptionSelected) return setNoPaymentOptionSelected(true);
  setPlaceOrder(true);
  let termsAgreedTemp = agreetermRef.current.checked;
	  if(!termsAgreedTemp){
      // termsAgreedTemp = true;
		  setTermsAgreed(true)
	  }else{
		  setTermsAgreed(false)
      // termsAgreedTemp = false;
	  }
    //console.log("bim_bill",billingAddressForm)
    //console.log("bim_ship",shippingAddressForm)
    //return false;
    console.log("bbe",shipingError)
    console.log("placeterm",termsAgreedTemp)
    //return false;
    // if (!notCompletelyFilled && termsAgreedTemp &&
    //    Object.keys(billingError).filter(x=>billingError[x]!=='').length===0 && Object.keys(shipingError).filter(x=>shipingError[x]!=='').length===0) {
      if (!notCompletelyFilled && termsAgreedTemp &&
        Object.keys(billingError).filter(x=>billingError[x]!=='').length===0) {
      setPlaceOrder(false);
      
      if(paymentType=='pay_online'){
        axios
          .post(
            baseUrl+"/placeTempOrder",
            {
              // "first_name": firstname,
              // "last_name":lastname,
              // "company":cname,
              // "country": country,
              // "city": city,
              // "street": address,
              // "zip":zip,
              // "phone":phone,
              // "email":email,
              // "address_type":"billing"

              shipping_address: {
                address_id: shippingAddressForm.address_id,
                first_name: shippingAddressForm.first_name,
                last_name: shippingAddressForm.first_name,
                company: shippingAddressForm.company,
                country: shippingAddressForm.country,
                city: shippingAddressForm.city,
                street: shippingAddressForm.street,
                zip: shippingAddressForm.zip,
                phone: shippingAddressForm.phone,
                email: shippingAddressForm.email,
                address_type: "shipping",
                default_address: "1",
          setAsBilling: setAsShippingRef.current.checked == true ? 1 : 0
              },
              billing_address: {
                address_id: billingAddressForm.address_id,
                first_name: billingAddressForm.first_name,
                last_name: billingAddressForm.last_name,
                company: billingAddressForm.company,
                country: billingAddressForm.country,
                city: billingAddressForm.city,
                street: billingAddressForm.street,
                zip: billingAddressForm.zip,
                phone: billingAddressForm.phone,
                email: billingAddressForm.email,
                address_type: "billing",
                default_address: "1",
              },
            },
            config
          )
          .then(function (response) {
            if (response.status == 200) {
              // alert(response.data.message)
              if(response.data.status){
                localStorage.setItem("ref_url", response.data.ref);
                window.location.href = response.data.url;
              }else{
                //alert(response.data.message)
                setShowErrorPopup(true);
                setErrorPopupMessage(response.data.message)
                console.log(response.data.message)
              }
              
            } else {
              // alert(response.data.message)
              history.push("/thankyouorder");
            }
          })
          .catch(function (error) {
            console.log(error);
            setShowErrorPopup(true);
            // setErrorPopupMessage(error.response.data.exception)
          });
      }else if(paymentType=='cash_on_delivery'){

        axios
          .post(
            baseUrl+"/placeOrder",
            {
              // "first_name": firstname,
              // "last_name":lastname,
              // "company":cname,
              // "country": country,
              // "city": city,
              // "street": address,
              // "zip":zip,
              // "phone":phone,
              // "email":email,
              // "address_type":"billing"

              shipping_address: {
                address_id: shippingAddressForm.address_id,
                first_name: shippingAddressForm.first_name,
                last_name: shippingAddressForm.first_name,
                company: shippingAddressForm.company,
                country: shippingAddressForm.country,
                city: shippingAddressForm.city,
                street: shippingAddressForm.street,
                zip: shippingAddressForm.zip,
                phone: shippingAddressForm.phone,
                email: shippingAddressForm.email,
                address_type: "shipping",
                default_address: "1",
                setAsBilling: setAsShippingRef.current.checked == true ? 1 : 0
              },
              billing_address: {
                address_id: billingAddressForm.address_id,
                first_name: billingAddressForm.first_name,
                last_name: billingAddressForm.last_name,
                company: billingAddressForm.company,
                country: billingAddressForm.country,
                city: billingAddressForm.city,
                street: billingAddressForm.street,
                zip: billingAddressForm.zip,
                phone: billingAddressForm.phone,
                email: billingAddressForm.email,
                address_type: "billing",
                default_address: "1",
              },
            },
            config
          )
          .then(function (response) {
            if (response.status == 200) {
              // alert(response.data.message)
              if(response.data.status){
                dispatch(updateSideCartDetails());
                localStorage.setItem("cart_total_count", response.data.cart_count);
                localStorage.setItem("cartcount", response.data.cart_count);
                dispatch(cartTotalCount(response.data.cart_count));
                history.push("/thankyouorder/" + response.data.order_id);
                
              }else{
                //alert(response.data.message)
                setShowErrorPopup(true);
                setErrorPopupMessage(response.data.message)
                console.log(response.data.message)
              }
              
            } else {
              // alert(response.data.message)
              history.push("/thankyouorder");
            }
          })
          .catch(function (error) {
            console.log(error);
            setShowErrorPopup(true);
            // setErrorPopupMessage(error.response.data.exception)
          });

      }

    } else {
      setShowError(true);
      
      //setValid(1);
    }
  };
  useEffect(() => {
    if(billingAddressList!==null && shippingAddressList !==null)
    setNotCompletelyFilled(
      billingAddressForm.first_name === null ||
        billingAddressForm.first_name === "" ||
        billingAddressForm.last_name === null ||
        billingAddressForm.last_name === "" ||
        billingAddressForm.country === null ||
        billingAddressForm.country === "" ||
        billingAddressForm.city === null ||
        billingAddressForm.city === "" ||
        billingAddressForm.street === null ||
        billingAddressForm.street === "" ||
        //billingAddressForm.zip === null ||
        //billingAddressForm.zip === "" ||
        billingAddressForm.phone === null ||
        billingAddressForm.phone === "" ||
        billingAddressForm.email === null ||
        billingAddressForm.email === "" ||
        // shippingAddressForm.address_id === null || shippingAddressForm.address_id === '' ||
        // shippingAddressForm.address_title === null || shippingAddressForm.address_title === '' ||
        shippingAddressForm.first_name === null ||
        shippingAddressForm.first_name === "" ||
        shippingAddressForm.last_name === null ||
        shippingAddressForm.last_name === "" ||
        shippingAddressForm.country === null ||
        shippingAddressForm.country === "" ||
        shippingAddressForm.city === null ||
        shippingAddressForm.city === "" ||
        shippingAddressForm.street === null ||
        shippingAddressForm.street === "" ||
        //shippingAddressForm.zip === null ||
        //shippingAddressForm.zip === "" ||
        shippingAddressForm.phone === null ||
        shippingAddressForm.phone === "" ||
        shippingAddressForm.email === null ||
        shippingAddressForm.email === ""
    );
  }, [billingAddressForm, shippingAddressForm]);
  const changeAddressType = (val) => {
    setAddressType(val);
    if (setAsShippingRef.current.checked === true) {
      setShippingAddressForm(billingAddressForm);
    }
  };
  const agreeterms = () =>{
	  if (agreetermRef.current.checked != true) {
		  setTermsAgreed(true)
	  }else{
      setTermsAgreed(false)
    }
  }
  const setasShipping = () => {
    if (setAsShippingRef.current.checked === true) {
      //billingAddressForm.address_id = 0;
      setShippingAddressForm(billingAddressForm);
      shippingAddressForm.address_id=0;
      //console.log("setsh",billingAddressForm)
      
    } else {
      setShippingAddressForm({
        address_id: null,
        address_title: null,
        first_name: null,
        last_name: null,
        company: null,
        country: null,
        city: null,
        street: null,
        zip: null,
        phone: null,
        email: null,
      });
    }
  };
  const setAsShippingRef = useRef();
const agreetermRef = useRef();
  useEffect(() => {
    if (setAsShippingRef.current.checked === true)
      setShippingAddressForm(billingAddressForm);
     // shippingAddressForm.address_id=0;
  }, [shippingAddressForm]);

  return (
    <div>
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="./img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1
          style={{
            color: "#0a3a32",
            marginBottom: "10px",
            marginRight: "30px",
            textTransform: "uppercase",
          }}
        >
          Checkout
        </h1>
      </div>

      <div className="check-out" style={{ marginTop: "40px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {userDetails === null && (
                <div
                  className="content "
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <h5>
                    Returning Customer?{" "}
                    <span style={{ textTransform: "uppercase" }}>
                      {" "}
                      click
                      <a
                        href="#"
                        style={{ color: "#333", textDecoration: "underline" }}
                      >
                        {" "}
                        here{" "}
                      </a>
                      to login
                    </span>
                  </h5>
                </div>
              )}
              {/* <select className="custom-select" style={{marginTop: '20px',padding: '15px 20px'}}  >
                                           <option value='0' >Add Address</option>
                                       {addresslist && addresslist.map((address)=>(
                                           <option key={address.address_id} value={address.address_id} >Address {address.address_id}</option>
                                           ))}
                                       </select> */}
            </div>

            <div className="col-md-7 col-sm-12">
              <div className="form-name">
                {/* <ul className="account-tab" style={{display: 'flex'}}>
                                <li className="active">
                                        <a data-toggle="tab" href="#billing" aria-expanded="false" style={{color: '#0a3a32'}} onClick={()=>setAddressType('billing')}>Billing Details</a>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" href="#shipping" aria-expanded="false" style={{color: '#0a3a32'}} onClick={()=>setAddressType('shipping')}>Shipping Details</a>
                                    </li>
                                </ul> */}

                <ul className="nav nav-tabs account-tab cartcustomcss">
                  <li className={addressType == "billing" ? "active" : ""}>
                    <a onClick={() => changeAddressType("billing")}>
                      Billing details
                    </a>
                  </li>
                  <li className={addressType == "shipping" ? "active" : ""}>
                    <a onClick={() => changeAddressType("shipping")}>
                      {" "}
                      Shipping Details
                    </a>
                  </li>
                </ul>

                <div
                  className="tab-content"
                  style={{
                    border: "1px solid #eee !important",
                    padding: "10px !important",
                  }}


                >
                  {notCompletelyFilled === true ?
                    <div class="alert alert-danger">
                      Please Fill your billing and shipping address !!!
                    </div>
                  :""}
                  <div
                    id="menu1"
                    className={
                      "tab-pane fade in " +
                      (addressType == "shipping" ? "active" : "")
                    }
                  >
                    {/* <div className="billing tab-pane fade in active" id="shipping"> */}

                    <form action="#" method="post">
                      <div className="row " style={{ marginTop: "20px" }}>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            first name
                            <span style={{ color: "#0a3a32" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="country"
                            placeholder=""
                            className="district"
                            value={shippingAddressForm.first_name ?? ""}
                            onChange={(e) =>
                              fillShippingForm("first_name", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {shipingError.firstName != "" &&
                              shipingError.firstName}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            Last name<span style={{ color: "#0a3a32" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            name="city"
                            style={{textTransform:'none'}}
                            placeholder=""
                            className="district"
                            value={shippingAddressForm.last_name ?? ""}
                            onChange={(e) =>
                              fillShippingForm("last_name", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {shipingError.lastName != "" &&
                              shipingError.lastName}
                          </div>
                        </div>
                      </div>
                      <label className="out">company name</label>
                      <br />
                      <input
                        type="text"
                        style={{textTransform:'none'}}
                        name="city"
                        required
                        className="district"
                        value={shippingAddressForm.company ?? ""}
                        onChange={(e) =>
                          fillShippingForm("company", e.target.value)
                        }
                      />
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            email address{" "}
                            <span style={{ color: "#0a3a32" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="email"
                            required
                            className="country"
                            value={shippingAddressForm.email ?? ""}
                            onChange={(e) =>
                              fillShippingForm("email", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {shipingError.email != "" && shipingError.email}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            phone <span style={{ color: "#0a3a32" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="phone"
                            required
                            className="district"
                            value={shippingAddressForm.phone ?? ""}
                            onChange={(e) =>
                              fillShippingForm("phone", e.target.value)
                            }
                          />
                          {/* <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i> */}
                          <div className="invalid-feedback">
                            {shipingError.phone != "" && shipingError.phone}
                          </div>
                        </div>
                      </div>
                      <label className="">
                        country <span style={{ color: "#0a3a32" }}>*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        style={{textTransform:'none'}}
                        name="country"
                        required
                        className="district"
                        value={shippingAddressForm.country ?? ""}
                        onChange={(e) =>
                          fillShippingForm("country", e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        {shipingError.country != "" && shipingError.country}
                      </div>
                      <label className="out">
                        address <span style={{ color: "#0a3a32" }}>*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        style={{textTransform:'none'}}
                        name="address"
                        required
                        className="district"
                        value={shippingAddressForm.street ?? ""}
                        onChange={(e) =>
                          fillShippingForm("street", e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        {shipingError.address != "" && shipingError.address}
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 ">
                          <label className="out">Postcode/ZIP</label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="zip"
                            required
                            className="country"
                            value={shippingAddressForm.zip ?? ""}
                            onChange={(e) =>
                              fillShippingForm("zip", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {shipingError.zip != "" && shipingError.zip}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            town/city{" "}
                            <span style={{ color: "#0a3a32" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="town"
                            required
                            className="district"
                            value={shippingAddressForm.city ?? ""}
                            onChange={(e) =>
                              fillShippingForm("city", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {shipingError.town != "" && shipingError.town}
                          </div>
                        </div>
                      </div>
                      {createAccount ?
                      <div>
                        <label className="control control--checkbox">
                          Create an account
                          <input type="checkbox" checked="checked" />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                      :""}

                    </form>
                  </div>
                  <div
                    id="home"
                    className={
                      "tab-pane fade in " +
                      (addressType == "billing" ? "active" : "")
                    }
                  >
                    {/* <div className="billing tab-pane fade in " id="billing"> */}
                    <form action="#" method="post">
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            first name<span>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            name="firstname"
                            style={{textTransform:'none'}}
                            placeholder=""
                            required
                            className="district"
                            value={billingAddressForm.first_name ?? ""}
                            onChange={(e) =>
                              fillBillingForm("first_name", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.firstName != "" &&
                              billingError.firstName}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">
                            Last name<span>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="lname"
                            placeholder=""
                            required
                            className="district"
                            value={billingAddressForm.last_name ?? ""}
                            onChange={(e) =>
                              fillBillingForm("last_name", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.lastName != "" &&
                              billingError.lastName}
                          </div>
                        </div>
                      </div>
                      <label className="out">company name</label>
                      <br />
                      <input
                        type="text"
                        style={{textTransform:'none'}}
                        name="city"
                        // required
                        className="district"
                        value={billingAddressForm.company ?? ''}
                        onChange={(e) =>
                          fillBillingForm("company", e.target.value)
                        }
                      />
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label className="out">email address *</label>
                          <br />
                          <input
                            type="email"
                            style={{textTransform:'none'}}
                            name="email"
                            required
                            className="country"
                            value={billingAddressForm.email ?? ''}
                            onChange={(e) =>
                              fillBillingForm("email", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.email != "" && billingError.email}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">phone *</label>
                          <br />
                          <input
                            type="text"
                            name="phone"
                            style={{textTransform:'none'}}
                            required
                            className="district"
                            value={billingAddressForm.phone ?? ''}
                            onChange={(e) =>
                              fillBillingForm("phone", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.phone != "" && billingError.phone}
                          </div>
                          {/* <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i> */}
                        </div>
                      </div>
                      <label className="">country *</label>
                      <br />
                      <input
                        type="text"
                        name="country"
                        style={{textTransform:'none'}}
                        required
                        className="district"
                        value={billingAddressForm.country ?? ''}
                        onChange={(e) =>
                          fillBillingForm("country", e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        {billingError.country != "" && billingError.country}
                      </div>
                      <label className="out">address *</label>
                      <br />
                      <input
                        type="text"
                        name="address"
                        style={{textTransform:'none'}}
                        required
                        className="district"
                        value={billingAddressForm.street ?? ''}
                        onChange={(e) =>
                          fillBillingForm("street", e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        {billingError.address != "" && billingError.address}
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 ">
                          <label className="out">Postcode/ZIP</label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="zip"
                            required={true}
                            className="country"
                            value={billingAddressForm.zip ?? ''}
                            onChange={(e) =>
                              fillBillingForm("zip", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.zip != "" && billingError.zip}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label className="out">town/city *</label>
                          <br />
                          <input
                            type="text"
                            style={{textTransform:'none'}}
                            name="town"
                            required
                            className="district"
                            value={billingAddressForm.city ?? ''}
                            onChange={(e) =>
                              fillBillingForm("city", e.target.value)
                            }
                          />
                          <div className="invalid-feedback">
                            {billingError.town != "" && billingError.town}
                          </div>
                        </div>
                      </div>
                      {createAccount ?
                      <div className="">
                        <label className="control control--checkbox">
                          Create an Account
                          <input type="checkbox" checked="checked" />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                      :""}
                      <div className="">
                        <label className="control control--checkbox">
                          Use same Address for shipping
                          <input
                            type="checkbox"
                            onClick={setasShipping}
                            ref={setAsShippingRef}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-12 ">
              <div className="order" style={{ marginTop: "37px" }}>
                <div className="content-order">
                  <div className="table">
                    <table>
                      <caption style={{ color: "#0a3a32" }}>your order</caption>
                      <thead>
                        <tr>
                          <th style={{ color: "#0a3a32" }}>product</th>
                          <th style={{ textAlign: "center", color: "#0a3a32" }}>
                            QTY
                          </th>
                          <th style={{ textAlign: "end", color: "#0a3a32" }}>
                            total(aed)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items &&
                          items.map((item) => (
                            <tr>
                              <td>
                                {item.product_name} <br />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.quantity}
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {item.sub_total}
                              </td>
                            </tr>
                          ))}

                        {/* <tr style={{display: 'none'}}>
                                                        <td>religion jersey polo shirt</td>
                                                        <td style={{textAlign: 'center'}}>1</td>
                                                        <td style={{textAlign: 'end'}}>20.00</td>
                                                        
                                                        
                                                    </tr> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="content-total">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5
                        className="sub-total"
                        style={{
                          color: "#222",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#0a3a32",
                        }}
                      >
                        sub total
                      </h5>
                      <h5 className="prince1">AED {price.sub_total}</h5>
                    </div>
                    <div
                      className="content-radio"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5
                        style={{
                          color: "#222",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#0a3a32",
                        }}
                      >
                        shipping cost:
                      </h5>
                      <div className="radio">
                        <form action="#" method="post">
                          <span
                            style={{ color: "#333", fontSize: "14px" }}
                          ></span>
                          <span id="prince1">AED {price.shipping_charge}</span>
                          <br />
                        </form>
                      </div>
                    </div>
                    <div
                      className="content-radio"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5
                        style={{
                          color: "#222",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#0a3a32",
                        }}
                      >
                        VAT {price.vat}:
                      </h5>
                      <div className="radio">
                        <form action="#" method="post">
                          <span
                            style={{ color: "#333", fontSize: "14px" }}
                          ></span>{" "}
                          <span id="prince1">AED {price.vat_amount}</span>
                          <br />
                        </form>
                      </div>
                    </div>
                    <div
                      className="total"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="sub-total" style={{ color: "#0a3a32" }}>
                        Total
                      </h5>
                      <h5 className="prince">AED {price.grand_total}</h5>
                    </div>

                    {noPaymentOptionSelected ? 
						          <div className="invalid-feedback">
                            Please select a payment method
                          </div>
						        : ''}

                    <div className="payment">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <label className="control control--radio">
                            Pay Now
                            <input type="radio" name="radio"  onClick={payOnline}/>
                            <div className="control__indicator"></div>
                          </label>
                        </div>

                        <div className="social">
                          <i
                            className="fa fa-cc-paypal"
                            style={{ color: "#888" }}
                          ></i>
                          <i
                            className="fa fa-cc-visa"
                            style={{ color: "#888" }}
                          ></i>
                          <i
                            className="fa fa-cc-mastercard"
                            style={{ color: "#888" }}
                          ></i>
                          <i
                            className="fa fa-cc-discover"
                            style={{ color: "#888" }}
                          ></i>
                          <i
                            className="fa fa-cc-amex"
                            style={{ color: "#888" }}
                          ></i>
                        </div>
                      </div>

                      <hr />
                      <label className="control control--radio">
                        Cash On Delivery
                        <input type="radio" name="radio" onClick={cashOnDelivery}/>
                        <div className="control__indicator"></div>
                      </label>
                      <hr />
                    </div>
                    
                    <div className="payment">
                      <label className="control control--checkbox">
					    <Link
                    to={{
                      pathname: "/terms-and-conditions"
                    }}
					target="_blank" 
					className="agreeterm"
                  >
                        
                          Agree to Terms and Conditions
                        </Link>
						{console.log('termsAgreed',termsAgreed)}
						{termsAgreed ? 
						 <div className="invalid-feedback">
                            Please agree the terms-and-conditions!!!
                          </div>
						: ''}
            
                        <input type="checkbox" onClick={agreeterms}
                            ref={agreetermRef} />
                        <div className="control__indicator"></div>
                      </label>
                    </div>

                    <div className="place-ober">
                      <button
                        className="ober btn zoa-btn"
                        onClick={placeorder}
                        //disabled={
                          // billingAddressForm.address_id === null |||| billingAddressForm.address_id |=== '' ||
                          // billingAddressForm.address_title === null || billingAddressForm.address_title === '' ||
                          //notCompletelyFilled
                        //}
                      >
                        place order
                      </button>
                      <div
        class={`modal fade ${showErrorPopup ? "in" : ""}`}
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={showErrorPopup ? "false" : "true"}
        style={{ display: `${showErrorPopup ? "block" : "none"}` }}
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
      

          <div class="modal-content">
          <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>setShowErrorPopup(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title text-center" id="exampleModalLabel">Error</h4>
       
      </div>
            <div
              class="modal-body"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* ... */}
              <img
                src="img/red-cross.png"
                alt="check"
                style={{ width: "30px", height: "30px", marginRight: "5px" }}
              />{" "}
              <p style={{ margin: "0px", color: "#0a3a32" }}>
                {errorPopupMessage}
              </p>
            </div>
            {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
