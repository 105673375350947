import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { cartTotalCount } from "../../redux/actions/cartActions";
import { useDispatch } from 'react-redux';
import { baseUrl } from '../../constants';


function Order() {
  const history = useHistory();
  const [useraccount, setuserDetails] = useState("");
  const [orderlist, setOrderList] = useState("");

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  let itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(
      `User requested Loading items from ${itemOffset} to ${endOffset}`
    );
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(itemsCount / itemsPerPage));
  }, [itemOffset, itemsPerPage, itemsCount]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    let pageNo;

    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  console.log(orderlist);
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
    // console.log(token)
  }
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.setItem("cart_total_count", 0);
    dispatch(cartTotalCount(0));
    //window.reload();
    history.push("/");
  };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  useEffect(() => {
    axios
      .get(baseUrl+"/getUserAccount", config)
      .then(function (res) {
        // handle success
        // console.log(response.data);
        setuserDetails(res.data.user);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        baseUrl+"/orderList?page=" + page,
        config
      )
      .then(function (res) {
        // handle success
        // console.log(response.data);
        setOrderList(res.data.order_list);
        setItemsCount(res.data.order_count);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [page]);

  return (
    <div>
      <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>My Account</li>
        </ul>
      </div>

      <div className="my-account">
        <div className="container container-content ">
          <div className="row">
            <div className="col-md-2 col-sm-2 howday">
              <div className="titlelt">
                <h2>
                  Hello, <strong> {useraccount.name} !</strong>
                </h2>
                <div className="login">
                  <ul className="nav ">
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/profile")}
                      >
                        <img src="img/Icon_Add.jpg" alt="Icon_User.jpg" />{" "}
                        Address
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => history.push("/orders")}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                      >
                        <img src="img/Icon_Listing.jpg" alt="Icon_User.jpg" />{" "}
                        My orders
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => history.push("change-password")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="img/Icon_Lock.jpg" alt="Icon_User.jpg" />
                        change password
                      </a>
                    </li>
                    <li>
                      <a onClick={logout}>
                        <img src="img/Icon_Off.jpg" alt="Icon_User.jpg" /> log
                        out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-sm-12 porfolio1">
              <div className="tab-content">
                <div id="account-details" className="tab-pane fade in active">
                  <h4 style={{ color: "#0a3a32" }}>
                    <b>Orders</b>
                  </h4>
                  <div className="shopping-cart">
                    <div className="table-responsive">
                      <table className="table cart-table" style={{minHeight:'300px'}}>
                        <thead>
                          <tr>
                            <th className="product-thumbnail">Product</th>
                            <th className="product-name">Order Id</th>
                            <th className="product-name">Order Date</th>
                            <th className="product-name">Items Ordered</th>
                            <th className="product-price">Total</th>
                            <th className="product-quantity">status</th>
                            <th className="product-quantity">Delivery Date</th>
                            {/* <th className="product-subtotal">Total</th>
                                                <th className="product-subtotal">Status</th>
                                                <th className="product-subtotal">EXP.Delivery</th> */}
                            <th className="product-subtotal">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderlist &&
                            orderlist.map((order) => (
                              <tr className="item_cart">
                                <td className=" product-name">
                                  <div className="product-img">
                                    <img
                                      src={order.product_image}
                                      alt="Product"
                                    />
                                  </div>
                                </td>
                                <td className="product-desc">
                                  <div className="product-info">
                                    <a
                                      title=""
                                      className="hover-anchor"
                                      style={{ fontSize: 13 }}
                                    >
                                      {order.order_display_id}{" "}
                                    </a>
                                    <span></span>
                                  </div>
                                </td>
                                <td className="product-same">
                                  <div className="product-info">
                                    <p>{order.ordered_date}</p>
                                  </div>
                                </td>
                                <td className="bcart-quantity single-product-detail">
                                  <div className="cart-qtt text-center">
                                    <p>{order.items_ordered}</p>
                                  </div>
                                </td>
                                <td className="product-same total-price">
                                  <p
                                    className="price"
                                    style={{ fontFamily: "Eina, sans-serif" }}
                                  >
                                    AED {order.grand_total}
                                  </p>
                                </td>
                                <td className="bcart-quantity single-product-detail">
                                  <div className="cart-qtt text-center">
                                    <p>{order.ordered_status}</p>
                                  </div>
                                </td>
                                <td className="bcart-quantity single-product-detail">
                                  <div className="cart-qtt text-center">
                                    <p>{order.delivery_date}</p>
                                  </div>
                                </td>
                                <td className="total-price">
                                  <Link
                                    to={{
                                      pathname:
                                        "/orderdetails/" + order.order_id,
                                    }}
                                  >
                                    <button
                                      className=""
                                      style={{
                                        background: "none",
                                        border: "1px solid",
                                        outline: "none",
                                        padding: "5px 20px",
                                      }}
                                    >
                                      View Details
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
                <div>{/* <p>No items ordered</p> */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
