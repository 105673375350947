import React from "react";
import { useHistory } from "react-router-dom";
import Header from "./Layout/Header";
import Navigation from "./Layout/Navigation";
import SideMenu from "./Layout/SideMenu";
import SideCart from "./Layout/SideCart";
import Footer from "./Layout/Footer";
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { triggerLogout } from "../redux/actions/cartActions";
const Layout = (props) => {

  return (
    <React.Fragment>
      <div style={{display:'flex',flexDirection:'column',minHeight:'100vh'}}>
      <Header />
      <SideMenu />
      {/* <SideCart /> */}

      <div>{props.children}</div>
      <div style={{marginTop:'auto'}}>
      <Footer />
      </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
