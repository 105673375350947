import * as types from "../constants/types";

const defaultState = {
  cart: [],
  cart_count:
    typeof window !== "undefined"
      ? localStorage.getItem("cart_total_count") != ""
        ? localStorage.getItem("cart_total_count")
        : 0
      : 0,
  cart_loading: false,
  cart_error: null,
  cart_list: [],
};

export const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.CART_COUNT:
      return {
        ...state,
        cart_count: action.payload,
        cart_loading: true,
        cart_error: null,
      };
    case "SET_CART_DATA": {
      console.log("inside SET_CART_DATA");
      let temp = JSON.parse(JSON.stringify(state));
      console.log(state);
      temp.cart_list = action.payload.data;
      console.log(state, temp);

      return temp;
    }
    default:
      return state;
  }
};
