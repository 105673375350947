import React, { useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { cartTotalCount, updateSideCartDetails } from "../../redux/actions/cartActions";

import { useHistory, useParams } from "react-router-dom";
import { baseUrl,backendUrl } from '../../constants';


function Cart() {
  const history = useHistory();
  const dispatch = useDispatch();
  let cartid = "";
  let token = "";
  let carttotalcount = "";
  if (typeof window !== "undefined") {
    cartid = localStorage.getItem("cartdeviceid");
    token = localStorage.getItem("user");
    carttotalcount = localStorage.getItem("cart_total_count");
  }
  //console.log("ddd===cart", cartid);
  //const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYmFja2VuZC5hcnRlcnVzaC54eXpcL2FwaVwvdjFcL2xvZ2luIiwiaWF0IjoxNjM4ODgyNDIyLCJleHAiOjE2Mzg4ODYwMjIsIm5iZiI6MTYzODg4MjQyMiwianRpIjoiNVZueU40djA0c2NyN09oZiIsInN1YiI6MiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.w58BWoR6LsCNi4hCXNGJYciBrV3FFXzjTbzb3uhEJGM';
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const [cartdeviceid, setcartdevice] = useState("");
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState([]);
  const [qty, setQty] = useState("");
  const [cartiduser, setCartIdUser] = useState("");
  const [itemiduser, setitemiduser] = useState("");
  const [producterror, setProducterrorVar] = useState([]);
  const setProducterror = ({index,message}) => {
   // console.log('index,message   ',index,message);
    let temp = [...producterror];
    let foundindex = producterror.findIndex(x=>x.index===index);
    console.log(foundindex);
    if(foundindex== -1){
      temp.push({index:index,message:message});
    }
    else
    temp[foundindex].message = message;
    setProducterrorVar(temp);
  }
  // console.log(cartid)
  // console.log(price)

  const bodyParameters = {
    cart_device_id: cartid,
  };
  //  const bodyParametersremove = {
  //     cart_device_id:cartid,
  //     item_id:itemid,
  //     cart_id:price.cart_id
  //  };

  //Create handleDecrement event handler
  const checkLogin = () => {
    if (token == null) {
      history.push("/login");
    } else {
      $(".js-user").click();
      history.push("/checkout");
    }
  };

  const handleIncrement = (item_id) => {
    if (token == null) {
      items.map((itm) =>
        //console.log("sss",itm)
        item_id === itm.item_id
          ? axios
              .post(baseUrl+"/cartUpdate", {
                cart_device_id: cartid,
                item_id: item_id,
                cart_id: parseInt(itm.cart_id),
                quantity: parseInt(itm.quantity) + 1,
              })
              .then(
                (response) => {
                  if (response.data.success) {
                    console.log("bim1")
                    setProducterror({index:item_id,message:''});
                    console.log(response);
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
                    localStorage.setItem("cartcount", response.data.cart_list.cart_count);
                    setCartTotalCount(localStorage.getItem("cart_total_count"));
                    dispatch(cartTotalCount(response.data.cart_list.cart_count));
                    console.log("bbbbbb", localStorage.getItem("cart_total_count"));
                  } else {
                    setProducterror({index:item_id,message:response.data.error});
                  }
                },
                (error) => {
                  console.log(error);
                }
              )
          : 0
      );
    } else {
      items.map((itm) =>
        //console.log("sss",itm)
        item_id === itm.item_id
          ? axios
              .post(
                baseUrl+"/cartUpdate?token=true",
                {
                  cart_device_id: cartid,
                  item_id: item_id,
                  cart_id: parseInt(itm.cart_id),
                  quantity: parseInt(itm.quantity) + 1,
                },
                config
              )
              .then(
                (response) => {
                  if (response.data.success) {
                    console.log("bim2")
                    console.log(response);
                    setProducterror({index:item_id,message:''});
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    //console.log("sssss", items);
                    localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
                    localStorage.setItem("cartcount", price.cart_count);
                    setCartTotalCount(localStorage.getItem("cart_total_count"));
                    dispatch(cartTotalCount(response.data.cart_list.cart_count));
                  } else {
                    setProducterror({index:item_id,message:response.data.error});
                  }
                },
                (error) => {
                  console.log(error);
                }
              )
          : 0
      );
    }
  };
  
  const handleDecrement = (item_id) => {
    if (token == null) {
      items.map((itm) =>
        //console.log("sss",itm)
        item_id === itm.item_id
          ? itm.quantity - 1 > 0
            ? axios
                .post(baseUrl+"/cartUpdate", {
                  cart_device_id: cartid,
                  item_id: item_id,
                  cart_id: parseInt(itm.cart_id),
                  quantity: parseInt(itm.quantity) - 1 > 0 ? parseInt(itm.quantity) - 1 : 0,
                })
                .then(
                  (response) => {
                    console.log(response);
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
                    localStorage.setItem("cartcount", price.cart_count);
                    setCartTotalCount(localStorage.getItem("cart_total_count"));
                    dispatch(cartTotalCount(response.data.cart_list.cart_count));
                    if(response.data.success){
                    setProducterror({index:item_id,message:''});
                    }else{
                    setProducterror({index:item_id,message:response.data.error});
                    }
                    //console.log("sssss", items);
                  },
                  (error) => {
                    console.log(error);
                  }
                )
            : axios
                .post(baseUrl+"/cartDelete", {
                  cart_device_id: cartid,
                  item_id: item_id,
                  cart_id: parseInt(itm.cart_id),
                })
                .then(
                  (response) => {
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    localStorage.setItem("cartcount", price.cart_count);
                    localStorage.setItem(
                      "cart_total_count",
                      response.data.cart_list.length == 0
                        ? 0
                        : response.data.cart_list.cart_count
                    );

                    dispatch(
                      cartTotalCount(
                        response.data.cart_list.length == 0
                          ? 0
                          : response.data.cart_list.cart_count
                      )
                    );
                  },
                  (error) => {
                    console.log(error);
                  }
                )
          : 0
      );
    } else {
      items.map((itm) =>
        //console.log("sss",itm)
        item_id === itm.item_id
          ? itm.quantity - 1 > 0
            ? axios
                .post(
                  baseUrl+"/cartUpdate?token=true",
                  {
                    cart_device_id: cartid,
                    item_id: item_id,
                    cart_id: parseInt(itm.cart_id),
                    quantity: parseInt(itm.quantity) - 1 > 0 ? parseInt(itm.quantity) - 1 : 0,
                  },
                  config
                )
                .then(
                  (response) => {
                    console.log(response);
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
                    localStorage.setItem("cartcount", price.cart_count);
                    setCartTotalCount(localStorage.getItem("cart_total_count"));
                    dispatch(cartTotalCount(response.data.cart_list.cart_count));
                    if(response.data.success){
                      setProducterror({index:item_id,message:''});
                      }else{
                      setProducterror({index:item_id,message:response.data.error});
                      }
                    console.log("sssss", items);
                  },
                  (error) => {
                    console.log(error);
                  }
                )
            : axios
                .post(
                  baseUrl+"/cartDelete?token=true",
                  {
                    cart_device_id: cartid,
                    item_id: item_id,
                    cart_id: itm.cart_id,
                  },
                  config
                )
                .then(
                  (response) => {
                    setItems(response.data.cart_list.items);
                    setPrice(response.data.cart_list);
                    localStorage.setItem("cartcount", price.cart_count);
                    localStorage.setItem(
                      "cart_total_count",
                      response.data.cart_list.length == 0
                        ? 0
                        : response.data.cart_list.cart_count
                    );

                    dispatch(
                      cartTotalCount(
                        response.data.cart_list.length == 0
                          ? 0
                          : response.data.cart_list.cart_count
                      )
                    );
                  },
                  (error) => {
                    console.log(error);
                  }
                )
          : 0
      );
    }
  };
  const handleRemove = (itemid) => {
    console.log("hai" + itemid);
    console.log(price.cart_id);

    console.log(itemiduser);
    if (token == null) {
      axios
        .post(baseUrl+"/cartDelete", {
          cart_device_id: cartid,
          item_id: itemid,
          cart_id: price.cart_id,
        })
        .then(
          (response) => {
            console.log(response);
            setItems(response.data.cart_list.items);
            setPrice(response.data.cart_list);
            localStorage.setItem("cartcount", price.cart_count);
            localStorage.setItem(
              "cart_total_count",
              response.data.cart_list.length == 0
                ? 0
                : response.data.cart_list.cart_count
            );

            dispatch(
              cartTotalCount(
                response.data.cart_list.length == 0
                  ? 0
                  : response.data.cart_list.cart_count
              )
            );
            // alert("product add to cart")
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      axios
        .post(
          baseUrl+"/cartDelete?token=true",
          {
            cart_device_id: cartid,
            item_id: itemid,
            cart_id: price.cart_id,
          },
          config
        )
        .then(
          (response) => {
            console.log(response);
            setItems(response.data.cart_list.items);
            setPrice(response.data.cart_list);
            localStorage.setItem("cartcount", price.cart_count);
            localStorage.setItem(
              "cart_total_count",
              response.data.cart_list.length == 0
                ? 0
                : response.data.cart_list.cart_count
            );

            dispatch(
              cartTotalCount(
                response.data.cart_list.length == 0
                  ? 0
                  : response.data.cart_list.cart_count
              )
            );
            // alert("product add to cart")
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    //     const cartid = localStorage.getItem('cartdeviceid')
    //     // console.log(cartid)
    //     const token = localStorage.getItem('token')
    //     // console.log(token)

    //  }

    if (token == null) {
      axios
        .post(baseUrl+"/cartList", {
          cart_device_id: cartid,
        })
        .then(function (response) {
          console.log(response.data.cart_list.items);
          setItems(response.data.cart_list.items);
          setPrice(response.data.cart_list);
          localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
          localStorage.setItem("cartcount", price.cart_count);
          setCartTotalCount(localStorage.getItem("cart_total_count"));
          dispatch(cartTotalCount(response.data.cart_list.cart_count));
          //    console.log(items)
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(
          baseUrl+"/cartList?token=true",
          bodyParameters,
          config
        )
        .then(function (response) {
          if(response.data.success){
            console.log(response.data.cart_list.items);
            setItems(response.data.cart_list.items);
            setPrice(response.data.cart_list);
            localStorage.setItem("cart_total_count",response.data.cart_list.cart_count);
            localStorage.setItem("cartcount", price.cart_count);
            setCartTotalCount(localStorage.getItem("cart_total_count"));
            dispatch(cartTotalCount(response.data.cart_list.cart_count));
            
          }
          if(!response.data.success && !response.data.token){
            if(response.data.error!=='No items in cart')
            localStorage.removeItem("user");
            localStorage.setItem("cart_total_count", 0);
            dispatch(cartTotalCount(0));
            //window.reload();
            // history.push("/");
          }
          
          //    console.log(items)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    // dispatch(updateSideCartDetails());
  }, []);
  const toShop = () => {
    history.push("/shop");
  };
  const [cartTotalCount2,setCartTotalCount ] = useState(localStorage.getItem("cart_total_count"));
  const [cartCount, setCartCount] = useState(null);
  useEffect(() => {
    if (items !== undefined) carttotalcount = items.length;
  }, [items]);
  return (
    <div>
      <div
        class="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="./img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1
          style={{
            color: "#0a3a32",
            marginBottom: "10px",
            textTransform: "uppercase",
          }}
        >
          Shopping Cart
        </h1>
      </div>
      <div
        className="container"
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        {items === undefined || cartTotalCount2 == 0 ? (
          <>
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img
                src={backendUrl+"/public/img/1642477234_empty-cart.png"}
                alt="empty cart"
                style={{ width: "200px" }}
              />
            </span>
            <span
              style={{
                display: "flex",
                width: "100%",
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              {" "}
              Your cart is empty!!!
            </span>
            <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
            <span className="shopping-cart">
              <span className="table-cart-bottom ">
            <button
              onClick={toShop}
              className="btn-update"
              style={{ marginTop: "20px",width:'300px' }}
            >
              Continue Shopping
            </button>
            </span>
            </span>
            </div>
          </>
        ) : (
          <div class="shopping-cart">
            <div class="table-responsive" style={{ overflowX: "visible" }}>
              <table class="table cart-table">
                <thead>
                  <tr>
                    <th class="product-thumbnail">Product</th>
                    <th class="product-name">Description</th>
                    <th class="product-name">Color</th>
                    <th class="product-name">Size</th>
                    <th class="product-price">Price</th>
                    <th class="product-quantity">Quantity</th>
                    <th class="product-subtotal">Total</th>
                    {/* <th class="product-name">Stock</th> */}
                    <th class="product-remove">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.map((item) => (
                      <tr class="item_cart">
                        <td class=" product-name">
                          <div class="product-img">
                            <img src={item.product_image} alt="Product" />
                            <p></p>
                          </div>
                        </td>
                        <td class="product-desc">
                          <div class="product-info">
                            <p>{item.product_name}</p>
                          </div>
                        </td>
                        <td class="product-same">
                          <div class="product-info">
                            <p>{item.color}</p>
                          </div>
                        </td>
                        <td class="product-same">
                          <div class="product-info">
                            <p>{item.size}</p>
                          </div>
                        </td>
                        <td class="product-same total-price">
                          <p
                            class="price"
                            style={{ fontFamily: '"Eina", sans-serif' }}
                          >
                            AED{" "}
                            {item.product_offer_price > 0
                              ? item.product_offer_price
                              : item.product_price}
                          </p>
                        </td>

                        <td class="bcart-quantity single-product-detail">
                          <div className="zoa-qtt" style={{ width: 105 }}>
                            <button
                              type="button"
                              className="quantity-left-minus btn btn-number js-minus"
                              onClick={() => handleDecrement(item.item_id)}
                            ></button>
                            <input
                              type="text"
                              name="number"
                              value={item.quantity}
                              className="product_quantity_number "
                            />

                            <button
                              type="button"
                              className="quantity-right-plus btn btn-number js-plus"
                              data-type="plus"
                              data-field=""
                              onClick={() => handleIncrement(item.item_id)}
                            ></button>
                           {producterror.findIndex(x=>x.index===item.item_id)!==-1 && <div
                              className="invalid-feedback"
                              style={{ fontSize: "10px" }}
                            >
                              {producterror[producterror.findIndex(x=>x.index===item.item_id)].message}
                            </div>}
                          </div>
                          {/* <div class="cart-qtt" >
                                                
                                                  
                                            </div> */}
                        </td>

                        {item.in_stock ? (
                          <td class="total-price">
                            <p
                              class="price"
                              style={{ fontFamily: '"Eina", sans-serif' }}
                            >
                              AED {item.sub_total}
                            </p>
                          </td>
                        ) : (
                          <td class="total-price">
                            <p
                              class="price"
                              style={{ fontFamily: '"Eina", sans-serif' }}
                            >
                              AED{" "}
                              {item.product_offer_price > 0
                                ? item.product_offer_price * item.quantity
                                : item.product_price * item.quantity}
                            </p>
                          </td>
                        )}
                        {/* {item.in_stock ? (
                          <td class="product-same">
                            <div class="product-info">
                              <p>In Stock</p>
                            </div>
                          </td>
                        ) : (
                          <td class="product-same">
                            <div class="product-info">
                              <p>Out of Stock</p>
                            </div>
                          </td>
                        )} */}
                        <td class="product-remove">
                          <a
                            value={item.item_id}
                            onClick={() => {
                              handleRemove(item.item_id);
                            }}
                            class="btn-del hover-anchor"
                            style={{ fontSize: "35px" }}
                          >
                            <i class="ion-ios-close-empty"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div class="table-cart-bottom">
              <div class="row">
                <div class="col-md-7 col-sm-6 col-xs-12">
                  <div class="cart-btn-group">
                    {/* <a href="" class="btn-continue">Continue shopping</a>  */}
                    {/* <a href="" class="btn-clear">clear cart</a>  */}
                  </div>
                  <div class="coupon-group">
                    {/* <form class="form_coupon" action="#" method="post">
                                            <input type="email" value="" placeholder="COUPON CODE" name="EMAIL" id="mail" class="newsletter-input form-control">
                                            <div class="input-icon">
                                                <img src="img/coupon.png" alt="">
                                            </div>
                                        </form>  */}
                    <button
                      onClick={toShop}
                      class="btn-update d-none-mob hidden-xs hidden-sm"
                    style={{ marginTop: "20px" ,width:'300px'}}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
                <div class="col-md-5 col-sm-6 col-xs-12">
                  <div class="cart-text">
                    <div class="cart-element">
                      <p>Subtotal:</p>
                      <p>AED {price.sub_total}</p>
                    </div>
                    <div class="cart-element">
                      <p>Estimated shipping costs:</p>
                      <p>AED {price.shipping_charge}</p>
                    </div>
                    <div class="cart-element">
                      <p>VAT {price.vat}:</p>
                      <p>AED {price.vat_amount}</p>
                    </div>
                    <div class="cart-element text-bold">
                      <p>Grand Total:</p>
                      <p>AED {price.grand_total}</p>
                    </div>
                  </div>
                  {/* {token==null ?
                                    <Link href="/login"><a  class="zoa-btn zoa-checkout ober">Checkout</a></Link>
                                    :<Link href="/checkout"><a  class="zoa-btn zoa-checkout ober">Checkout</a></Link>} */}
                  <a
                    style={{ cursor: "pointer",width:'300px' }}
                    class="zoa-btn zoa-checkout ober js-user"
                    onClick={checkLogin}
                  >
                    Checkout
                  </a>

                  <button
                    onClick={toShop}
                    class="btn-update d-none-mob hidden-md hidden-lg"
                    style={{ marginTop: "20px" ,width:'300px'}}
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Cart;