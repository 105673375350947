import React, { useState, useEffect } from "react";

import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { cartTotalCount } from "../../redux/actions/cartActions";
import { baseUrl } from '../../constants';


function OrderDetails() {

  const [useraccount, setuserDetails] = useState("");
  const [orderdetails, setOrderDetails] = useState("");
  const [billing, setBilling] = useState("");
  const [shipping, setShipping] = useState("");
  const [orderitems, setOrderItems] = useState("");
  const [iscancel, setIscancel] = useState(1);
  const [reason, setReason] = useState("");
  const [orderDetailsCancel, setOrderDetailsCancel] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showReturnPopup, setShowReturnPopup] = useState(false);
  const [returnReason, setReturnReason] = useState("");
  const [orderDetailsReturn, setOrderDetailsReturn] = useState({});
  const dispatch = useDispatch();
  let carttotalcount = "";

  const history = useHistory();

  let { orderid } = useParams();
  const or_id = orderid;
  let token = "";

  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
    // console.log(token)
  }

  const config = { headers: { Authorization: `Bearer ${token}` } };
  useEffect(() => {
    axios
      .get(baseUrl+"/getUserAccount", config)
      .then(function (res) {
        if (res.data.success) setuserDetails(res.data.user);
        else history.push("/login");
        // handle success
        console.log("oddd==", res.data);
        // setuserDetails(res.data.user);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.setItem("cart_total_count", 0);
    dispatch(cartTotalCount(0));
    //window.reload();
    history.push("/");
  };
  useEffect(() => {
    console.log("ddd", or_id);

    axios
      .get(
        baseUrl+ `/orderDetails?id=${or_id}`,
        config
      )
      .then(function (res) {
        // handle success
        // console.log(response.data);
        setOrderDetails(res.data.order_list);
        setBilling(res.data.order_list.billing_address);
        setShipping(res.data.order_list.shipping_address);
        setOrderItems(res.data.order_list.items);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [or_id]);

  const handleCancel = (e) => {
    e.preventDefault();
    axios

      .post(
        baseUrl+"/changeStatus",
        {
          order_id: orderDetailsCancel.ord_id,
          order_item_id: orderDetailsCancel.order_item_id,
          reason: reason,
          type: "cancel",
        },
        config
      )
      .then(
        (response) => {
          //console.log("rrr",response);
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const onCancel = () => {
    setShowSuccessPopup(false);
  };
  const handleCancelOrder = (order_item_id, ord_id) => {
    setOrderDetailsCancel({
      order_item_id: order_item_id,
      ord_id: ord_id,
    });
    setShowSuccessPopup(true);
  };

  const onCancelReturn = () => {
    setShowReturnPopup(false);
  };
  const handleCancelReturn = (order_item_id, ord_id) => {
    setOrderDetailsReturn({
      order_item_id: order_item_id,
      ord_id: ord_id,
    });
    setShowReturnPopup(true);
  };
  const handleReturn = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl+"/changeStatus",
        {
          order_id: orderDetailsReturn.ord_id,
          order_item_id: orderDetailsReturn.order_item_id,
          reason: returnReason,
          type: "return",
        },
        config
      )
      .then(
        (response) => {
          //console.log("rrr",response);
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <div>
      <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a href="#" style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>My Account</li>
        </ul>
      </div>

      <div className="my-account">
        <div className="container container-content ">
          <div className="row">
            <div className="col-md-2 col-sm-2 howday">
              <div className="titlelt">
                <h2>
                  Hello, <strong> {useraccount.name} !</strong>
                </h2>
                <div className="login">
                  <ul className="nav ">
                    <li>
                      <a
                        onClick={() => history.push("/profile")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="img/Icon_Add.jpg" alt="Icon_User.jpg" />{" "}
                        Address
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => history.push("/orders")}
                        style={{ fontWeight: 600 ,cursor: "pointer"}}
                        // style={{  }}
                      >
                        <img src="img/Icon_Listing.jpg" alt="Icon_User.jpg" />{" "}
                        My orders
                      </a>
                    </li>
                       <li>
                      <a
                        onClick={() => history.push("/change-password")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="img/Icon_Lock.jpg" alt="Icon_User.jpg" />
                        change password
                      </a>
                    </li>
                    <li>
                      <a onClick={logout}>
                        <img src="img/Icon_Off.jpg" alt="Icon_User.jpg" /> log
                        out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-sm-12 porfolio">
              <div className="tab-content">
                <div id="account-details" className="tab-pane fade in active">
                  <h4 style={{ color: "#0a3a32" }}>
                    <b>Order Details</b>
                  </h4>
                  <div className="shopping-cart">
                    <div>
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>Order ID:</b>
                        </span>
                        {orderdetails.order_display_id}
                      </p>
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>Order Date:</b>
                        </span>
                        {orderdetails.ordered_date}
                      </p>
                    </div>
                    <div className="row">
                      <div style={{ marginTop: "20px" }} className="col-md-6">
                        <h4>Billing Address</h4>
                        <p>
                          {billing.first_name} {billing.last_name}
                        </p>
                        <p>{billing.street}</p>
                        <p>{billing.phone}</p>
                        <p>{billing.email}</p>
                      </div>
                      <div style={{ marginTop: "20px" }} className="col-md-6">
                        <h4>Shipping Address</h4>
                        <p>
                          {shipping.first_name} {billing.last_name}
                        </p>
                        <p>{shipping.street}</p>
                        <p>{shipping.phone}</p>
                        <p>{shipping.email}</p>
                      </div>
                    </div>
                    <div>
                      <div className="table-responsive">
                        <h4>Item Ordered</h4>
                        <table className="table cart-table">
                          <thead>
                            <tr>
                              <th className="product-thumbnail">Product</th>
                              <th className="product-name"></th>
                              <th className="product-name">Color</th>
                              <th className="product-name">Size</th>
                              <th className="product-name">Price</th>
                              <th className="product-name">Offer Price</th>
                              <th className="product-price">QTY</th>
                              <th className="product-quantity">Status</th>
                              <th className="product-subtotal">Actions</th>
                              {/* <th className="product-subtotal">Status</th>
                                                <th className="product-subtotal">EXP.Delivery</th>
                                                <th className="product-subtotal">Return</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {orderitems &&
                              orderitems.map((item) => (
                                <tr className="item_cart">
                                  <td className="product-desc">
                                    <div className="product-info">
                                      <a
                                        title=""
                                        className="hover-anchor"
                                      >
                                        {item.product_name}{" "}
                                      </a>
                                      <span></span>
                                    </div>
                                  </td>
                                  <td className=" product-name">
                                    <div className="product-img">
                                      <img
                                        src={item.product_image}
                                        alt="Product"
                                      />
                                    </div>
                                  </td>

                                  <td className="product-same total-price">
                                    <p
                                      className="price"
                                      style={{ fontFamily: "Eina, sans-serif" }}
                                    >
                                      {" "}
                                      {item.color}
                                    </p>
                                  </td>

                                  <td className="product-same total-price">
                                    <p
                                      className="price"
                                      style={{ fontFamily: "Eina, sans-serif" }}
                                    >
                                      {" "}
                                      {item.size}
                                    </p>
                                  </td>

                                  <td className="product-same total-price">
                                    <p
                                      className="price"
                                      style={{ fontFamily: "Eina, sans-serif" }}
                                    >
                                      AED {item.product_price}
                                    </p>
                                  </td>
                                  <td className="bcart-quantity single-product-detail">
                                    <div className="cart-qtt text-center">
                                      <p
                                        className="price"
                                        style={{
                                          fontFamily: "Eina, sans-serif",
                                        }}
                                      >
                                        {
                                          item.product_offer_price !=0 ? `AED ${item.product_offer_price}` :"__"
                                        }
                                      </p>
                                    </div>
                                  </td>
                                  <td className="total-price">
                                    <p
                                      className=""
                                      style={{ fontFamily: "Eina, sans-serif" }}
                                    >
                                      {item.quantity}
                                    </p>
                                  </td>
                                  <td className="total-price">
                                    <p>{item.item_status}</p>
                                  </td>
                                  {/* <td className="total-price">
                                                     <p>17-11-2021</p>
                                                 </td> */}

                                  {iscancel ? (
                                    item.item_status == "Ordered" ||
                                    item.item_status == "Shipped" ? (
                                      <td className="total-price">
                                        <button
                                          className=""
                                          
                                          style={{
                                            background: "none",
                                            border: "1px solid",
                                            outline: "none",
                                            padding: "5px 20px",
                                          }}
                                          onClick={() =>
                                            handleCancelOrder(
                                              item.order_item_id,
                                              item.order_id
                                            )
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    ) : null
                                  ) : null}
                                  {item.item_status==='Cancelled' && <td className="total-price">
                                        <button
                                          className=""
                                          disabled
                                          style={{
                                            background: "none",
                                            border: "1px solid",
                                            outline: "none",
                                            padding: "5px 20px",
                                            cursor:'not-allowed'
                                          }}
                                          onClick={() =>
                                            handleCancelOrder(
                                              item.order_item_id,
                                              item.order_id
                                            )
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </td> }
                                   
                                   {item.item_status == "Delivered" && orderdetails.can_return == 1  ? ( <td className="total-price">
                                      <button
                                        className=""
                                        style={{
                                          background: "none",
                                          border: "1px solid",
                                          outline: "none",
                                          padding: "5px 20px",
                                        }}
                                        onClick={() =>
                                          handleCancelReturn(
                                            item.order_item_id,
                                            item.order_id
                                          )
                                        }
                                      >
                                        Return
                                      </button>
                                    </td>
                                    ) : null}
                                    { item.item_status == "Returned" && <td className="total-price"><button
                                        className=""
                                        disabled
                                        style={{
                                          background: "none",
                                          border: "1px solid",
                                          outline: "none",
                                          padding: "5px 20px",
                                          cursor:'not-allowed'
                                        }}
                                        onClick={() =>
                                          handleCancelReturn(
                                            item.order_item_id,
                                            item.order_id
                                          )
                                        }
                                      >
                                        Return
                                      </button></td>}
    
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div>
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>Sub Total:</b>
                        </span>
                        AED {orderdetails.sub_total}
                      </p>
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>Shipping charges:</b>
                        </span>
                        AED {orderdetails.shipping_charge}
                      </p>
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>VAT {orderdetails.vat_perc}:</b>
                        </span>
                        AED {orderdetails.tax_amount}
                      </p>
                      
                      <p>
                        <span className="mr-2" style={{ marginRight: 5 }}>
                          <b>Grand Total:</b>
                        </span>
                        AED {orderdetails.grand_total}
                      </p>
                    </div>
                  </div>
                </div>
                <div>{/* <p>No items ordered</p> */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`modal fade ${showSuccessPopup ? "in" : ""}`}
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={showSuccessPopup ? "false" : "true"}
        style={{ display: `${showSuccessPopup ? "block" : "none"}` }}
      >
        <div class="modal-dialog" role="document">
          <form onSubmit={handleCancel}>
          <div class="modal-content">
            <div class="modal-header ">
              <h4 class="modal-title w-100 font-weight-bold">Cancel Item</h4>
            </div>
            <div class="modal-body mx-3">
              <div class="md-form mb-5">
                <label
                  data-error="wrong"
                  data-success="right"
                  for="defaultForm-email"
                >
                  Please enter your reason
                </label>
                <input
                  type="textarea"
                  id="defaultForm-email"
                  class="form-control validate"
                  required
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button class="btn btn-default" onClick={onCancel}>
                Discard
              </button>
              <button class="btn btn-success" style={{background:'#0a3a32',border:'none'}} type="submit">
                Send
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>

      <div
        class={`modal fade ${showReturnPopup ? "in" : ""}`}
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={showReturnPopup ? "false" : "true"}
        style={{ display: `${showReturnPopup ? "block" : "none"}` }}
      >
        <div class="modal-dialog" role="document">
         
          <form onSubmit={handleReturn}>
          <div class="modal-content">
          <div class="modal-header ">
              <h4 class="modal-title w-100 font-weight-bold">Return Item</h4>
            </div>
            <div class="modal-body mx-3">
              <div class="md-form mb-5">
                <label
                  data-error="wrong"
                  data-success="right"
                  for="defaultForm-email"
                >
                  Please enter your reason
                </label>
                <input
                  type="textarea"
                  id="defaultForm-email"
                  class="form-control validate"
                  required
                  onChange={(e) => setReturnReason(e.target.value)}
                />
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button class="btn btn-default" onClick={onCancelReturn}>
                Discard
              </button>
              <button class="btn btn-success" style={{background:'#0a3a32',border:'none'}} type="submit">
                Send
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
