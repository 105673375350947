import React, { useEffect, useState } from "react";
import { handleMenu } from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Products from "./Products";

function Shop({ categories, colors, sizes }) {
  const [showFilter,setShowFilter] = useState(false);

  const history = useHistory();
  let { category_slug } = useParams();

  const dispatch = useDispatch();
  var url_param = "Shop";

  if (typeof category_slug === "undefined") {
    url_param = "Shop";
  } else {
    url_param = category_slug;
  }

  useEffect(() => {
    dispatch(handleMenu(false));
  }, []);
  return (
    <div>
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="./img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={({ color: "#0a3a32" }, { textTransform: "uppercase" })}>
          {url_param}
        </h1>
        <ul className="breadcrumb">
          <li>
            <a style={{ textTransform: "uppercase" }}>
              {url_param}
            </a>
          </li>
          <li
            class="active"
            style={{ color: "#0a3a32", textTransform: "uppercase" }}
          >
            Clothing
          </li>
        </ul>
      </div>
	  <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={() => history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>{url_param}</li>
		  
        </ul>
      </div>
      <Products showFilter={showFilter} setShowFilter={setShowFilter} />
    </div>
  );
}

export default Shop;
