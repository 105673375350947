import * as types from "../constants/types";
import axios from "axios";
import { baseUrl } from '../../constants';
import { useHistory } from "react-router-dom";

let cartid = "";
let token = "";
let carttotalcount = "";
if (typeof window !== "undefined") {
  cartid = localStorage.getItem("cartdeviceid");
  token = localStorage.getItem("user");
  carttotalcount = localStorage.getItem("cart_total_count");
}
const bodyParameters = {
  cart_device_id: cartid,
};
const config = { headers: { Authorization: `Bearer ${token}` } };

export const cartTotalCount = (counts) => {
  return {
    type: types.CART_COUNT,
    payload: counts,
  };
};
export const updateSideCartDetails = () => (dispatch, getState) => {
  // if (typeof window !== 'undefined') {
  //     const cartid = localStorage.getItem('cartdeviceid')
  //     // console.log(cartid)
  //     const token = localStorage.getItem('token')
  //     // console.log(token)

  //  }

  const getCartListWithoutToken = (dispatch) => {
    axios
      .post(baseUrl+"/cartList", {
        cart_device_id: cartid,
      })
      .then(function (response) {
        console.log("cartitems", response.data);
        // isLoaded: true,
        // setItems(response.data.cart_list.items);
        // setPrice(response.data.cart_list);
        // localStorage.setItem('cartcount',price.cart_count)

        return dispatch({
          type: "SET_CART_DATA",
          payload: {
            data: response.data.cart_list.items,
          },
        });
        //    console.log(items)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCartListWithToken = (dispatch) => {
    axios
      .post(
        baseUrl+"/cartList?token=true",
        bodyParameters,
        config
      )
      .then(function (response) {
        console.log(response.data);
        // isLoaded: true,
        // setItems(response.data.cart_list.items);
        // setPrice(response.data.cart_list);

        // localStorage.setItem('cartcount',price.cart_count)
        if (response.data.cart_list)
          return dispatch({
            type: "SET_CART_DATA",
            payload: {
              data: response.data.cart_list.items,
            },
          });
        else if (response.data.error === "No items in cart")
          return dispatch({
            type: "SET_CART_DATA",
            payload: {
              data: [],
            },
          });
        else getCartListWithoutToken();
        //    console.log(items)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (token == null) {
    getCartListWithoutToken(dispatch);
  } else {
    getCartListWithToken(dispatch);
  }
};

export const handleIncrement = (item_id) => (dispatch, getState) => {
  const cart_list = getState().cart.cart_list;
  console.log("cart_list==", cart_list);
  if (token == null) {
    cart_list.map((itm) =>
      //console.log("sss",itm)
      item_id === itm.item_id
        ? axios
            .post(baseUrl+"/cartUpdate", {
              cart_device_id: cartid,
              item_id: item_id,
              cart_id: itm.cart_id,
              quantity: itm.quantity + 1,
            })
            .then(
              (response) => {
                // console.log(response);
                // setItems(response.data.cart_list.items);
                // setPrice(response.data.cart_list);
                // console.log("sssss",items)
                if (response.data.cart_list)
                  return dispatch({
                    type: "SET_CART_DATA",
                    payload: {
                      data: response.data.cart_list.items,
                    },
                  });
                else if (response.data.error === "No items in cart")
                  return dispatch({
                    type: "SET_CART_DATA",
                    payload: {
                      data: [],
                    },
                  });
              },
              (error) => {
                console.log(error);
              }
            )
        : 0
    );
  } else {
    cart_list.map((itm) =>
      //console.log("sss",itm)
      item_id === itm.item_id
        ? axios
            .post(
              baseUrl+"/cartUpdate?token=true",
              {
                cart_device_id: cartid,
                item_id: item_id,
                cart_id: itm.cart_id,
                quantity: itm.quantity + 1,
              },
              config
            )
            .then(
              (response) => {
                if (response.data.cart_list)
                  return dispatch({
                    type: "SET_CART_DATA",
                    payload: {
                      data: response.data.cart_list.items,
                    },
                  });
                else if (response.data.error === "No items in cart")
                  return dispatch({
                    type: "SET_CART_DATA",
                    payload: {
                      data: [],
                    },
                  });
              },
              (error) => {
                console.log(error);
              }
            )
        : 0
    );
  }
};
export const handleDecrement = (item_id) => (dispatch, getState) => {
  const cart_list = getState().cart.cart_list;
  if (token == null) {
    cart_list.map((itm) =>
      //console.log("sss",itm)
      item_id === itm.item_id
        ? itm.quantity - 1 > 0
          ? axios
              .post(baseUrl+"/cartUpdate", {
                cart_device_id: cartid,
                item_id: item_id,
                cart_id: itm.cart_id,
                quantity: itm.quantity - 1 > 0 ? itm.quantity - 1 : 0,
              })
              .then(
                (response) => {
                  if (response.data.cart_list)
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: response.data.cart_list.items,
                      },
                    });
                  else if (response.data.error === "No items in cart")
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: [],
                      },
                    });
                },
                (error) => {
                  console.log(error);
                }
              )
          : axios
              .post(baseUrl+"/cartDelete", {
                cart_device_id: cartid,
                item_id: item_id,
                cart_id: itm.cart_id,
              })
              .then(
                (response) => {
                  if (response.data.cart_list)
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: response.data.cart_list.items,
                      },
                    });
                  else if (response.data.error === "No items in cart")
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: [],
                      },
                    });
                },
                (error) => {
                  console.log(error);
                }
              )
        : 0
    );
  } else {
    cart_list.map((itm) =>
      //console.log("sss",itm)
      item_id === itm.item_id
        ? itm.quantity - 1 > 0
          ? axios
              .post(
                baseUrl+"/cartUpdate?token=true",
                {
                  cart_device_id: cartid,
                  item_id: item_id,
                  cart_id: itm.cart_id,
                  quantity: itm.quantity - 1 > 0 ? itm.quantity - 1 : 0,
                },
                config
              )
              .then(
                (response) => {
                  // console.log(response);
                  // setItems(response.data.cart_list.items);
                  // setPrice(response.data.cart_list);
                  // console.log("sssss",items)
                  if (response.data.cart_list)
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: response.data.cart_list.items,
                      },
                    });
                  else if (response.data.error === "No items in cart")
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: [],
                      },
                    });
                },
                (error) => {
                  console.log(error);
                }
              )
          : axios
              .post(
                baseUrl+"/cartDelete?token=true",
                {
                  cart_device_id: cartid,
                  item_id: item_id,
                  cart_id: itm.cart_id,
                },
                config
              )
              .then(
                (response) => {
                  // setItems(response.data.cart_list.items);
                  // setPrice(response.data.cart_list);
                  // localStorage.setItem('cartcount',price.cart_count)
                  // localStorage.setItem('cart_total_count',(response.data.cart_list.length==0)?0:response.data.cart_list.cart_count)

                  // dispatch(cartTotalCount((response.data.cart_list.length==0)?0:response.data.cart_list.cart_count));
                  if (response.data.cart_list)
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: response.data.cart_list.items,
                      },
                    });
                  else if (response.data.error === "No items in cart")
                    return dispatch({
                      type: "SET_CART_DATA",
                      payload: {
                        data: [],
                      },
                    });
                },
                (error) => {
                  console.log(error);
                }
              )
        : 0
    );
  }
};
export const triggerLogout = () => (dispatch, getState)=> {
  console.log("logout triggered");
  localStorage.removeItem("user");
  localStorage.setItem("cart_total_count", 0);
  dispatch(cartTotalCount(0));
  //window.reload();
  // history.push("/");
  window.location.href = '/#/login';
  window.location.reload();
}