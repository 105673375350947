import React from "react";

function ProductImages(images) {
  return (
    <div>
      {images.map((image) => (
        <img src={image} alt="photo" className="img-responsive" />
      ))}
      <a className="hover-images effect"></a>
    </div>
  );
}

export default ProductImages;
