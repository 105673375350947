import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { baseUrl } from '../../constants';
import axios from "axios";


function Contact() {

    const history = useHistory();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [message, setMessage] = useState("");
    
    const [emailmessage, setEmailmessage] = useState("");
    const [phonemessage, setPhonemessage] = useState("");
    const [firstnamemessage, setFirstnamemessage] = useState("");
    const [lastnamemessage, setLastnamemessage] = useState("");
    const [errmessage, setErrmessage] = useState("");
    const [updatedmessage, setUpdatedmessage] = useState("");
    

    const contactSubmit = (e) => {
        e.preventDefault();

        var isValid = 1;
        if (email == "") {
            setEmailmessage("Please enter Email Id");
            var isValid = 0;
        } else {
          if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");
    
            if (
              !(
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                email.indexOf("@@") == -1 &&
                lastDotPos > 2 &&
                email.length - lastDotPos > 2
              )
            ) {
                var isValid = 0;
                setEmailmessage("Email is not valid");
            } else {
                setEmailmessage("");
            }
          }
        }
        if (phone == "") {
            var isValid = 0;
            setPhonemessage("Please enter Phone Number");
        } else {
            var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            if (!phone.match(phoneno)) {
                var isValid = 0;
                setPhonemessage("Please enter a valid phone number(10 digits)");
                isValid = 0;
            } else {
                setPhonemessage("");
            }
            
        }

        if (firstname == "") {
            var isValid = 0;
            setFirstnamemessage("Please enter First Name");
        } else {
            setFirstnamemessage("");
        }
        
        if (lastname == "") {
            var isValid = 0;
            setLastnamemessage("Please enter Last Name");
        } else {
            setLastnamemessage("");
        }

        if (message == "") {
            var isValid = 0;
            setErrmessage("Please enter Message");
        } else {
            setErrmessage("");
        }
    
        if (isValid ==0) {
          return false;
        }
    
        axios
          .post(baseUrl+"/contactSubmit", {
            email: email,
            first_name: firstname,
            last_name: lastname,
            phone: phone,
            message: message,
            
          })
          .then(function (response) {
            if (response.data.success) {

              setEmail('');
              setPhone('');
              setFirstname('');
              setLastname('');
              setMessage('');
              setUpdatedmessage(response.data.message);
              setTimeout(() => {
                setUpdatedmessage('');
              }, 3000);
              
              //history.push("/profile");
            } else {
                setUpdatedmessage('Failed to submit details');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      };
  return (
    <div className="footer-item">
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="/img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={{ color: "#0a3a32", textTransform: "uppercase" }}>
            Contact Us
        </h1>
      </div>

        <div className="container">
            <div className="about-content bd-bottom">
                <div className="row">
                    <div className="col-md-4">
                        <div className="contact__Details">
                            <h3>Address</h3>
                            <p>Tamani Arts Offices Tower, Business Bay - office 811<br/>P.O Box - 23232323, UAE</p>
                            <div className="icon">
                            <i class="las la-map-marked-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="contact__Details">
                            <h3>Contact Number </h3>
                            <p>+971 58 588 4033</p>
                            <div className="icon">
                            <i class="las la-headset"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="contact__Details">
                            <h3>Email Address</h3>
                            <p>customercare@balaclavafashion.com</p>
                            <div className="icon">
                            <i class="las la-envelope"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-6 col-xs-12">
                        
                        <div className="about-info">
                            
                            <div className="about-desc">

                                <form className="form-customer form-register" onSubmit={contactSubmit}>

                                <div className="form-group col-md-6">
                                    <label for="exampleInputEmail2">First Name</label>
                                    <input
                                    type="text"
                                    className="form-control form-account"
                                    id="exampleInputEmail2"
                                    value={firstname ?? ''}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {firstnamemessage}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="exampleInputEmail2">Last Name</label>
                                    <input
                                    type="text"
                                    className="form-control form-account"
                                    id="exampleInputEmail2"
                                    value={lastname ?? ''}
                                    onChange={(e) => setLastname(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {lastnamemessage}
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="exampleInputPassword3">Email</label>
                                    <input
                                    type="text"
                                    className="form-control form-account"
                                    id="exampleInputPassword3"
                                    value={email ?? ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {emailmessage}
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="exampleInputPassword3">Phone Number</label>
                                    <input
                                    type="text"
                                    className="form-control form-account"
                                    id="exampleInputPassword3"
                                    value={phone ?? ''}
                                    onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {phonemessage}
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="exampleInputPassword3">Message</label>
                                    <textarea
                                    
                                    className="form-control form-account"
                                    id="exampleInputPassword3"
                                    value={message ?? ''}
                                    onChange={(e) => setMessage(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {errmessage}
                                    </div>
                                </div>
                                
                                <div className="btn-button-group mg-top-30 mg-bottom-15 col-md-12">
                                    <button
                                    type="submit"
                                    className="zoa-btn btn-login hover-white"
                                    >
                                    Submit
                                    </button>
                                    <div className="invalid-feedback">
                                        {updatedmessage}
                                    </div>
                                </div>
                                </form>
                                
                                {/* <p style={{color:"#666"}}>Zoa stands for a personal and obstinate selection. Surprising with new designers every season, great attention is given to the unique & personal identity of all in-store designers. From clothing to jewellery, shoes & bags, each piece is chosen with special care.</p> */}
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
       
      
    </div>
      
  );
}


export default Contact;