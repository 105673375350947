import React from 'react'
import Script from 'next/script';
import { Link, useHistory, useParams } from "react-router-dom";

function Footer() {
      const history = useHistory();
    return (
        <div className="footer-item">
             <footer className="footer v2">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3 hidden-xs hidden-sm" >
                            <a href="">
                                <img src="img/logo.png" alt="" className="img-responsive" style={{width: '200px'}}/>
                                <Link to="/terms-and-conditions" className='copyright'>© 2022 BALACLAVA</Link>
                                
                            </a>
                        </div>
                        <div className="col-md-6" style={{textAlign:'center'}}>
                        <img src="img/cards.svg" alt="" className='logo' className="img-responsive" style={{width: '70%',marginBottom:"1px",margin:"0 auto"}}/>
                            <ul className='col-sm-12 align-items-center'>
                                <li>
                                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                                </li>
                                <li>
                                    <Link to="/refund-policy">REFUND POLICY</Link>
                                </li>
                                <li>
                                    <Link to="/terms-and-conditions">TERMS OF USE</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">ABOUT US</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">CONTACT US</Link>
                                </li>
                            </ul>
                            {/* <p style={{fontSize: '8px'}}> © 2022<a href=""> BALACLAVA</a></p>
                            <p style={{fontSize: '8px'}}> <a 
                            className="footer-item"
                            onClick={() => history.push("/privacy-policy")}
                            style={{cursor:"pointer"}}
                            >
                            PRIVACY POLICY
                            </a></p>
                            <p style={{fontSize: '8px'}}><a style={{cursor:"pointer"}} onClick={() => history.push("/terms-and-conditions")}>RETURN POLICY</a></p>
                            <p style={{fontSize: '8px'}}><a style={{cursor:"pointer"}} onClick={() => history.push("/terms-and-conditions")}>TERMS OF USE</a></p>
                            <p style={{fontSize: '8px'}}><a style={{cursor:"pointer"}} onClick={() => history.push("/about-us")}>ABOUT US</a></p>
                            <p style={{fontSize: '8px'}}><a style={{cursor:"pointer"}} onClick={() => history.push("/contact-us")}>CONTACT US</a></p> */}
                        
                        </div>
                        <div className="col-md-3 col-sm-12" style={{flexDirection: 'column',padding: '10px',textAlign: 'end'}}>
                            <div className="social">
                                
{/*                                
                                <i className="fa fa-cc-paypal" style={{color: '#888'}}></i>
                                <i className="fa fa-cc-visa" style={{color: '#888'}}></i>
                                <i className="fa fa-cc-mastercard" style={{color: '#888'}}></i>
                                <i className="fa fa-cc-discover" style={{color: '#888'}}></i>
                                <i className="fa fa-cc-amex" style={{color: '#888'}}></i> */}
                                
    
                                 <div className="social" style={{paddingBottom:'24px'}}>
                        
                        <a href=""><i className="fa fa-facebook"></i></a>
                        <a href=""><i className="fa fa-twitter"></i></a>
                        <a href=""><i className="fa fa-instagram"></i></a>
                        <a href=""><i className="fa fa-linkedin"></i></a>
                    </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        
    </div>
    
       
    )
}

export default Footer
