import React, { useState, useEffect, useRef } from "react";

import { useHistory, useParams } from "react-router-dom";
import ProductImages from "./ProductImages";
import axios from "axios";
import generateToken from "../../Components/auth/token";

import { useDispatch, useSelector } from "react-redux";
import { cartTotalCount } from "../../redux/actions/cartActions";
import { Link } from "react-router-dom";
import DemoCarousel from '../../DemoCarousel'
import { baseUrl } from "../../constants";
import './shop.css'



function ProductDetails() {
 
  const [productVar, setProduct] = useState({});
  const [imagesVar, setImages] = useState([]);
  const [sizesVar, setSizes] = useState([]);
  const [colorsVar, setColors] = useState([]);
  const [related_products, setRelatedProducts] = useState({});
  const [paramVar, setParam] = useState();
  const history = useHistory();
  let { productid,homeBanner} = useParams();
  console.log(useParams())
console.log('homeBanner   ',homeBanner)
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [cartCount, setcartCount] = useState(1);
  const [productId, setproductId] = useState("");
  const [qty, setqty] = useState("1");
  const [productType, setproductType] = useState("");
  const [color, setcolor] = useState("");
  const [sizeatt, setsizeatt] = useState("");
  // const [coloratt, setcoloratt] = useState("");
  const [cartDeviceId, setcartDeviceId] = useState("");
  const [dimages, setDimages] = useState("");
  const [check, setCheck] = useState("");
  // const [checky, setChecky] = useState(0);
  const [prices, setPrices] = useState("");
  const [displaycolor, setDisplayColor] = useState("");
  const [selected, setSelected] = useState("");
  const [productprices, setProductPrices] = useState("");
  const [productofferprices, setProductOfferPrices] = useState("");
  const [producterror, setProducterror] = useState("");
  const [showSuccessPopup,setShowSuccessPopup] = useState(false);
  const [showErrorPopup,setShowErrorPopup] = useState({status:false,message:''});


  const [selectedItemPrice, setSelectedItemPrice] = useState({
    product_price: null,
    have_offer: 0,
    product_offer_price: null,
  });
  const [productsku, setProductsku] = useState("N/A");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const param = productid;
    axios
      .get(
        baseUrl+`/getProductsDetails?product_id=${param}`,
        {}
      )
      .then((res) => {
        setProduct(res.data.product_details);
        setImages(res.data.product_details.product_images);
        setSizes(res.data.product_details.size);
        setColors(res.data.product_details.color);
        setRelatedProducts(res.data.related_products);
        setParam(param);
      })
      .catch((err) => {});
  }, [productid]);
  const [noComb, setNoComb] = useState(false);
  useEffect(() => {
    if(productVar.product_type==='1'){
      setSelectedItemPrice({
        product_price: productVar.product_price,
        product_offer_price:
        productVar.product_offer_price,
        have_offer: productVar.have_offer,
        });
        setProductsku(productVar.product_sku);
      return;
    }
    // if(productVar?.size.length===0 && productVar?.color.length===0) return ;
    if (
      productVar && // 👈 null and undefined check
      Object.keys(productVar).length != 0
    ) {
      //size only
      if(productVar.color.length===0){
        let selectedSizeProduct = productVar.size.filter(x=>x.selected===1)[0];
        setsizeatt(selectedSizeProduct.size_id);
        setSelectedItemPrice({
        product_price: selectedSizeProduct.size_price_array[0].product_price,
        product_offer_price:
        selectedSizeProduct.size_price_array[0].product_offer_price,
        have_offer: selectedSizeProduct.size_price_array[0].have_offer,
        });
        setProductsku(selectedSizeProduct.size_price_array[0].product_sku);
      }
      //color only
      else if(productVar.size.length===0){
        let selectedColorProduct = productVar.color.filter(x=>x.selected===1)[0];
        // setsizeatt(selectedColorProduct.size_id);
        setDimages(selectedColorProduct.display_images[0]);
        setcolor(selectedColorProduct.color_id);
        setSelectedItemPrice({
        product_price: selectedColorProduct.prize_array[0].product_price,
        product_offer_price:
        selectedColorProduct.prize_array[0].product_offer_price,
        have_offer: selectedColorProduct.prize_array[0].have_offer,
        });
        setProductsku(selectedColorProduct.prize_array[0].product_sku);
      }
      else{
        let selectedColorProduct = productVar.color.filter(x=>x.selected===1)[0];
        let selectedSizeProduct = productVar.size.filter(x=>x.selected===1)[0];
        let sizeMatchingProductArray = selectedColorProduct.prize_array.filter(x=>x.size_attribite_id===selectedSizeProduct.size_id);
        setcolor(selectedColorProduct.color_id);
        setsizeatt(selectedSizeProduct.size_id);
        setDimages(selectedColorProduct.display_images[0]);
        if(sizeMatchingProductArray.length>0){
          setNoComb(false);
        setSelectedItemPrice({
          product_price: sizeMatchingProductArray[0].product_price,
          product_offer_price:
          sizeMatchingProductArray[0].product_offer_price,
          have_offer: sizeMatchingProductArray[0].have_offer,
          });console.log("bima",sizeMatchingProductArray[0].have_offer)
          setProductsku(sizeMatchingProductArray[0].product_sku);
        }
          else{
            setNoComb(true);
            setProductsku('N/A');
          }

      }
    }
  }, [productVar]);

  let cartid = "";
  let token = "";
  if (typeof window !== "undefined") {
    cartid = localStorage.getItem("cartdeviceid");
    token = localStorage.getItem("user");
  }

  const handleIncrement = () => {
    setcartCount((prevCount) => prevCount + 1);
  };

  //Create handleDecrement event handler
  const handleDecrement = () => {
    setcartCount((prevCount) => prevCount - 1);
  };

  const config = { headers: { Authorization: `Bearer ${token}` } };
  const bodyParameters = {
    product_id: productid,
    quantity: cartCount,
    product_type: productVar.product_type,
    color: color,
    size: sizeatt,
    cart_device_id: cartid,
  };

  if (typeof window !== "undefined") {
    const cartid = localStorage.getItem("cartdeviceid");
    //    setcartDeviceId('cartid');
  }
useEffect(()=>{
  if(showErrorPopup.status)
  setTimeout(() => {
    setShowErrorPopup({status:false,message:''})
  }, 1000);
},[showErrorPopup])
  const addItemToBasket = (product) => {
    // setcartCount([...cartCount,product])

    setproductId();
    if (token == null) {
      axios
        .post(baseUrl+"/addToCart", {
          product_id: productid,
          quantity: cartCount,
          product_type: productVar.product_type,
          color: color,
          size: sizeatt,
          cart_device_id: cartid,
        })
        .then(
          (response) => {
            if (response.data.success) {
              setShowSuccessPopup(true);
              setProducterror("");
              dispatch(cartTotalCount(response.data.cart_count));
              localStorage.setItem(
                "cart_total_count",
                response.data.cart_count
              );
            } else {
              setShowErrorPopup({status:true,message:response.data.error})
              setProducterror(response.data.error);
              setTimeout(() => {
                // After 3 seconds set the show value to false
                setProducterror('')
              }, 3000)
              
            }
          },
          (error) => {
            setShowErrorPopup({status:true,message:error.response.data.error})
          }
        );
    } else {
      axios
        .post(
          baseUrl+"/addToCart?token=true",
          bodyParameters,
          config
        )
        .then(
          (response) => {
            if (response.data.success) {
              setShowSuccessPopup(true);
              setProducterror("");
              dispatch(cartTotalCount(response.data.cart_count));
              localStorage.setItem(
                "cart_total_count",
                response.data.cart_count
              );
            } else {
              setShowErrorPopup({status:true,message:response.data.error})
              setProducterror(response.data.error);
              setTimeout(() => {
                // After 3 seconds set the show value to false
                setProducterror('')
              }, 3000)
            }
          },
          (error) => {
            setShowErrorPopup({status:true,message:error.response.data.error})
          }
        );
    }
  };
useEffect(()=>{
if(showSuccessPopup)
setTimeout(() => {
  setShowSuccessPopup(false)
}, 1000);
},[showSuccessPopup])

  function handleColor(e) {
    setcolor(e.target.value);
  }
  useEffect(()=>{
    if(colorsVar.length===0) return;
    let temp = [...colorsVar];
    temp.forEach((x) => {
      x.selected = 0;
    });
    //temp.find((x) => x.color_id == color).selected = 1;
    if (temp.filter((x) => x.color_id == color).length!=0)
    temp.find((x) => x.color_id == color).selected = 1;
    setColors(temp);
  },[color]);

  function handleSize(e) {
    setsizeatt(e.target.value);
  }

  useEffect(()=>{
    if(sizesVar.length===0) return;
    let temp = [...sizesVar];
    temp.forEach((x) => {
      x.selected = 0;
    });
     console.log("vov",sizeatt)
    // temp.find((x) => {
    //   //console.log("yaa",x.size_id)
    //   if(typeof x.size_id!== "undefined"){
    //     if(x.size_id == sizeatt){
        
    //       if(typeof x.selected !== "undefined"){
    //         setSizes(temp);
  
    //       }
    //     }
    //   }
      
    // })
    //console.log("temp",temp)
    //temp.find((x) => x.size_id == sizeatt).selected = 1;
    if (temp.filter((x) => x.size_id == sizeatt).length!=0)
    temp.find((x) => x.size_id == sizeatt).selected = 1;
    setSizes(temp);
  },[sizeatt]);
  // const check = '';
  const checked = (i) => {
    // setCheck('')
    // var x = document.getElementsByClassName("checkbox");

    // setChecky(i);
    // setDimages(imagesVar[i]);
    // setCheck(i);
  };
  const skuRef = useRef();
  const scrollToSku = () => {
    window.scrollTo(0,skuRef.current.offsetTop);
  }
  useEffect(() => {
    setDimages(imagesVar[check]);
  }, [check]);
  useEffect(()=>{
    if(Object.keys(productVar).length>0){

      let tempProductsVar = {...productVar};
      if(tempProductsVar.color.length !== colorsVar.length) return;
      if(tempProductsVar.size.length !== sizesVar.length) return;
      tempProductsVar.color = [...colorsVar];
      tempProductsVar.size = [...sizesVar];
      setProduct(tempProductsVar);
    }
  },[sizesVar,colorsVar])
  return (
    <div>
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="/img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={{ color: "#0a3a32", textTransform: "uppercase" }}>
          {productVar.product_name}
        </h1>
      </div>
      <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={()=> history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
          {homeBanner===undefined && <li>
            <a onClick={()=> history.push("/shop")} style={{textTransform:"capitalize"}}>Shop</a>
          </li>}
          <li className="active" style={{ color: "#0a3a32",textTransform:"capitalize"}}>
            {productVar.product_name}
          </li>
        </ul>
      </div>

      <div className="container container-content">
        <div className="single-product-detail">
          <div className="row">
                    <div class="col-xs-12 col-sm-6 col-md-6">
                      <DemoCarousel images={imagesVar} checked={checked} dimages={dimages}/>
                    </div>
            <div className="col-xs-12 col-sm-6 col-md-6 customproductDetailcss">
              <div className="single-product-info product-info product-grid-v2">
                <h3 className="product-title">
                  <a style={{ color: "#0a3a32" }}>
                    {productVar.product_name}
                  </a>
                </h3>

                {noComb === true && <p style={{color: 'rgb(10, 58, 50)',fontWeight:'bolder',fontSize:'20px'}}>No combination available</p>}
                {noComb === false && (
                  <>
                    {selectedItemPrice.have_offer == 1 ? (
                      <div className="product-price">
                        <span className="old thin">
                          AED {selectedItemPrice.product_price}
                        </span>

                        <span style={{ color: "#0a3a32" }}>
                          AED {selectedItemPrice.product_offer_price}
                        </span>
                      </div>
                    ) : (
                      <div className="product-price">
                        <span style={{ color: "#0a3a32" }}>
                          AED {selectedItemPrice.product_price}
                        </span>
                      </div>
                    )}
                  </>
                )}

                <div className="short-desc">
                  <p className="product-desc">
                    {productVar?.product_description?.length>100?<>{productVar.product_description.substr(0,100)}<a style={{color:'grey',fontWeight:'bold',cursor:'pointer'}} onClick={scrollToSku}>...Read More</a></>:productVar.product_description}
                    {/* <a href="#description" style={{ color: "#a05b45" }}>
                      Read More
                    </a> */}
                  </p>
                </div>
                {/* <div className="color-group">
                                
                                
                                <a className="circle "></a>
                                <a className="circle "></a>
                                <a className="circle "></a>
                            </div> */}
                {productVar.product_type == "2" ? (
                  <>
                    {colorsVar.length > 0 && (
                      <div className="colors">
                        <label>Color :</label>
                        <ul>
                          {colorsVar.map((color) => (
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  name="color"
                                  value={color.color_id}
                                  onClick={handleColor}
                                  checked={color.selected == 1 ? "checked" : ""}
                                />
                                <span
                                  className="swatch"
                                  style={{ backgroundColor: color.color_code }}
                                ></span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}{" "}
                  </>
                ) : null}
                {productVar.product_type == "2" ? (
                  <>
                    {sizesVar.length > 0 && (
                      <div className="product-size">
                        <div className="size-group">
                          <label>Size :</label>
                          <select
                            className="custom-select"
                            style={{ width: "100px", padding: "10px" }}
                            onChange={handleSize}
                          >
                            {sizesVar.map((size) => (
                              <option
                                selected={size.selected == 1 ? "selected" : ""}
                                value={size.size_id}
                              >
                                {size.size_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {productVar.size_guide !== "" && (
                          <a
                            href={productVar.size_guide}
                            target="_blank"
                            className="size-guide"
                            style={{ color: "#0a3a32 !important" }}
                            
                          >
                            Size guide
                          </a>
                        )}
                      </div>
                    )}
                  </>
                ) : null}
                <div className="single-product-button-group">
                  <div className="flex align-items-center element-button"
                  style={{flexDirection:"row"}}
                  >
                    <div className="zoa-qtt">
                      <button
                        type="button"
                        className="quantity-left-minus btn btn-number js-minus"
                        disabled={cartCount<=1}
                        onClick={handleDecrement}
                      ></button>
                      <input
                        type="text"
                        name="number"
                        value={cartCount}
                        className="product_quantity_number "
                      />
                      <button
                        type="button"
                        className="quantity-right-plus btn btn-number js-plus"
                        data-type="plus"
                        data-field=""
                        onClick={handleIncrement}
                      ></button>
                    </div>
                    <a
                      className="zoa-btn zoa-addcart"
                      onClick={() => addItemToBasket(productVar)}
                      
                    >
                      <i className="zoa-icon-cart"></i>add to cart
                    </a>
                    <>
                    {/* <button type="button" class="btn btn-primary">
  Launch demo modal
</button> */}

{/* <!-- Modal --> */}
<div class={`modal fade ${showSuccessPopup?'in':''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showSuccessPopup?"false":"true"} style={{display:`${showSuccessPopup?'block':'none'}`}}>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
        {/* ... */}
        <img src="img/check.png" alt="check" style={{width:"30px",height:"30px",marginRight:"5px"}}/> <p style={{margin:'0px',color:'#0a3a32'}}>Item added to cart</p>
      </div>
    </div>
  </div>
</div>
<div class={`modal fade ${showErrorPopup.status?'in':''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showErrorPopup.status?"false":"true"} style={{display:`${showErrorPopup.status?'block':'none'}`}}>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
        {/* ... */}
        <img src="img/red-cross.png" alt="check" style={{width:"30px",height:"30px",marginRight:"5px"}}/> <p style={{margin:'0px',color:'#0a3a32'}}>{showErrorPopup.message}</p>
      </div>
    </div>
  </div>
</div>
                    </>
                  </div>
                  <div className="invalid-feedback">{producterror}</div>
                </div>
                <div className="product-tags">
                  <div className="element-tag">
                    <label>SKU :</label>
                    <span>{productsku}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-product-tab" id="description">
          <ul className="tabs text-center">
            <li>
              <a data-toggle="pill" href="#desc" ref={skuRef}>
                Description
              </a>
            </li>
          </ul>
          <div className="tab-content" style={{ border: "none !important" }}>
            <div id="desc" className="tab-pane fade in active ">
              <div className="content-desc text-center">
                <p>{productVar.product_description}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="single-product-tab bd-bottom w-100"
          id="related_products"
          style={{ border: "none"}}
        >
          <ul className="tabs text-center">
            <li>
              <a data-toggle="pill" href="#relpro">
                Related Products
              </a>
            </li>
          </ul>
          <div
            className="tab-content"
            style={{ border: "none", width: "100%" }}
          >
            <div id="desc" className="tab-pane fade in active w-100">
              <div
                className="content-desc text-center w-100"
                style={{ minWidth: "100%" }}
              >
                {related_products.length > 0 &&
                  related_products.map((productss, productssIndex) => (
                    <div
                      className="col-xs-6 col-sm-4 col-md-15 col-lg-15 product-item"
                      key={productssIndex}
                    >
                      <div className="product-img">
                        <a href="">
                          <Link
                            to={{
                              pathname: "/product/" + productss.product_id,
                            }}
                          >
                            <img
                              src={productss.product_image}
                              alt=""
                              className="img-responsive"
                              style={{ height: "263px", objectFit: "cover" }}
                            />
                          </Link>
                        </a>
                        {productss.have_offer == "1" ? (
                          <div className="ribbon zoa-hot">
                            <span>SALE</span>
                          </div>
                        ) : null}

                        <div className="product-button-group">

                        </div>
                      </div>
                      <div className="product-info text-center">
                        <h3 className="product-title">
                          <Link
                            to={{
                              pathname: "/product/" + productss.product_id,
                            }}
                          >
                            {productss.product_name}
                          </Link>
                        </h3>
                        <div className="product-price">
                          {productss.have_offer == "1" ? (
                            <div>
                              <span
                                style={{
                                  color: "#888",
                                  marginRight: "5px",
                                  textDecoration: "line-through",
                                }}
                              >
                                {" "}
                                AED {productss.product_price}
                              </span>
                              <span style={{ color: "#0a3a32" }}>
                                AED {productss.product_offer_price}
                              </span>
                            </div>
                          ) : (
                            <span style={{ color: "#0a3a32" }}>
                              AED {productss.product_price}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export const getServerSideProps = async (context) => {
  const param = context.params.id;
  const res = await fetch(
    `https://backend.shtdevops.xyz/api/v1/getProductsDetails?product_id=${param}`
  );
  const product = await res.json();
  return {
    props: {
      product: product.product_details,
      images: product.product_details.product_images,
      sizes: product.product_details.size,
      colors: product.product_details.color,
      related_products: product.related_products,

      // dimages:productVar.product_details.color.display_images,
      param,
    },
  };
};

export default ProductDetails;
