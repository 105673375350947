import * as types from "../constants/types";

const initialState = {
  products: [],
  showMenu: false,
  loading: false,
  error: null,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.GET_PRODUCTS:{
    //     console.log('Hello');
    //     console.log('action.payload   ',action.payload);
    //     console.log('state  ',state);
    //     let temp = {...state};
    //     console.log('temp   ',temp);
    //     let tempProducts = [...state.products];
    //     temp.products = tempProducts.concat(action.payload);
    //     temp.loading = true;
    //     temp.error = null;
    //     console.log('temp   ',temp);
    // return temp;
    // }
    case types.MENU_OPEN: {
      return {
        ...state,
        showMenu: action.payload,
      };
      break;
    }
    case types.GET_PRODUCTS: {
      console.log("types.GET_PRODUCT");
      return {
        ...state,
        products: action.payload,
        loading: true,
        error: null,
      };
      break;
    }
    case "ADD_MORE_PRODUCTS": {
      let temp = { ...state };
      let temp2 = temp.products.products;
      temp2 = temp2.concat(action.payload.products);
      temp.products.products = temp2;
      return temp;
      break;
    }
    default:
      return state;
  }
};
