import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../Order/order.css";

import axios from "axios";
import { baseUrl } from "../../constants";
function PaymentCancel() {
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState("");
  const [orderitems, setOrderItems] = useState("");
  const [errMsg, setErrMsg] = useState("");
  console.log(orderDetails);
  // const cartid = '';
  let token = "";
  let ref_url = "";
  if (typeof window !== "undefined") {
    // cartid = localStorage.getItem('cartdeviceid')
    token = localStorage.getItem("user");
    ref_url = localStorage.getItem("ref_url");
    // console.log(token)
  }
  
  let { id } = useParams();

  const config = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    axios
    .post(
        baseUrl+"/paymentCancel",
        {
          ref_url: ref_url,
        },
        config
      )
      .then(function (res) {
        setOrderDetails(res.data.order_list);
        setOrderItems(res.data.order_list.items);
        setErrMsg(res.data.result);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  }, []);
  const toShop = () => {
    history.push("/shop");
  };
  return (
    <div>
      <div className="check-out">
        <div className="container vh-100" style={{ marginBottom: "50px" }}>
          <div
            className="row align-items-center"
            style={{ marginTop: "30px" }}
          >
            <div className="col-md-12">
              <h2 style={{ color: "#0a3a32" }}>
                <b>Oops {errMsg}</b>
              </h2>
              <h3>Order Summary</h3>
              <div className="col-md-6">
              <table className="table">
              <thead>
                      <tr>
                        <th style={{ color: "#0a3a32",width:'25%' }} >product</th>
                        <th style={{ color: "#0a3a32",width:'45%' }} >product name</th>
                        <th style={{ color: "#0a3a32" ,width:'15%'}}>QTY</th>
                        {/* <th></th> */}
                        <th style={{ color: "#0a3a32",width:'15%' }}>total</th>
                        
                      </tr>
                    </thead>
                <tbody>
                {orderitems &&
                              orderitems.map((item) => (
                      <tr>
                        <td style={{width:'25%' }}>
                          <img
                            src={item.product_image}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td style={{width:'45%' }}>{item.product_name}</td>
                        <td style={{width:'15%' }}>{item.quantity}</td>
                        {/* <td></td> */}
                        <td style={{width:'15%' }}>AED {item.item_sub_total}</td>
                      </tr>
                      ))}
                </tbody>
              </table>
              <div className="mt-3" style={{ marginTop: "30px" }}>
                <Link
                  to="/cart"
                  className="btn zoa-btn "
                  style={{
                    background: "#0c3b33",
                    color: "#fff",
                    padding: "13px 30px",
                    width: "200px",
                  }}
                >
                  Go back to cart
                </Link>
              </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCancel;
