import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { baseUrl } from '../../constants';

function Subcategory() {
  const [subcategories, setCategories] = useState([]);
  const history = useHistory();
  // var url_param=router.query.category;
  let { category } = useParams();
  console.log("useParams", category);
  let selCategory;
  if (typeof category == "undefined") {
    selCategory = "";
  } else {
    selCategory = category;
  }
  const category_slug = selCategory;

  useEffect(() => {
    axios
      .get(
        baseUrl+`/getSubcategory?category_slug=${category_slug}`,
        {}
      )
      .then((res) => {
        // dispatch(fetchproducts(res.data));
        setCategories(res.data.sub_categories);
      })
      .catch((err) => {});
  }, [category_slug]);

  return (
    <div>
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="./img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={({ color: "#0a3a32" }, { textTransform: "uppercase" })}>
          {category_slug}
        </h1>
        {/* <ul className="breadcrumb">
                <li><a style={{textTransform: 'uppercase'}}>{url_param}</a></li>
                <li class="active" style={{color: '#0a3a32',textTransform: 'uppercase'}}>Categories</li>
            </ul> */}
      </div>
	  <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={() => history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
		  
          <li className="active" style={{textTransform:"capitalize"}}>{selCategory}</li>
        </ul>
      </div>
      <div className="container mt-3">
        <div className="row">
          {subcategories &&
            subcategories.map((subcategory) => (
              <div
                className="col-md-4 hover-images"
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  cursor: "pointer",
                }}
              >
                <Link
                  to={{
                    pathname:
                      "/shopsubcategory/"+category +'/'+ subcategory.sub_category_slug,
                  }}
                >
                  <div>
                    <div style={{ border: "1px solid #eee" }}>
                      <img
                        src={subcategory.sub_category_image_url}
                        style={{ objectFit: "cover", width: "100%",height:"400px" }}
                      />
                    </div>
                    {/* <p>{subcategory.sub_category_slug}</p> */}
                    <h4>{subcategory.sub_category_name}</h4>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Subcategory;
