import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { baseUrl } from '../../constants';
import parse from 'html-react-parser';


function Terms() {
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
  }
  useEffect(()=>{
    Axios
  .post(baseUrl+"/getCms",
  {id:3}).then((res) => {
    // dispatch(fetchproducts(res.data));
    setDescription(res.data.data.description)
    setTitle(res.data.data.title)
  })
  .catch((err) => {});

  },[])

  const config = { headers: { Authorization: `Bearer ${token}` } };
  const [description,setDescription] = useState('');
  const [title,setTitle] = useState('');

  return (
    <div className="footer-item">
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="/img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={{ color: "#0a3a32", textTransform: "uppercase" }}>
        {title}
        </h1>
      </div>

        <div className="container">
            <div className="about-content bd-bottom">
                <div className="row">
                    <div className="col-md-12 col-sm-6 col-xs-12">
                        
                        <div className="about-info">
                            
                            <div className="about-desc">
                                <p style={{color:"#666"}}>{parse(description)}</p>
                                {/* <p style={{color:"#666"}}>Zoa stands for a personal and obstinate selection. Surprising with new designers every season, great attention is given to the unique & personal identity of all in-store designers. From clothing to jewellery, shoes & bags, each piece is chosen with special care.</p> */}
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
       
      
    </div>
      
  );
}


export default Terms;
