import React, { useEffect, useState } from "react";
import Image from "next/image";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../auth/Login";
import SideMenu from "./SideMenu";
import SideCart from "./SideCart";
import Search from "./Search";
import { updateSideCartDetails } from "../../redux/actions/cartActions";
import $ from 'jquery';

function Header() {
  const location = useLocation();
  const [scrollableHeader,setscrollableHeader] = useState(true);
  useEffect(()=>{
    console.log('location  ',location)
    if(location.pathname ==='/')
      setscrollableHeader(true);
    else
    setscrollableHeader(false);
  },[location])
  // setInterval(triggerHeightCalculation, 100);
  // var menuLeft = $('.pushmenu-left');
  // var menuHome6 = $('.menu-home5');
  // var nav_list = $('.icon-cart');
  // var nav_click = $('.icon-pushmenu');
  // nav_list.on("click", function(event) {
  //   console.log('menu clciked')
  //     event.stopPropagation();
  //     $(this).toggleClass('active');
  //     $('body').toggleClass('pushmenu-push-toright-cart');
  //     menuLeft.toggleClass('pushmenu-open');
  //     $(".container").toggleClass("canvas-container");
  // });
  // nav_click.on("click", function(event) {
  //   console.log('menu clciked')
  //     event.stopPropagation();
  //     $(this).toggleClass('active');
  //     $('body').toggleClass('pushmenu-push-toleft');
  //     menuHome6.toggleClass('pushmenu-open');
  // });
  // $(".wrappage").on("click", function() {
  //   console.log('wrappage clciked')
  //     $(this).removeClass('active');
  //     $('body').removeClass('pushmenu-push-toright-cart').removeClass('pushmenu-push-toleft');
  //     menuLeft.removeClass('pushmenu-open');
  //     menuHome6.removeClass('pushmenu-open');
  // });
  // $(".close-left").on("click", function() {
  //     $(this).removeClass('active');
  //     $('body').removeClass('pushmenu-push-toright-cart');
  //     menuLeft.removeClass('pushmenu-open');
  // });
  // $(".close-left").on("click", function() {
  //     $('body').removeClass('pushmenu-push-toleft');
  //     menuHome6.removeClass('pushmenu-open');
  // });
  const [showSideCart, setShowSideCart] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { cart } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
  }
  console.log("cart==", cart);
  const callCartDetails = (e) => {
    e.preventDefault();
    // console.log("heeeee", showSideCart);
    //setShowSideCart(true);
    //dispatch(updateSideCartDetails());
    $(".js-close").click();
    history.push("/cart");
  };
  const showCart = (val) => {
    setShowSideCart(val);
  };
  const showLogin = (val) => {
    console.log("tt", token);
    if (token != null) {
      $(".btn-search-close").click();
      history.push("/profile");
    } else {
      setIsLogin(true);
    }
  };
  const handleMenu = () => {
    console.log("handleMenu");
    setShowMenu(false);
  };
  const handleClose = (val) => {
    setIsLogin(false);
  };
  const handleMenuClose = () => {
    setShowMenu(false);
  };
  return (
    <header id="header" className={`header-v1 ${scrollableHeader?'navbar-fixed-top':''}`}>
      <Login showLogin={isLogin} handleClose={handleClose} />
      <SideMenu showMenu={showMenu} handleCloseMenu={handleMenuClose} />
      <Search />
      <div className="header-center">
        <div className="container container-content">
          <div className="row flex align-items-center justify-content-between">
            <div className="col-md-4 col">
              <div className="topbar-right">
                <div className="element" onClick={handleMenu}>
                  <a className="icon-pushmenu js-push-menu" onClick={(e)=>{console.log(e)}}>
                    <img src="img/icon_menu.svg" width={26} height={16}></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col flex justify-content-center">
              <a  style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
                <img
                  src="img/logo.png"
                  alt=""
                  className="img-responsive"
                  onClick={() => history.push("/")}
                />
              </a>
            </div>
            <div className="col-md-4 col flex justify-content-end">
              <div className="topbar-left flex">
                <div className="element element-search hidden-xs hidden-sm">
                  <a className="zoa-icon search-toggle">
                    <img src="img/icon_search.svg" height={20} width={20}></img>
                  </a>
                </div>
                <div className="element element-user">
                  <a
                    className={token == null ? "zoa-icon js-user" : ""}
                    onClick={showLogin}
                  >
                    <img src="img/icon_user.svg" height={20} width={19} />
                  </a>
                </div>
                <div className="element element-cart">
                  {/* <div onClick={callCartDetails}> */}
                  <div onClick={callCartDetails}>
                    <img src="img/icon_cart.svg" height={20} width={20} />{" "}
                  </div>
                  <span
                    className="zoa-icon icon-cart2"
                    onClick={callCartDetails}
                  >
                    <span
                      className="count cart-count"
                      style={{ cursor: "pointer" }}
                      onClick={callCartDetails}
                    >
                      {cart.cart_count != null ? cart.cart_count : 0}
                    </span>
                  </span>
                  {/* </div> */}
                  {/* <SideCart
                    showSideCart={showSideCart}
                    setShowSideCart={showCart}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
