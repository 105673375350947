

const generateToken = () => {
    var randomstring           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 20; i++ ) {
          randomstring += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return randomstring;
};

export default generateToken;