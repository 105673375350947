import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import axios from "axios";

import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { baseUrl } from '../../constants';

function ListCategory() {
	 const history = useHistory();
  const [categorylist, setCategoryList] = useState([]);
  useEffect(() => {
    axios
      .get(baseUrl+`/getCategoryList`, {})
      .then((res) => {
        // dispatch(fetchproducts(res.data));
        setCategoryList(res.data.category);
      })
      .catch((err) => {});
  },[]);

  return (
    <div>
      <div
        className="shop-heading text-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src="./img/shop-head.png"
          alt=""
          style={{
            opacity: "0.1",
            position: "absolute",
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <h1 style={({ color: "#0a3a32" }, { textTransform: "uppercase" })}>
          Category List
        </h1>
        {/* <ul className="breadcrumb">
                <li><a style={{textTransform: 'uppercase'}}>{url_param}</a></li>
                <li class="active" style={{color: '#0a3a32',textTransform: 'uppercase'}}>Categories</li>
            </ul> */}
      </div>
	  <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={() => history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>Category List</li>
        </ul>
      </div>
      <div className="container mt-3">
        <div className="row">
          {categorylist &&
            categorylist.map((category) => (
              <div
                className="col-md-4 hover-images"
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  cursor: "pointer",
                }}
              >
                {category.have_sub_category == 1 ? (
                  <Link
                    to={{
                      pathname: "/subcategory/" + category.category_slug,
                    }}
                  >
                    <div>
                      <div style={{ border: "1px solid #eee" }}>
                        <img
                          src={category.category_image_url}
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "383px",
                          }}
                        />
                      </div>
                      {/* <p>{subcategory.sub_category_slug}</p> */}
                      <h4>{category.category_name}</h4>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/shop/" + category.category_slug,
                    }}
                  >
                    <div>
                      <div style={{ border: "1px solid #eee" }}>
                        <img
                          src={category.category_image_url}
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "383px",
                          }}
                        />
                      </div>
                      {/* <p>{subcategory.sub_category_slug}</p> */}
                      <h4>{category.category_name}</h4>
                    </div>
                  </Link>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ListCategory;
