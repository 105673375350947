import React, { useState } from "react";
import { useHistory } from "react-router";
import $ from "jquery";

function Search(props) {
  const history = useHistory();
  const [searchKey, setSearchKey] = useState("");
  const handleSearch = () => {
    $(".btn-search-close").click();
    history.push("/products/" + searchKey);
  };
  const handleKeyPress = (event) => {
    console.log("handleKeyPress");
    console.log(event.key);
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const closeMenu = () => {
    $(".btn-search-close").click();
    setSearchKey('');
  };
  return (
    <div class="search-form-wrapper header-search-form">
      <div class="container">
        <div class="search-results-wrapper">
          <div class="btn-search-close" onClick={closeMenu}>
            <i class="ion-ios-close-empty"></i>
          </div>
        </div>
        {/* <ul class="zoa-category text-center">
          <li>
            <a href="">All Categories</a>
          </li>
          <li>
            <a href="">Woman</a>
          </li>
          <li>
            <a href="">Man</a>
          </li>
          <li>
            <a href="">Accessories</a>
          </li>
          <li>
            <a href="">Kid</a>
          </li>
          <li>
            <a href="">Others</a>
          </li>
        </ul> */}
        <div class="search-form">
          <input
            class="search-input"
            type="text"
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyDown={handleKeyPress}
            value={searchKey}
            placeholder="Enter your keywords..."
            autocomplete="off"
          />
          {/* <input type="hidden" name="post_type" value="product" /> */}
          <button onClick={handleSearch} id="search-btn">
            <i class="ion-ios-search-strong"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;
