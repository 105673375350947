import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useHistory, useParams } from "react-router-dom";

import { cartTotalCount } from "../../redux/actions/cartActions";
import './changePassword.css';
import { baseUrl } from '../../constants';

function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [addressbyid, setaddressbyid] = useState([""]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [cname, setCname] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [fnameship, setFnameship] = useState("");
  const [lnameship, setLnameship] = useState("");
  const [countryship, setCountryship] = useState("");
  const [cnameship, setCnameship] = useState("");
  const [cityship, setCityship] = useState("");
  const [addressship, setAddressship] = useState("");
  const [zipship, setZipship] = useState("");
  const [phoneship, setPhoneship] = useState("");
  const [emailship, setEmailship] = useState("");

  const [addresslist, setaddressList] = useState([]);
  const [addressid, setaddressId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [passerror, setPasserror] = useState('');
  const [cpasserror, setCpasserror] = useState('');
  const [npasserror, setNpasserror] = useState('');
  
  const [useraccount, setuserDetails] = useState("");
  // console.log(addresslist);
  // console.log(addressid)
  // console.log(addressbyid);
  const [shippingAddressForm, setShippingAddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company_name: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
  const [billingAddressForm, setBillingddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company_name: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
  const [billingAddressList, setBillingAddressList] = useState([]);
  const [shippingAddressList, setShippingAddressList] = useState([]);
  const [addressType, setAddressType] = useState("billing");
  const [billingDefaultAddress, setAsBillingDefaultAddress] = useState(false);
  const [shippingDefaultAddress, setAsShippingDefaultAddress] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);

  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
    // console.log(token)
  }

  const config = { headers: { Authorization: `Bearer ${token}` } };

  const ShippingingAddress = () => {
    axios
      .post(
        baseUrl+"/createAddress",
        {
          first_name: fname,
          last_name: lname,
          company: cname,
          country: country,
          city: city,
          street: address,
          zip: zip,
          phone: phone,
          email: email,
          address_type: "shipping",
        },
        config
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.setItem("cart_total_count", 0);
    dispatch(cartTotalCount(0));
    //window.reload();
    history.push("/");
  };
  useEffect(() => {
    console.log(addressType);
    if (addressType === "billing")
      billingAddressList.forEach((x) => {
        console.log(x.address_id, addressid);
        if (Number(x.address_id) === Number(addressid))
          setBillingddressForm({
            address_id: x.address_id,
            address_title: x.address_title,
            first_name: x.first_name,
            last_name: x.last_name,
            company_name: x.company,
            country: x.country,
            city: x.city,
            street: x.street,
            zip: x.zip,
            phone: x.phone,
            email: x.email,
            default_address: null,
          });
      });
    if (addressType === "shipping")
      shippingAddressList.forEach((x) => {
        if (Number(x.address_id) === Number(addressid))
          setShippingAddressForm({
            address_id: x.address_id,
            address_title: x.address_title,
            first_name: x.first_name,
            last_name: x.last_name,
            company_name: x.company,
            country: x.country,
            city: x.city,
            street: x.street,
            zip: x.zip,
            phone: x.phone,
            email: x.email,
            default_address: null,
          });
      });
  }, [addressid]);
  useEffect(() => {
    if (saveSuccessful)
      setTimeout(() => {
        setSaveSuccessful(false);
      }, 2000);
  }, [saveSuccessful]);
  useEffect(() => {
    if (saveFailed)
      setTimeout(() => {
        setSaveFailed(false);
      }, 2000);
  }, [saveFailed]);
  const fillBillingAddressForm = (type, value) => {
    let temp = { ...billingAddressForm };
    temp[type] = value;
    setBillingddressForm(temp);
  };
  const fillShippingAddressForm = (type, value) => {
    let temp = { ...shippingAddressForm };
    temp[type] = value;
    setShippingAddressForm(temp);
  };
  useEffect(() => {
    console.log(addressType);
    axios
      .get(
        baseUrl+ `/listAddress?type=${addressType}`,
        config
      )
      .then(function (res) {
        // handle success
        // console.log(response.data);
        if (addressType === "billing")
          setBillingAddressList(res.data.address_list);
        if (addressType === "shipping")
          setShippingAddressList(res.data.address_list);
        // setaddressList(res.data.address_list);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [addressType]);

  useEffect(() => {
    axios
      .get(baseUrl+"/getUserAccount", config)
      .then(function (res) {
        // handle success
        // console.log(response.data);
        if (res.data.success) setuserDetails(res.data.user);
        else history.push("/login");
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const billingAddress = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl+"/updateAddress",
        {
          address_id: billingAddressForm.address_id,
          address_title: billingAddressForm.address_id,
          first_name: billingAddressForm.first_name,
          last_name: billingAddressForm.last_name,
          company: billingAddressForm.company,
          country: billingAddressForm.country,
          city: billingAddressForm.city,
          street: billingAddressForm.street,
          zip: billingAddressForm.zip,
          phone: billingAddressForm.phone,
          email: billingAddressForm.email,
          address_type: "billing",
          default_address: billingDefaultAddress ? 1 : 0,
        },
        config
      )
      .then(function (response) {
        console.log(response);
        setSaveSuccessful(true);
      })
      .catch(function (error) {
        console.log(error);
        setSaveFailed(true);
      });
  };
  const billingAddressCreate = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl+"/createAddress",
        {
          address_title: billingAddressForm.address_title,
          first_name: billingAddressForm.first_name,
          last_name: billingAddressForm.last_name,
          company: billingAddressForm.company,
          country: billingAddressForm.country,
          city: billingAddressForm.city,
          street: billingAddressForm.street,
          zip: billingAddressForm.zip,
          phone: billingAddressForm.phone,
          email: billingAddressForm.email,
          address_type: "billing",
          default_address: billingDefaultAddress ? 1 : 0,
        },
        config
      )
      .then(function (response) {
        setSaveSuccessful(true);
        console.log(response);
      })
      .catch(function (error) {
        setSaveFailed(true);
        console.log(error);
      });
  };
  const shippingAddressCreate = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl+"/createAddress",
        {
          address_title: shippingAddressForm.address_title,
          first_name: shippingAddressForm.first_name,
          last_name: shippingAddressForm.last_name,
          company: shippingAddressForm.company,
          country: shippingAddressForm.country,
          city: shippingAddressForm.city,
          street: shippingAddressForm.street,
          zip: shippingAddressForm.zip,
          phone: shippingAddressForm.phone,
          email: shippingAddressForm.email,
          address_type: "shipping",
          default_address: shippingDefaultAddress ? 1 : 0,
        },
        config
      )
      .then(function (response) {
        setSaveSuccessful(true);
        console.log(response);
      })
      .catch(function (error) {
        setSaveFailed(true);
        console.log(error);
      });
  };
  const shippingAddressUpdate = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl+"/updateAddress",
        {
          address_id: billingAddressForm.address_id,
          address_title: billingAddressForm.address_id,
          first_name: shippingAddressForm.first_name,
          last_name: shippingAddressForm.last_name,
          company: shippingAddressForm.company,
          country: shippingAddressForm.country,
          city: shippingAddressForm.city,
          street: shippingAddressForm.street,
          zip: shippingAddressForm.zip,
          phone: shippingAddressForm.phone,
          email: shippingAddressForm.email,
          address_type: "shipping",
          default_address: shippingDefaultAddress ? 1 : 0,
        },
        config
      )
      .then(function (response) {
        setSaveSuccessful(true);
        console.log(response);
      })
      .catch(function (error) {
        setSaveFailed(true);
        console.log(error);
      });
  };
  const displayForm = () => {};
  const toOrderlist = () => {
    history.push("/orders");
  };
  const changeAddressType = (val) => {
    setAddressType(val);
  };
  const changePasswordForm = (e) =>{
    e.preventDefault();

    var isallow = 0;
    var icsallow=0;
    var insallow=0;
    var reg = /[^0-9a-zA-Z]/;  
    if(changePasswordFieldForm.current==null || changePasswordFieldForm.current==""){
      isallow=1;
      setPasserror('Please enter current password');
    }else{
      if(reg.test(changePasswordFieldForm.current)){
        if (changePasswordFieldForm.current.length < 8) {
          setPasserror("Your password must be at least 8 characters"); 
      }else{
        isallow=0;
        setPasserror('');
      }
      }else{
        setPasserror('Password should contain one Capital, one small letter and a special character');
      }
    }

    if(changePasswordFieldForm.new==null || changePasswordFieldForm.new==""){
      icsallow=1;
     
      setNpasserror('Please enter new password');
    }else{
      if(reg.test(changePasswordFieldForm.new)){
        if (changePasswordFieldForm.new.length < 8) {
          setNpasserror("Your password must be at least 8 characters"); 
      }else{
        icsallow=0;
        setNpasserror('');
      }
      }else{
        setNpasserror('Password should contain one Capital, one small letter and a special character');
      }
    }

    if(changePasswordFieldForm.confirm==null || changePasswordFieldForm.confirm==""){
      insallow=1;
      setCpasserror('Please enter confirm password');
    }else{
      if(reg.test(changePasswordFieldForm.confirm)){
        if (changePasswordFieldForm.confirm.length < 8) {
          setCpasserror("Your password must be at least 8 characters"); 
      }else{
      insallow=0;
        setCpasserror('');
      }
      }else{
        setCpasserror('Password should contain one Capital, one small letter and a special character');
      }
    }

    if(isallow==1 || icsallow==1|| insallow==1){
      return false;
    }

    axios
      .post(
        baseUrl+"/changePassword",
        {
            current_password:changePasswordFieldForm.current,
            new_password:changePasswordFieldForm.new,
            confirm_password:changePasswordFieldForm.confirm, 
        },
        config
      )
      .then(function (response) {
        setShowSuccessPopup(true);
        console.log(response);
        if(response.data.success)
        setShowResponseMessage(response.data.message)
        else
        setShowResponseMessage(response.data.error)

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [changePasswordFieldForm,setChangePasswordFieldForm] = useState({
      current:null,new:null,confirm:null
  });
  useEffect(()=>{
      console.log('changePasswordFieldForm    ',changePasswordFieldForm)
  },[changePasswordFieldForm]);
  const changeCurrent = (e) => {let temp = {...changePasswordFieldForm}; temp.current=e.target.value; setChangePasswordFieldForm(temp);} 
  const changeNew = (e) => {let temp = {...changePasswordFieldForm}; temp.new=e.target.value; setChangePasswordFieldForm(temp);} 
  const changeConfirm = (e) => {let temp = {...changePasswordFieldForm}; temp.confirm=e.target.value; setChangePasswordFieldForm(temp);}
  const [showSuccessPopup,setShowSuccessPopup] = useState(false);
  const [showResponseMessage,setShowResponseMessage] = useState(null);

  useEffect(()=>{
    console.log('showSuccessPopup   ',showSuccessPopup)
  if(showSuccessPopup)
  setTimeout(() => {
    setShowSuccessPopup(false)
  }, 1000);
  },[showSuccessPopup])
//   const changeNew = (e) => setChangePasswordFieldForm({...changeAddressType,new:e.target.value});
//   const changeConfirm = (e) => setChangePasswordFieldForm({...changeAddressType,confirm:e.target.value});
  console.log("addressType", addressType);
  return (
    <div>
      <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={() => history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>My Account</li>
        </ul>
      </div>

      <div className="my-account">
        <div className="container container-content ">
          <div className="row">
            <div className="col-md-2 col-sm-2 howday">
              <div className="titlelt">
                <h2>
                  Hello, <strong> {useraccount.name} !</strong>
                </h2>
                <div className="login">
                  <ul className="nav ">
                    <li>
                    <a
                        onClick={() => history.push("/profile")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="img/Icon_Add.jpg" alt="Icon_User.jpg" />{" "}
                        Address
                      </a>
                    </li>

                    <li>
                      <a onClick={toOrderlist} style={{ cursor: "pointer" }}>
                        <img src="img/Icon_Listing.jpg" alt="Icon_User.jpg" />{" "}
                        My orders
                      </a>
                    </li>
                    <li>
                      <a onClick={()=>history.push('/change-password')}>
                        <img src="img/Icon_Lock.jpg" alt="Icon_User.jpg" />
                        change password
                      </a>
                    </li>
                    <li>
                      <a onClick={logout}>
                        <img src="img/Icon_Off.jpg" alt="Icon_User.jpg" /> log
                        out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        



           <div class="col-md-9 col-sm-12 porfolio">
                            <div class="tab-content" style={{border:'none'}}>
                                <div id="account-details" class="tab-pane fade in active">
                               
                                   
    
                                <div class="tab-content" style={{border:'none'}}>
                                    <div id="home" class="tab-pane fade in active">
                                      <div class="form">
                                            <form  onSubmit={changePasswordForm} method="post">
                                                <div class="row">
                                                    <div class="col-md-6 col-sm-6">
                                                         <label>Current Password</label><br />
                                                        <input type="password" style={{textTransform:'none'}} name="password" placeholder="Current Password"  value={changePasswordFieldForm.current} onChange={changeCurrent} class="country" />
                                                        <div className="invalid-feedback">
                                                          {passerror}
                                                        </div>
                                                     </div>
                                                      
                                                     <div class="col-md-6 col-sm-6">
                                                       
                                                    </div>
                                                </div>
                                                <div class="row">    
                                                    <div class="col-md-6 col-sm-6">
                                                        <label>New Password</label><br />
                                                         <input type="password" style={{textTransform:'none'}} name="cpassword" placeholder="New Password"  value={changePasswordFieldForm.new} onChange={changeNew} class="city" />
                                                         <div className="invalid-feedback">
                                                          {npasserror}
                                                        </div>
                                                     </div>
                                                     
                                                     <div class="col-md-6 col-sm-6">
                                                        <label>Confirm Password</label><br />
                                                         <input type="password" style={{textTransform:'none'}} name="npassword" placeholder="Confirm Password"  value={changePasswordFieldForm.confirm} onChange={changeConfirm} class="city" />
                                                         <div className="invalid-feedback">
                                                          {cpasserror}
                                                        </div>
                                                     </div>
                                                     
                                                </div>

                                               
                                                
                                                <button class="change">Save change</button>
                                            </form>
                                            <div class={`modal fade ${showSuccessPopup?'in':''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showSuccessPopup?"false":"true"} style={{display:`${showSuccessPopup?'block':'none'}`}}>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
        {/* ... */}
        <img src={showResponseMessage!==null && showResponseMessage.includes('success')?'img/check.png':'img/red-cross.png'} alt="check" style={{width:"30px",height:"30px",marginRight:"5px"}}/> <p style={{margin:'0px',color:'#0a3a32'}}>{showResponseMessage}</p>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
                                        </div>
                                    </div>
                                      
                            </div>
                        </div>    
                        </div>
                    </div>



          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;