import { baseUrl } from '../constants';

export const homeSliders = () => {
  const result = fetch(baseUrl+"/getHome").then(
    (res) => res.json()
  );
  //console.log("pok",result)

  return result;
};
