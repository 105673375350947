import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import DemoCarousel from '../CarousalSlider/index';
import "./DemoCarousal.css";
// class DemoCarousel extends Component {
    
//     render() {
//         return (
//             <Carousel>
//                 {this.props.images.map((image=>
//                 <div>
//                     <img src={image} />
//                     <p className="legend">Legend 1</p>
//                 </div>
//                 ))}
//                 {/* <div>
//                     <img src="assets/2.jpeg" />
//                     <p className="legend">Legend 2</p>
//                 </div>
//                 <div>
//                     <img src="assets/3.jpeg" />
//                     <p className="legend">Legend 3</p>
//                 </div> */}
//             </Carousel>
//         );
//     }
// }
const DemoCarousel = ({images,checked,dimages}) =>  {
    console.log('dimages   ',dimages)
    const selectedImageChange = (index,item) => {console.log('index   ',index,'item    ',item);checked(index)}
    console.log(images.indexOf(dimages)===-1?0:images.indexOf(dimages))
    return(
    <Carousel onChange={selectedImageChange} selectedItem={images.indexOf(dimages)===-1?0:images.indexOf(dimages)} showArrows={true}>
        {images.map((image=>
        <div>
            <img src={image} />
            {/* <p className="legend">Legend 1</p> */}
        </div>
        ))}
        {/* <div>
            <img src="assets/2.jpeg" />
            <p className="legend">Legend 2</p>
        </div>
        <div>
            <img src="assets/3.jpeg" />
            <p className="legend">Legend 3</p>
        </div> */}
    </Carousel>
);}
// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default DemoCarousel;
