import React, { useState } from "react";
import { useHistory } from "react-router";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { handleMenu } from "../../redux/actions/productActions";
import { useLocation } from 'react-router-dom';
import { backendUrl } from '../../constants';

function SideMenu(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { products } = useSelector((state) => state);
  const [searchKey, setSearchKey] = useState("");
  const handleSearch = () => {
    $(".js-close").click();
    history.push("/products/" + searchKey);
    setSearchKey("");
  };
  const gotoShop = () => {
    $(".js-close").click();
    console.log('location   ',location);
    if(location.pathname.includes('/shop')){
      history.push({ 
        pathname: "/shop",
        state: {},
      });
      window.location.reload();
    }
    else
    history.push({ 
      pathname: "/shop",
      state: {},
    });
  };
  const gotoHome = () => {
    $(".js-close").click();
    history.push("/");
  };
  const gotoLogin = () => {
    history.push("/login");
  };
  let token;
  if (typeof window !== "undefined") {
    // Perform localStorage action
    const token = localStorage.getItem("user");
  }
  const closeMenu = () => {
    $(".js-close").click();
  };
  const handleKeyPress = (event) => {
    console.log("handleKeyPress");
    console.log(event.key);
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div
      className={
        "pushmenu menu-home5" + (products.showMenu ? "pushmenu-open" : "")
      }
    >
      <div className="menu-push">
        <img onClick={gotoHome}
          src={backendUrl+'/public/img/balaclavia-side.png'}
          style={{ width: "180px", marginLeft: "28px", marginTop: "10px" }}
        />
        <span className="close-left js-close" onClick={closeMenu}>
          <i className="ion-ios-close-empty f-40"></i>
        </span>
        <div className="clearfix"></div>

        <div className="searchform">
          <div>
            <label className="screen-reader-text" for="q"></label>
            <input
              type="text"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Search for products"
              autocomplete="off"
            />

            <button onClick={handleSearch} id="searchsubmit">
              <i className="ion-ios-search-strong"></i>
            </button>
          </div>
        </div>
        <ul className="nav-home5 js-menubar">
          <li className="level1 active dropdown">
            <a onClick={gotoHome} style={{ cursor: "pointer" }}>
              Home
            </a>
          </li>
          <li className="level1 active dropdown">
            <a onClick={gotoShop} style={{ cursor: "pointer" }}>
              Shop
            </a>
          </li>
        </ul>
        {token === null && (
          <ul className="mobile-account">
            <li>
              <a onClick={gotoLogin} className="js-user">
                <i className="fa fa-unlock-alt"></i>Login
              </a>
            </li>
            <li>
              <a onClick={gotoLogin}>
                <i className="fa fa-user-plus"></i>Register
              </a>
            </li>
          </ul>
        )}
        <h4 className="mb-title">connect and follow</h4>
        <div className="mobile-social mg-bottom-30">
          <a href="">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="">
            <i className="fa fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
