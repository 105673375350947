import React from "react";
import { Link } from "react-router-dom";

function HomeCategory({ homecategory }) {
  return (
    <div>
      <div className="collection-4">
        <div className="collection-grid">
          {homecategory.map((homecate, index) => (
            <div
              className={
                index == 0
                  ? "collection-element f-col4"
                  : index == 1
                  ? "collection-element f-col6"
                  : index <= 2 && index > 4
                  ? "collection-element f-col4"
                  : "collection-element f-col4"
              } style={{justifyContent: "center",
              textAlign: "center"}}
              key={homecate.category_id}
            >{index===0 &&  <HomeCategoryTile homecate={homecate} index={index}/>}
              {index!==0 &&( homecate.have_sub_category == 1 ? (
      <Link
        to={{
          pathname: "/subcategory/" + homecate.category_slug,
        }}
      >
        <HomeCategoryTile homecate={homecate} index={index}/>
      </Link>
    ) : (
      <Link
        to={{
          pathname: "/shop/" + homecate.category_slug,
        }}
      >
         <HomeCategoryTile homecate={homecate} index={index}/>
      </Link>
    ))}
           
            </div>
          ))}
          <div class="collection-element f-col4 sm-f-col6 xs-f-col6">
            <div class="collection-img hover-images">
              <img
                src="img/home8/category-blank.png"
                alt=""
                class="img-responsive"
              />
              <div class="box-center content style1" style={{justifyContent: "center",
                textAlign: "center"}}>
                <h3>
                  <Link
                    to={{
                      pathname: "/listcategory",
                    }}
                  >
                    + want more?
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const HomeCategoryTile = ({homecate,index}) =>   (<div className="collection-img hover-images">
{index!==0 && <img
  src={homecate.category_image_url}
  alt=""
  style={{ height: "400px", objectFit: "cover" }}
  className="img-responsive"
/>}
<div
  className={
    index == 0
      ? ""
      : index == 1
      ? "box-center content style2"
      : index > 1 && index < 7
      ? "box-center content style2"
      : "box-center content style1"
  }
>
  <h3 style={{ color: "#0a3a32" }}><></>
  {index !== 0 && <>{homecate.category_name}</>}
  {index === 0 && <>trending <br/> categories</>}
  </h3>
</div>
</div>);

export default HomeCategory;
