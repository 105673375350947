import React from "react";
import { useHistory } from "react-router";
function HomeFlash({ homeflash, product }) {
  const history = useHistory();
  console.log("homeflash", homeflash, product);
  const viewProduct = () => {
    history.push("/product/" + product.product_id+'/homeBanner');
  };
  return (
    <>
      {product && // 👈 null and undefined check
        Object.keys(product).length != 0 &&
        homeflash[0].banner_status === '1' && (
          <div>
            <div className="flash-deal pad">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <a className="hover-images">
                      <img
                        className="img-responsive"
                        src={homeflash[0].banner_image_url}
                        alt=""
                      />
                    </a>
                    <div className="box-center content ">
                    <a  className="tag-title"></a>
                    <h3 style={{ color: "#0a3a32" }}>
                      {homeflash[0].banner_title1}
                    </h3>
                    <h3 style={{ color: "#0a3a32" }}>
                      {homeflash[0].banner_title2}
                    </h3>
                  </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="content text-center">
                      <h3>{product.product_bannername}</h3>
                      <p>{product.product_desc}</p>
                      <div className="product-img">
                        <img
                          src={product.product_image}
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div
                        className="countdown countdown-time"
                        data-countdown="countdown"
                        data-date="08-31-2018-00-00-00"
                      ></div>
                      <div className="product-content product-info">
                        <h3>
                          <a>{product.product_name}</a>
                        </h3>

                        {product.have_offer == 1 ? (
                          <div className="product-price">
                            <span className="old">
                              AED {product.product_price}
                            </span>
                            <span>AED {product.product_offer_price}</span>
                          </div>
                        ) : (
                          <div className="product-price">
                            <span>AED {product.product_price}</span>
                          </div>
                        )}

                        <button
                          className="zoa-btn btn-add-cart"
                          onClick={viewProduct}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      <></>
    </>
  );
}

export default HomeFlash;
