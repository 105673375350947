import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useHistory, useParams,useLocation } from "react-router-dom";

import { cartTotalCount } from "../../redux/actions/cartActions";
import './changePassword.css';
import { baseUrl } from '../../constants';

function ForgotPassword() {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const link = searchParams.get('link');
  const [email,setEmail] = useState(null);

 
  const [cpasserror, setCpasserror] = useState('');
  const [npasserror, setNpasserror] = useState('');
  
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [regemailmessage, setRegemailmessage] = useState("");

//   let link = searchParams.get("link");
// console.log('link   ',link);
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
    // console.log(token)
  }

  const config = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    if (saveSuccessful)
      setTimeout(() => {
        setSaveSuccessful(false);
      }, 2000);
  }, [saveSuccessful]);
  useEffect(() => {
    if (saveFailed)
      setTimeout(() => {
        setSaveFailed(false);
      }, 2000);
  }, [saveFailed]);

  const ForgotPasswordEmailForm = (e) =>{
    e.preventDefault();
    var reg = /[^0-9a-zA-Z]/;  
    var isValid = 1;

    if (emailreg == "") {
      setRegemailmessage("Please enter Email Id");
      isValid = 0;
    } 
    else {
      if (typeof emailreg !== "undefined") {
        let lastAtPos = emailreg.lastIndexOf("@");
        let lastDotPos = emailreg.lastIndexOf(".");
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            emailreg.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            emailreg.length - lastDotPos > 2
          )
        ) {
          setRegemailmessage("Email is not valid");
          isValid = 0;
        } else {
          setRegemailmessage("");
        }
      }
    }
    if (isValid == 0) {
      return false;
    }
    axios
      .post(
        baseUrl+"/forgotPassword",
        {
            // current_password:forgotPasswordFieldForm.current, 
            email:emailreg,
            baselink:`${window.location.origin}${window.location.pathname}`
        },
        config
      )
      .then(function (response) {
        setShowSuccessPopup(true);
        console.log(response);
        if(response.data.success)
        setShowResponseMessage(JSON.stringify(response.data.message))
        else
        setShowResponseMessage(JSON.stringify(response.data.error))

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const forgotPasswordForm = (e) =>{
    e.preventDefault();

    // var isallow = 0;
    // var icsallow=0;
    // var insallow=0;
    var isAllow = 1;
    var reg = /[^0-9a-zA-Z]/;
    
    // if(forgotPasswordFieldForm.current==null || forgotPasswordFieldForm.current==""){
    //   isallow=1;
    //   setPasserror('Please enter current password');
    // }else{
    //   if(reg.test(forgotPasswordFieldForm.current)){
    //     if (forgotPasswordFieldForm.current.length < 8) {
    //       setPasserror("Your password must be at least 8 characters"); 
    //   }else{
    //     isallow=0;
    //     setPasserror('');
    //   }
    //   }else{
    //     setPasserror('Password should contain one Capital, one small letter');
    //   }
    // }

    if(forgotPasswordFieldForm.new==null || forgotPasswordFieldForm.new==""){
      // icsallow=1;
      isAllow = 0;
      setNpasserror('Please enter new password');
    }else{
     console.log( 'reg.test   ',reg.test(forgotPasswordFieldForm.new),'forgotPasswordFieldForm.new  ',forgotPasswordFieldForm.new)
      if(reg.test(forgotPasswordFieldForm.new)){
        if (forgotPasswordFieldForm.new.length < 8) {
      isAllow = 0;
          setNpasserror("Your password must be at least 8 characters"); 
      }else{
        // icsallow=0;
        setNpasserror('');
      }
      }else{
      isAllow = 0;
        setNpasserror('Password should contain one Capital, one small letter and a special character');
      }
    }

    if(forgotPasswordFieldForm.confirm==null || forgotPasswordFieldForm.confirm==""){
      // insallow=1;
      isAllow = 0;

      setCpasserror('Please enter confirm password');
    }else{
      if(reg.test(forgotPasswordFieldForm.confirm)){
        if (forgotPasswordFieldForm.confirm.length < 8) {
      isAllow = 0;

          setCpasserror("Your password must be at least 8 characters"); 
      }else{
      // insallow=0;
        setCpasserror('');
      }
      }else{
      isAllow = 0;
        setCpasserror('Password should contain one Capital, one small letter and a special character');
      }
    }

    if(isAllow == 0){
      return false;
    }

    axios
      .post(
        baseUrl+"/updatePassword",
        {
            // current_password:forgotPasswordFieldForm.current,
            password:forgotPasswordFieldForm.new,
            confirm_password:forgotPasswordFieldForm.confirm, 
            email:email
        },
        config
      )
      .then(function (response) {
        setShowSuccessPopup(true);
        console.log(response);
        if(response.data.success){
        setShowResponseMessage(JSON.stringify(response.data.message));
      setTimeout(() => {
        history.push('/login');
      }, 3500);}
        else
        setShowResponseMessage(JSON.stringify(response.data.error))

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [forgotPasswordFieldForm,setForgotPasswordFieldForm] = useState({
      new:null,confirm:null
  });
  const [emailIdFetching,setEmailIdFetching] = useState(false);
  if(link!==null){
    if(!emailIdFetching){
      setEmailIdFetching(true);
    axios
    .post(
      baseUrl+"/getForgotUrl",
      {
          // current_password:forgotPasswordFieldForm.current, 
          email_link:link,
      },
      config
    )
    .then(function (response) {
      setShowSuccessPopup(true);
      console.log(response);
      if(response.data.success) {
        setShowSuccessPopup(false);
        setEmail(response.data.email);
      }
      // setShowResponseMessage(JSON.stringify(response.data.message))
      else
      setShowResponseMessage(JSON.stringify(response.data.error))

    })
    .catch(function (error) {
      console.log(error);
    });
  }
  }
  useEffect(()=>{
      console.log('forgotPasswordFieldForm    ',forgotPasswordFieldForm)
  },[forgotPasswordFieldForm]);
  // const changeCurrent = (e) => {let temp = {...forgotPasswordFieldForm}; temp.current=e.target.value; setForgotPasswordFieldForm(temp);} 
  const changeNew = (e) => {let temp = {...forgotPasswordFieldForm}; temp.new=e.target.value; setForgotPasswordFieldForm(temp);} 
  const changeConfirm = (e) => {let temp = {...forgotPasswordFieldForm}; temp.confirm=e.target.value; setForgotPasswordFieldForm(temp);}
  const [showSuccessPopup,setShowSuccessPopup] = useState(false);
  const [showResponseMessage,setShowResponseMessage] = useState(null);
  const [emailreg, setEmailReg] = useState("");


  useEffect(()=>{
    console.log('showSuccessPopup   ',showSuccessPopup)
  if(showSuccessPopup)
  setTimeout(() => {
    setShowSuccessPopup(false)
  }, 1000);
  },[showSuccessPopup])
//   const changeNew = (e) => setForgotPasswordFieldForm({...changeAddressType,new:e.target.value});
//   const changeConfirm = (e) => setForgotPasswordFieldForm({...changeAddressType,confirm:e.target.value});
if(link===null || email===null)
return (
  <div>
  <form  onSubmit={ForgotPasswordEmailForm} method="post">
  <div class="row" style={{marginTop:'200px'}}>    
  <div class="col-md-3 col-sm-3"></div>
  <div class="col-md-6 col-sm-6">
      <label>Email</label><br />
        <input type="text" style={{textTransform:'none'}} name="cpassword" placeholder="Email Address"  value={emailreg}
        onChange={(e) => setEmailReg(e.target.value)}
        class="city" />
        <div className="invalid-feedback">
        {regemailmessage}
      </div>
      <button class="change">Forgot Password</button>
      <div class={`modal fade ${showSuccessPopup?'in':''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showSuccessPopup?"false":"true"} style={{display:`${showSuccessPopup?'block':'none'}`}}>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
        {/* ... */}
        {console.log('showResponseMessage   ',showResponseMessage,'type   ',typeof showResponseMessage)}
        <img src={showResponseMessage!==null && showResponseMessage.includes('is send')?'img/check.png':'img/red-cross.png'} alt="check" style={{width:"30px",height:"30px",marginRight:"5px"}}/> <p style={{margin:'0px',color:'#0a3a32'}}>{showResponseMessage}</p>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
                                                     </div>
  <div class="col-md-3 col-sm-3"></div>

                                                    
                                                     
                                                </div>

                                               
                                                
                                                
  </form>
  </div>
)
  return (
      <div className="my-account">
        <div className="container container-content ">
          <div className="row">
        


          <div class="col-md-2 col-sm-12 porfolio"></div>
           <div class="col-md-8 col-sm-12 porfolio">
                            <div class="tab-content" style={{border:'none'}}>
                                <div id="account-details" class="tab-pane fade in active">
                               
                                   
    
                                <div class="tab-content" style={{border:'none'}}>
                                    <div id="home" class="tab-pane fade in active">
                                      <div class="form">
                                            <form  onSubmit={forgotPasswordForm} method="post">
                                                <div class="row">    
                                                    <div class="col-md-6 col-sm-6">
                                                        <label>New Password</label><br />
                                                         <input type="password" style={{textTransform:'none'}} name="cpassword" placeholder="New Password"  value={forgotPasswordFieldForm.new} onChange={changeNew} class="city" />
                                                         <div className="invalid-feedback">
                                                          {npasserror}
                                                        </div>
                                                     </div>
                                                     
                                                     <div class="col-md-6 col-sm-6">
                                                        <label>Confirm Password</label><br />
                                                         <input type="password" style={{textTransform:'none'}} name="npassword" placeholder="Confirm Password"  value={forgotPasswordFieldForm.confirm} onChange={changeConfirm} class="city" />
                                                         <div className="invalid-feedback">
                                                          {cpasserror}
                                                        </div>
                                                     </div>
                                                     
                                                </div>

                                               
                                                
                                                <button class="change">Save change</button>
                                            </form>
                                            <div class={`modal fade ${showSuccessPopup?'in':''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showSuccessPopup?"false":"true"} style={{display:`${showSuccessPopup?'block':'none'}`}}>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
        {/* ... */}
        {console.log('showResponseMessage   ',showResponseMessage,'type   ',typeof showResponseMessage)}
        <img src={showResponseMessage!==null && showResponseMessage.includes('success')?'img/check.png':'img/red-cross.png'} alt="check" style={{width:"30px",height:"30px",marginRight:"5px"}}/> <p style={{margin:'0px',color:'#0a3a32'}}>{showResponseMessage}</p>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
                                        </div>
                                    </div>
                                      
                            </div>
                        </div>    
                        </div>
                    </div>
          <div class="col-md-2 col-sm-12 porfolio"></div>



          </div>
        </div>
      </div>
  );
}

export default ForgotPassword;