import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useHistory, useParams } from "react-router-dom";

import { cartTotalCount } from "../../redux/actions/cartActions";
import { baseUrl } from '../../constants';
import './profile.css'

function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [addressid, setaddressId] = useState(0);

  const [useraccount, setuserDetails] = useState("");
  // console.log(addresslist);
  // console.log(addressid)
  // console.log(addressbyid);
  const [shippingAddressForm, setShippingAddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
  const [billingAddressForm, setBillingddressForm] = useState({
    address_id: null,
    address_title: null,
    first_name: null,
    last_name: null,
    company: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phone: null,
    email: null,
  });
  const [billingAddressList, setBillingAddressList] = useState([]);
  const [shippingAddressList, setShippingAddressList] = useState([]);
  const [addressType, setAddressType] = useState("billing");
  const [billingDefaultAddress, setAsBillingDefaultAddress] = useState(false);
  const [shippingDefaultAddress, setAsShippingDefaultAddress] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);

  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("user");
    // console.log(token)
  }

  const config = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    if (addressType == "billing") {
      if (billingAddressList.length > 0) {
        let defaultAddress = billingAddressList.filter((obj) => {
          return obj.default_address === 1;
        });
        if (defaultAddress.length > 0) {
          setaddressId(defaultAddress[0].address_id);
          setBillingddressForm(defaultAddress[0]);
        } else {
          setaddressId(billingAddressList[0].address_id);
          setBillingddressForm(billingAddressList[0]);
        }
      } else {
        setaddressId(0);
      }
    } else {
      if (shippingAddressList.length > 0) {
        let defaultAddressShipping = shippingAddressList.filter((obj) => {
          return obj.default_address === 1;
        });
        if (defaultAddressShipping.length > 0) {
          setaddressId(defaultAddressShipping[0].address_id);
          setShippingAddressForm(defaultAddressShipping[0]);
        } else {
          setaddressId(shippingAddressList[0].address_id);
          setShippingAddressForm(shippingAddressList[0]);
        }
      } else {
        setaddressId(0);
      }
    }
  }, [addressType, billingAddressList, shippingAddressList]);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.setItem("cart_total_count", 0);
    dispatch(cartTotalCount(0));
    //window.reload();
    history.push("/");
  };
  useEffect(() => {
    if (saveSuccessful)
      setTimeout(() => {
        setSaveSuccessful(false);
      }, 2000);
  }, [saveSuccessful]);
  useEffect(() => {
    if (saveFailed)
      setTimeout(() => {
        setSaveFailed(false);
      }, 2000);
  }, [saveFailed]);
  const fillBillingAddressForm = (type, value) => {
    let temp = { ...billingAddressForm };
    if(type==='phone') temp[type] = value.replace(/\D/g,'');
    else temp[type] = value;
    setBillingddressForm(temp);
  };
  const fillShippingAddressForm = (type, value) => {
    let temp = { ...shippingAddressForm };
    if(type==='phone') temp[type] = value.replace(/\D/g,'');
    else temp[type] = value;
    setShippingAddressForm(temp);
  };

  useEffect(() => {
    axios
      .get(
        baseUrl+`/listAddress?type=${addressType}`,
        config
      )
      .then(function (res) {
        // handle success

        if (addressType === "billing") {
          setBillingAddressList(res.data.address_list);
        }

        if (addressType === "shipping") {
          setShippingAddressList(res.data.address_list);
        }

        // setaddressList(res.data.address_list);
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log("eee==", error.status);
      })
      .then(function () {
        // always executed
      });
  }, [addressType, saveSuccessful]);

  useEffect(() => {
    axios
      .get(baseUrl+"/getUserAccount", config)
      .then(function (res) {
        // handle success
        // console.log(response.data);
        if (res.data.success) setuserDetails(res.data.user);
        else history.push("/login");
        // setaddressId(response.data.address_list.address_id);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const billingAddress = (e) => {
    e.preventDefault();
    if (addressid == 0) {
      axios
        .post(
          baseUrl+"/createAddress",
          {
            address_title: billingAddressForm.address_title,
            first_name: billingAddressForm.first_name,
            last_name: billingAddressForm.last_name,
            company: billingAddressForm.company,
            country: billingAddressForm.country,
            city: billingAddressForm.city,
            street: billingAddressForm.street,
            zip: billingAddressForm.zip,
            phone: billingAddressForm.phone,
            email: billingAddressForm.email,
            address_type: "billing",
            default_address: billingDefaultAddress ? 1 : 0,
          },
          config
        )
        .then(function (response) {
          setSaveSuccessful(true);
          console.log(response);
        })
        .catch(function (error) {
          setSaveFailed(true);
          console.log(error);
        });
    } else {
      axios
        .post(
          baseUrl+"/updateAddress",
          {
            address_id: billingAddressForm.address_id,
            address_title: billingAddressForm.address_title,
            first_name: billingAddressForm.first_name,
            last_name: billingAddressForm.last_name,
            company: billingAddressForm.company,
            country: billingAddressForm.country,
            city: billingAddressForm.city,
            street: billingAddressForm.street,
            zip: billingAddressForm.zip,
            phone: billingAddressForm.phone,
            email: billingAddressForm.email,
            address_type: "billing",
            default_address: billingDefaultAddress ? 1 : 0,
          },
          config
        )
        .then(function (response) {
          console.log(response);
          setSaveSuccessful(true);
        })
        .catch(function (error) {
          console.log(error);
          setSaveFailed(true);
        });
    }
  };

  const shippingAddress = (e) => {
    e.preventDefault();
    if (addressid === 0) {
      axios
        .post(
          baseUrl+"/createAddress",
          {
            address_title: shippingAddressForm.address_title,
            first_name: shippingAddressForm.first_name,
            last_name: shippingAddressForm.last_name,
            company: shippingAddressForm.company,
            country: shippingAddressForm.country,
            city: shippingAddressForm.city,
            street: shippingAddressForm.street,
            zip: shippingAddressForm.zip,
            phone: shippingAddressForm.phone,
            email: shippingAddressForm.email,
            address_type: "shipping",
            default_address: shippingDefaultAddress ? 1 : 0,
          },
          config
        )
        .then(function (response) {
          setSaveSuccessful(true);
          console.log(response);
        })
        .catch(function (error) {
          setSaveFailed(true);
          console.log(error);
        });
    } else {
      axios
        .post(
          baseUrl+"/updateAddress",
          {
            address_id: shippingAddressForm.address_id,
            address_title: shippingAddressForm.address_title,
            first_name: shippingAddressForm.first_name,
            last_name: shippingAddressForm.last_name,
            company: shippingAddressForm.company,
            country: shippingAddressForm.country,
            city: shippingAddressForm.city,
            street: shippingAddressForm.street,
            zip: shippingAddressForm.zip,
            phone: shippingAddressForm.phone,
            email: shippingAddressForm.email,
            address_type: "shipping",
            default_address: shippingDefaultAddress ? 1 : 0,
          },
          config
        )
        .then(function (response) {
          setSaveSuccessful(true);
          console.log(response);
        })
        .catch(function (error) {
          setSaveFailed(true);
          console.log(error);
        });
    }
  };

  const toOrderlist = () => {
    history.push("/orders");
  };
  const changeAddressType = (val) => {
    setAddressType(val);
  };
  const addAddress = () => {
    setaddressId(0);
    if (addressType == "billing") {
      setBillingddressForm({
        // address_id: null,
        address_title: "",
        first_name: "",
        last_name: "",
        company: "",
        country: "",
        city: "",
        street: "",
        zip: "",
        phone: "",
        email: "",
      });
    } else {
      setShippingAddressForm({
        // address_id: null,
        address_title: "",
        first_name: "",
        last_name: "",
        company: "",
        country: "",
        city: "",
        street: "",
        zip: "",
        phone: "",
        email: "",
      });
    }
  };

  const deleteAddress = (id) => {

    if (window.confirm("Are you sure, you want to delete this address?")) {
      axios
      .post(baseUrl+"/deleteAddress", {
        address_id: id,
      },
      config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    
  };

  const editAddress = (id) => {
    // setSelectedAddressId(id);
    setaddressId(id);

    if (addressType == "billing") {
      billingAddressList.forEach((x) => {
        if (x.address_id === id) {
          setBillingddressForm({
            address_id: x.address_id,
            address_title: x.address_title,
            first_name: x.first_name,
            last_name: x.last_name,
            company: x.company,
            country: x.country,
            city: x.city,
            street: x.street,
            zip: x.zip,
            phone: x.phone,
            email: x.email,
            default_address: x.default_address,
          });
        }
      });
    } else {
      shippingAddressList.forEach((x) => {
        if (x.address_id === id) {
          setShippingAddressForm({
            address_id: x.address_id,
            address_title: x.address_title,
            first_name: x.first_name,
            last_name: x.last_name,
            company: x.company,
            country: x.country,
            city: x.city,
            street: x.street,
            zip: x.zip,
            phone: x.phone,
            email: x.email,
            default_address: x.default_address,
          });
        }
      });
    }
  };
  useEffect(() => {
    if (addressType == "billing") {
      if (billingAddressForm.default_address == 1) {
        setAsBillingDefaultAddress(true);
      } else {
        setAsBillingDefaultAddress(false);
      }
    } else {
      if (shippingAddressForm.default_address == 1) {
        setAsShippingDefaultAddress(true);
      } else {
        setAsShippingDefaultAddress(false);
      }
    }
  }, [billingAddressForm, shippingAddressForm, addressType]);

  return (
    <div>
      <div className="container container-content">
        <ul className="breadcrumb v2">
          <li>
            <a onClick={() => history.push("/")} style={{textTransform:"capitalize"}}>Home</a>
          </li>
          <li className="active" style={{textTransform:"capitalize"}}>My Account</li>
        </ul>
      </div>

      <div className="my-account">
        <div className="container container-content ">
          <div className="row">
            <div className="col-md-2 col-sm-2 howday">
              <div className="titlelt">
                <h2>
                  Hello, <strong> {useraccount.name} !</strong>
                </h2>
                <div className="login">
                  <ul className="nav ">
                    <li>
                      <a
                        style={{ fontWeight: 600 }}
                        onClick={() => history.push("/profile")}
                      >
                        <img src="img/Icon_Add.jpg" alt="Icon_User.jpg" />{" "}
                        Address
                      </a>
                    </li>

                    <li>
                      <a onClick={toOrderlist} style={{ cursor: "pointer" }}>
                        <img src="img/Icon_Listing.jpg" alt="Icon_User.jpg" />{" "}
                        My orders
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => history.push("/change-password")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="img/Icon_Lock.jpg" alt="Icon_User.jpg" />
                        change password
                      </a>
                    </li>
                    <li>
                      <a onClick={logout}>
                        <img src="img/Icon_Off.jpg" alt="Icon_User.jpg" /> log
                        out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-sm-10 porfolio">
              <div className="tab-content">
                <div id="account-details" className="tab-pane fade in active">
                  <ul className="nav nav-tabs customprofilecss">
                    <li className={addressType == "billing" ? "active" : ""}>
                      <a onClick={() => changeAddressType("billing")}>
                        Billing details
                      </a>
                    </li>
                    <li className={addressType == "shipping" ? "active" : ""}>
                      <a onClick={() => changeAddressType("shipping")}>
                        Shipping Details
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div
                      id="home"
                      className={
                        "tab-pane fade in " +
                        (addressType == "billing" ? "active" : "")
                      }
                    >
                      <div class="col-md-12 col-sm-12 addressCards">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4>
                            <b>Billing Addresses</b>
                          </h4>{" "}
                          <button className="addAddress" onClick={addAddress}>
                            Add
                          </button>
                        </div>
                        <div class="col-md-12 col-sm-12 porfolio">
                          <div class="row" style={{ height: "100%" }}>

                            {billingAddressList &&
                              billingAddressList.map((address) => (

                                <div
                                  class="col-md-4"
                                  style={{ margin: "10px 0px" }}
                                >
                                  <div
                                    class={
                                      address.default_address == 1
                                        ? "addres__card__selected"
                                        : "addres__card"
                                    }
                                    style={{height:"200px",overflowY:'auto'}}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 10,
                                      }}
                                    >
                                       <a 
                                        onClick={() =>
                                          deleteAddress(address.address_id)
                                        }
                                       style={{backgroundColor:"#0b3a32",cursor:"pointer",width:"25px",height:"25px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"4px"}}><i class="fa fa-trash" style={{color:"#f7d8c1",paddingTop:"2px"}} aria-hidden="true"></i></a>
                                      {" "}
                                      <h4>{address.address_title}</h4>{" "}
                                      <div
                                        class="form-check"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          title="Edit Address"
                                          value=""
                                          id="flexCheckChecked"
                                          checked={
                                            address.address_id == addressid
                                              ? true
                                              : false
                                          }
                                          style={{ marginRight: 10 }}
                                          onClick={() =>
                                            editAddress(address.address_id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <h5>
                                      {address.first_name +
                                        " " +
                                        address.last_name}
                                    </h5>
                                    <p>{address.phone}</p>

                                    <p>
                                      {address.company
                                        ? address.company + ","
                                        : ""}{" "}
                                      {address.street +
                                        "," +
                                        address.city +
                                        "," +
                                        address.country}
                                    </p>
                                    <p>{address.zip}</p>
                                    <div>
                                  
                                </div>
                                  </div>
                                 
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="form">
                        <h4 style={{ margin: "20px 0px" }}>
                          <b>
                            {billingAddressForm.address_title == "" ||
                            billingAddressList.length <= 0
                              ? "Create "
                              : ""}{" "}
                            Address {billingAddressForm.address_title}
                          </b>
                        </h4>

                        <form onSubmit={billingAddress}>
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <label>Address Title</label>
                              <br />
                              <input
                                type="text"
                                style={{textTransform:'none'}}
                                name="fname"
                                placeholder="Enter the address title"
                                required
                                className="country"
                                value={billingAddressForm.address_title}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "address_title",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>First Name</label>
                              <br />
                              <input
                                type="text"
                                name="fname"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your First name"
                                required
                                className="country"
                                value={billingAddressForm.first_name}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "first_name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>Last Name</label>
                              <br />
                              <input
                                type="text"
                                name="lname"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your Last Name"
                                required
                                className="city"
                                value={billingAddressForm.last_name}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "last_name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label>Company Name</label>
                              <br />
                              <input
                                type="text"
                                name="cname"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your Company Name"
                                className="city"
                                value={billingAddressForm.company}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "company",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Country</label>
                              <br />
                              <input
                                type="text"
                                name="country"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your Country"
                                required
                                className="country"
                                value={billingAddressForm.country}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "country",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>City /state</label>
                              <br />
                              <input
                                type="text"
                                name="city"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your City"
                                required
                                className="city"
                                value={billingAddressForm.city}
                                onChange={(e) =>
                                  fillBillingAddressForm("city", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <label>Street address</label>
                          <input
                            type="text"
                            name="city"
                            placeholder="Enter Your Street Address"
                            required
                            className="city"
                            value={billingAddressForm.street}
                            onChange={(e) =>
                              fillBillingAddressForm("street", e.target.value)
                            }
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>ZIP</label>
                              <br />
                              <input
                                type="text"
                                style={{textTransform:'none'}}
                                name="country"
                                placeholder=" ZIP"
                                //required
                                className="zipcode"
                                value={billingAddressForm.zip}
                                onChange={(e) =>
                                  fillBillingAddressForm("zip", e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>phone</label>
                              <br />
                              <input
                                type="text"
                                name="city"
                                placeholder="Enter Your Phone Number"
                                required
                                className="phone"
                                maxlength="10"
                                pattern=".{10,}"
                                title="length should be 10 digits"
                                value={billingAddressForm.phone}
                                onChange={(e) =>
                                  fillBillingAddressForm(
                                    "phone",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <label>email</label>
                          <br />
                          <input
                            type="email"
                            style={{textTransform:'none'}}
                            name="email"
                            placeholder="Enter Your Email Address"
                            required
                            className="gmail"
                            value={billingAddressForm.email}
                            onChange={(e) =>
                              fillBillingAddressForm("email", e.target.value)
                            }
                          />
                          {console.log(
                            "billingDefaultAddress",
                            billingDefaultAddress
                          )}
                          <div class="form-check" style={{ marginBottom: 10 }}>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={billingDefaultAddress}
                              onChange={() =>
                                setAsBillingDefaultAddress(
                                  !billingDefaultAddress
                                )
                              }
                              style={{ marginRight: 10 }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Set as default address
                            </label>
                          </div>
                          <button type="submit" className="change">
                            {addressid == 0 ? "Save Address" : "Update Address"}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div
                      id="menu1"
                      className={
                        "tab-pane fade in " +
                        (addressType == "shipping" ? "active" : "")
                      }
                    >
                      <div class="col-md-12 col-sm-12 addressCards">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4>
                            <b>Shipping Addresses</b>
                          </h4>{" "}
                          <button className="addAddress" onClick={addAddress}>
                            Add
                          </button>
                        </div>
                        <div class="col-md-12 col-sm-12 porfolio">
                          <div class="row" style={{ height: "100%" }}>
                            {shippingAddressList &&
                              shippingAddressList.map((address) => (
                                <div
                                  class="col-md-4"
                                  style={{ margin: "10px 0px" }}
                                >
                                  <div
                                    class={
                                      address.default_address == 1
                                        ? "addres__card__selected"
                                        : "addres__card"
                                    }
                                    style={{height:"200px",overflowY:'auto'}}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 10,
                                      }}
                                    >
                                      <a 
                                        onClick={() =>
                                          deleteAddress(address.address_id)
                                        }
                                       style={{backgroundColor:"#0b3a32",cursor:"pointer",width:"25px",height:"25px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"4px"}}><i class="fa fa-trash" style={{color:"#f7d8c1",paddingTop:"2px"}} aria-hidden="true"></i></a>
                                      {" "}
                                      <h4>{address.address_title}</h4>{" "}
                                      <div
                                        class="form-check"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          title="Edit Address"
                                          value=""
                                          id="flexCheckChecked"
                                          checked={
                                            address.address_id == addressid
                                              ? true
                                              : false
                                          }
                                          style={{ marginRight: 10 }}
                                          onClick={() =>
                                            editAddress(address.address_id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <h5>
                                      {address.first_name +
                                        " " +
                                        address.last_name}
                                    </h5>
                                    <p>{address.phone}</p>

                                    <p>
                                      {address.company
                                        ? address.company + ","
                                        : ""}{" "}
                                      {address.street +
                                        "," +
                                        address.city +
                                        "," +
                                        address.country}
                                    </p>
                                    <p>{address.zip}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="form">
                        <h4 style={{ margin: "20px 0px" }}>
                          <b>
                          {shippingAddressForm.address_title == "" ||
                            shippingAddressList.length <= 0
                              ? "Create "
                              : ""}{" "}
                            Address {shippingAddressForm.address_title}
                          </b>
                        </h4>
                        <form onSubmit={shippingAddress}>
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <label>Address Title</label>
                              <br />
                              <input
                                type="text"
                                name="fname"
                                style={{textTransform:'none'}}
                                placeholder="Enter the address title"
                                required
                                className="country"
                                value={shippingAddressForm.address_title}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "address_title",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>First Name</label>
                              <br />
                              <input
                                type="text"
                                name="fname"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your First name"
                                required
                                className="country"
                                value={shippingAddressForm.first_name}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "first_name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>Last Name</label>
                              <br />
                              <input
                                type="text"
                                name="lname"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your Last Name"
                                required
                                className="city"
                                value={shippingAddressForm.last_name}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "last_name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label>Company Name</label>
                              <br />
                              <input
                                type="text"
                                style={{textTransform:'none'}}
                                name="fname"
                                placeholder="Enter Your Company Name"
                                //required
                                className="country"
                                value={shippingAddressForm.company}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "company",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Country</label>
                              <br />
                              <input
                                type="text"
                                style={{textTransform:'none'}}
                                name="country"
                                placeholder="Enter Your Country"
                                required
                                className="country"
                                value={shippingAddressForm.country}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "country",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>City /state</label>
                              <br />
                              <input
                                type="text"
                                name="city"
                                style={{textTransform:'none'}}
                                placeholder="Enter Your City"
                                required
                                className="city"
                                value={shippingAddressForm.city}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "city",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <label>Street address</label>
                          <input
                            type="text"
                            name="city"
                            style={{textTransform:'none'}}
                            placeholder="Enter Your Street Address"
                            required
                            className="city"
                            value={shippingAddressForm.street}
                            onChange={(e) =>
                              fillShippingAddressForm("street", e.target.value)
                            }
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>ZIP</label>
                              <br />
                              <input
                                type="text"
                                name="country"
                                style={{textTransform:'none'}}
                                placeholder=" ZIP "
                                //required
                                className="zipcode"
                                value={shippingAddressForm.zip}
                                onChange={(e) =>
                                  fillShippingAddressForm("zip", e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <label>phone</label>
                              <br />
                              <input
                                type="text"
                                name="city"
                                placeholder="Enter Your Phone Number"
                                required
                                maxlength="10"
                                pattern=".{10,}"
                                title="length should be 10 digits"
                                className="phone"
                                value={shippingAddressForm.phone}
                                onChange={(e) =>
                                  fillShippingAddressForm(
                                    "phone",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <label>email</label>
                          <br />
                          <input
                            type="email"
                            style={{textTransform:'none'}}
                            name="city"
                            placeholder="Enter Your Email Address"
                            required
                            className="gmail"
                            value={shippingAddressForm.email}
                            onChange={(e) =>
                              fillShippingAddressForm("email", e.target.value)
                            }
                          />
                          <div class="form-check" style={{ marginBottom: 10 }}>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={shippingDefaultAddress}
                              onClick={() =>
                                setAsShippingDefaultAddress(
                                  !shippingDefaultAddress
                                )
                              }
                              style={{ marginRight: 10 }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Set as default address
                            </label>
                          </div>
                          <button type="submit" className="change">
                            {addressid == 0 ? "Save Address" : "Update Address"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`modal fade ${saveSuccessful ? "in" : ""}`}
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={saveSuccessful ? "false" : "true"}
        style={{ display: `${saveSuccessful ? "block" : "none"}` }}
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div
              class="modal-body"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* ... */}
              <img
                src="img/check.png"
                alt="check"
                style={{ width: "30px", height: "30px", marginRight: "5px" }}
              />{" "}
              <p style={{ margin: "0px", color: "#0a3a32" }}>
                Address Saved Successfully !!!
              </p>
            </div>
            {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
