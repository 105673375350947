import HomeCallto from "./HomeCallto";
import HomeCategory from "./HomeCategory";
import HomeFlash from "./HomeFlash";
import HomeSlider from "./HomeSlider";
import $ from "jquery";
import generateToken from "../../Components/auth/token";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeSliders } from "../../Utils/Services";

export default function Home() {
  const [cartDeviceId, setcartDeviceId] = useState("");
  const [home, setHome] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    $(".js-close").click();
    homeSliders().then((data) => setHome(data));
  }, []);
  console.log("hh", home.success);
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cartdeviceid") == null) {
      const token = generateToken(20);
      setcartDeviceId(token);
      localStorage.setItem("cartdeviceid", token);
    }
  }
  return typeof home.success != "undefined" ? (
    <div>
      <HomeSlider homesliders={home.banners} />
      <HomeCallto homesliders={home.banners} pdf_file={home.pdf_file} />
      <HomeCategory homecategory={home.categories} />
      <HomeFlash homeflash={home.banners} product={home.product} />
    </div>
  ) : null;
}
