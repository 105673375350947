import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import './order.css';

import axios from "axios";
import { baseUrl } from '../../constants';
function Thankyouorder() {
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState("");
  const [orderitems, setOrderItems] = useState("");
  console.log(orderDetails);
  // const cartid = '';
  let token = "";
  if (typeof window !== "undefined") {
    // cartid = localStorage.getItem('cartdeviceid')
    token = localStorage.getItem("user");
    // console.log(token)
  }

  let { id } = useParams();

  const config = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    axios
      .get(baseUrl+"/orderDetails?id="+id, config)
      .then(function (res) {
        setOrderDetails(res.data.order_list);
        setOrderItems(res.data.order_list.items);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  }, []);
  const toShop = () => {
    history.push("/shop");
  };
  return (
    <div>
      <div className="check-out">
        <div
          className="container vh-100"
          style={{ marginBottom: "50px" }}
        >
          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-md-12 col-sm-12">
              <h2 style={{ color: "#0a3a32" }}>
                <b>Thank you, your order has been placed.</b>
              </h2>
              <p>An email confirmation has been sent to you.</p>
              <div
                style={{
                  border: "1px solid #0a3a32",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <p>
                  Order Id :{" "}
                  <b style={{ color: "#0a3a32" }}>
                    {orderDetails.order_display_id}
                  </b>
                </p>
                <p>
                  Order Total :{" "}
                  <b style={{ color: "#0a3a32" }}>
                    AED {orderDetails.grand_total}
                  </b>
                </p>
            
                <p>
                  Delivered to : <b style={{ color: "#0a3a32" }}>{orderDetails.customer_name}</b>
                  
                </p>
                {/* <p>Mob : +971 23456789</p> */}
                <p>Email : {orderDetails.customer_email}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h4 style={{ marginTop: "20px", color: "#0a3a32" }}>
                  Order Summary
                </h4>

                <div>
                  <table className="ordersummarytable">
                    <thead>
                      <tr>
                        <th style={{ color: "#0a3a32",width:'25%' }} >product</th>
                        <th style={{ color: "#0a3a32",width:'45%' }} >product name</th>
                        <th style={{ color: "#0a3a32" ,width:'15%'}}>QTY</th>
                        {/* <th></th> */}
                        <th style={{ color: "#0a3a32",width:'15%' }}>total</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                    {orderitems &&
                              orderitems.map((item) => (
                      <tr>
                        <td style={{width:'25%' }}>
                          <img
                            src={item.product_image}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td style={{width:'45%' }}>{item.product_name}</td>
                        <td style={{width:'15%' }}>{item.quantity}</td>
                        {/* <td></td> */}
                        <td style={{width:'15%' }}>AED {item.item_sub_total}</td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <button
              onClick={toShop}
                className="btn zoa-btn "
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                  padding: "13px 30px",
                }}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyouorder;
