import * as types from "../constants/types";
import axios from "axios";

export const handleMenu = (status) => {
  console.log("action.type", status);
  // console.log("sddsds",`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`)
  // const res = await axios.get(`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`);
  // dispatch({
  //     type:types.GET_PRODUCTS,
  //     payload : res.data
  // })

  return {
    type: types.MENU_OPEN,
    payload: status,
  };
};
export const fetchproducts = (products) => {
  // console.log("sddsds",`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`)
  // const res = await axios.get(`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`);
  // dispatch({
  //     type:types.GET_PRODUCTS,
  //     payload : res.data
  // })
  return {
    type: types.GET_PRODUCTS,
    payload: products,
  };
};

export const addProducts = (products) => {
  console.log("addProducts   222");
  // console.log("sddsds",`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`)
  // const res = await axios.get(`http://localhost/new-ecommerce/api/v1/getProducts?category_id=${category_ids}&color=&size=&price=`);
  // dispatch({
  //     type:types.GET_PRODUCTS,
  //     payload : res.data
  // })

  return {
    type: "ADD_MORE_PRODUCTS",
    payload: products,
  };
};
