import React from "react";

import { Provider, useDispatch } from "react-redux";
import { HashRouter as Router, Routes, Route, Switch } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./views/Home";
import Shop from "./views/Shop";
import Cart from "./views/Cart";
import ProductDetails from "./views/Shop/ProductDetails";
import Checkout from "./views/Cart/Checkout";
import Login from "./views/Profile/Login";
import Profile from "./views/Profile";
import ChangePassword from "./views/ChangePassword";
import ForgotPassword from "./views/ForgotPassword";
import Order from "./views/Order";
import Thankyouorder from "./views/Order/Thankyou";
import OrderDetails from "./views/Order/OrderDetails";
import Subcategory from "./views/Category/SubCategory";
import ListCategory from "./views/Category/ListCategory";
import Products from "./views/Shop";
import SubCategoryProducts from "./views/Category/SubCategoryProducts";
import About from "./views/Cms/About";
import Terms from "./views/Cms/Terms";
import Privacy from "./views/Cms/Privacy";
import ReturnPolicy from "./views/Cms/Return";
import PaymentRecieve from "./views/Cart/PaymentRecieve";
import PaymentCancel from "./views/Cart/PaymentCancel";
import "./index.css";

import ScrollToTop from "./scrollToTop";
import axios from "axios";
import { triggerLogout } from "./redux/actions/cartActions";
import Contact from "./views/Cms/Contact";
import { useHistory } from "react-router";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  axios.interceptors.request.use(
    function (config) {
      console.log("request interceptor", config);
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      console.log("response  ", response);
      if (response.data.status === false)
        if (response.data.token === false)
          if (response.data.message.includes("token")) {
            dispatch(triggerLogout());
          }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />{" "}
          <Route exact path="/shop" component={Shop} />{" "}
          <Route exact path="/shop/:category_slug" component={Shop} />{" "}
          <Route exact path="/products/:search" component={Products} />{" "}
          <Route
            exact
            path="/product/:productid/:homeBanner"
            component={ProductDetails}
          />{" "}
          <Route exact path="/product/:productid" component={ProductDetails} />{" "}
          <Route exact path="/cart" component={Cart} />{" "}
          <Route exact path="/checkout" component={Checkout} />{" "}
          <Route exact path="/login" component={Login} />{" "}
          <Route exact path="/profile" component={Profile} />{" "}
          <Route exact path="/change-password" component={ChangePassword} />{" "}
          <Route exact path="/forgot-password" component={ForgotPassword} />{" "}
          <Route exact path="/orders" component={Order} />{" "}
          <Route exact path="/thankyouorder/:id" component={Thankyouorder} />{" "}
          <Route exact path="/orderdetails/:orderid" component={OrderDetails} />{" "}
          <Route exact path="/subcategory/:category" component={Subcategory} />{" "}
          <Route
            exact
            path="/shopsubcategory/:categoryN/:subselCategory"
            component={SubCategoryProducts}
          />{" "}
          <Route
            exact
            path="/shopsubcategory/:category/"
            component={SubCategoryProducts}
          />{" "}
          <Route exact path="/listcategory" component={ListCategory} />
          <Route exact path="/paymentRecieve" component={PaymentRecieve} />{" "}
          <Route exact path="/PaymentCancel" component={PaymentCancel} />{" "}
          <Route exact path="/about-us" component={About} />{" "}
          <Route exact path="/contact-us" component={Contact} />{" "}
          <Route exact path="/terms-and-conditions" component={Terms} />{" "}
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/refund-policy" component={ReturnPolicy} />
        </Switch>{" "}
      </Layout>{" "}
    </Router>
  );
}

export default App;
