import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchproducts, addProducts } from "../../redux/actions/productActions";
import { baseUrl } from "../../constants";
import '../../main'
import $ from 'jquery';
import jQuery from 'jquery';



function Products({ showFilter, setShowFilter }) {
  $(document).ready(function() {
  $('.filter-collection-left > button').on('click', function() {
    console.log('function called filter')
    $('.wrappage').addClass('show-filter');
});
$('.filtermobilebutton').on('click', function() {
    console.log('function called filter')
    $('.wrappage').addClass('show-filter');
});
// filtermobilebutton
$(document).bind("mouseup touchend", function(e) {
    var container = jQuery("#filter-sidebar");
    if (!container.is(e.target) // if the target of the click isn't the container...
        &&
        container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        $('.wrappage').removeClass('show-filter');
    }
});
$('.close-sidebar-collection').on('click', function() {
    $('.wrappage').removeClass('show-filter');
});
  });
  const [produc, setProduc] = useState([]);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [category, setCategory] = useState([]);
  const [price, setPrice] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const { products } = useSelector((state) => state);
  const temp = useSelector((state) => state);
  const [gridType, setGridType] = useState("grid");

  let { search, category_slug } = useParams();
  var url_param = "PRODUCTS";
  //const url_param = router.query.category
  // const category_slug = router.query["category"];
  var searchkey;
  if (typeof search === "undefined") {
    searchkey = "";

    url_param = "PRODUCTS";
  } else {
    searchkey = search;
    // setCategory(category);
    url_param = category;
  }
  if (typeof category_slug === "undefined") {
    url_param = "PRODUCTS";
  } else {
    //setCategory(category_slug);
    url_param = category_slug;
  }

  const history = useHistory();

  const loadmore = () => {
    let temp = page;
    setPage(temp + 1);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let loadPage;

    var cat_params;
    if (typeof category_slug != "undefined") {
      //setCategory(category_slug);
      cat_params = category_slug;
    } else {
      cat_params = "";
    }
    axios
      .get(
        baseUrl +
          `/getProducts?category_id=${category}&color=${color}&size=${size}&price=${price}&page=${page}&category_slug=${cat_params}&search=${searchkey}&sort_by=${sort}`,
        {}
      )
      .then((res) => {
        if (page == 1) dispatch(fetchproducts(res.data));
        else dispatch(addProducts(res.data));
      })
      .catch((err) => {});
  }, [size, color, category, price, page, produc, searchkey, page, sort]);
  const changeGridType = (val) => {
    setGridType(val);
  };
  const changeCategory = (id) => {
    setCategory(id);
    setPage(1);
  };
  const changeColor = (id) => {
    setColor(id);
    setPage(1);
  };
  const changePrice = (id) => {
    setPrice(id);
    setPage(1);
  };
  const changeSize = (id) => {
    setSize(id);
    setPage(1);
  };
  const changeSort = (id) => {
    setSort(id);
    setPage(1);
  };
  const handleRemoveFilters = () => {
    setPage(1);
    setCategory([]);
    setColor([]);
    setPrice([]);
    setSize([]);
    setSort("");
  };
  // const [showFilter,setShowFilter] = useState(false);
  if (products.loading) {
    const produstList = products.products.products;
    const categoryList = products.products.categories;
    const colorList = products.products.color_attributes;
    const sizeList = products.products.size_attributes;
    return (
      <>
        <div className="container container-content ">
          <div className="filter-collection-left hidden-lg hidden-md">
            <button class="filtermobilebutton btn" onClick={()=>console.log('clickededc')}>
              <i className="zoa-icon-filter"></i> Filter
            </button>
            <a
                  onClick={() => {
                    handleRemoveFilters();
                  }}
                  className="btn-del hover-anchor"
                  style={{
                    fontSize: "12px",
                    margin: "15px 20px",
                    cursor: "pointer",
                  }}
                >
                  <label style={{ marginRight: 5 }}>Clear Filters</label>
                  <i
                    className="ion-ios-close-empty"
                    style={{ fontSize: 14 }}
                  ></i>
                </a>
          </div>
          <div className="wrappage">
            <div
              className="col-xs-12 hidden-md hidden-lg col-left collection-sidebar"
              id="filter-sidebar"
            >
              <div className="close-sidebar-collection hidden-lg hidden-md">
                <span>Filter</span>
                <i className="icon_close ion-close"></i>
              </div>
              <div className="widget-filter filter-cate no-pd-top">
                <h3>Categories</h3>
                <ul>
                  {console.log('category   ',category)}
                  {categoryList &&
                    categoryList.map((categoryy, categoryIndex) => (
                      <label
                        className="control control--radio"
                        key={categoryIndex}
                      >
                        {categoryy.category_name}
                        <input
                          type="radio"
                          name="categoryM"
                          checked={category===categoryy.category_id}
                          onClick={() => {console.log('category   ',category,'categoryy.category_id    ',categoryy.category_id);changeCategory(categoryy.category_id)}}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    ))}
                </ul>
              </div>
              <div className="widget-filter filter-cate filter-color">
                <h3>Filter by colors</h3>
                <ul>
                  {colorList &&
                    colorList.map((colorr, colorIndex) => (
                      <label
                        className="control control--radio"
                        key={colorIndex}
                      >
                        {colorr.attribute_name}
                        <input
                          type="radio"
                          name="colorM"
                          checked={color===colorr.attribute_value_id}
                          onClick={() => changeColor(colorr.attribute_value_id)}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    ))}
                </ul>
              </div>
              <div className="widget-filter filter-cate filter-size">
                <h3>Filter by sizes</h3>
                <ul>
                  {sizeList &&
                    sizeList.map((sizee, sizeIndex) => (
                      <label className="control control--radio" key={sizeIndex}>
                        {sizee.attribute_name}
                        <input
                          type="radio"
                          name="sizeM"
                          checked={size===sizee.attribute_value_id}
                          onClick={() => changeSize(sizee.attribute_value_id)}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    ))}
                </ul>
              </div>
              <div className="widget-filter filter-cate filter-size">
                <h3>Filter by price</h3>
                <ul>
                  <label className="control control--radio">
                    0 - AED 50
                    <input
                      type="radio"
                      name="priceM"
                      checked={price==="0-50"}
                      onClick={() => changePrice("0-50")}
                    />
                    <div className="control__indicator"></div>
                  </label>
                  <label className="control control--radio">
                    AED 51 - AED 100
                    <input
                      type="radio"
                      name="priceM"
                      checked={price==="51-100"}
                      onClick={() => changePrice("51-100")}
                    />
                    <div className="control__indicator"></div>
                  </label>
                  <label className="control control--radio">
                    AED 101 - AED 200
                    <input
                      type="radio"
                      name="priceM"
                      checked={price==="101-200"}
                      onClick={() => changePrice("101-200")}
                    />
                    <div className="control__indicator"></div>
                  </label>
                </ul>
                <div className="widget-filter filter-cate filter-size">
                  <h3>Sort by:</h3>
                  <ul>
                    <label className="control control--radio">
                      Alphabetically, A-Z
                      <input
                        type="radio"
                        name="sortM"
                      checked={sort==="letter_asc"}
                        onClick={() => changeSort("letter_asc")}
                      />
                      <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio">
                      Alphabetically, Z-A
                      <input
                        type="radio"
                        name="sortM"
                      checked={sort==="letter_desc"}
                        onClick={() => changeSort("letter_desc")}
                      />
                      <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio">
                      Price, high to low
                      <input
                        type="radio"
                        name="sortM"
                      checked={sort==="price_desc"}
                      onClick={() => changeSort("price_desc")}
                      />
                      <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio">
                      Price, low to high
                      <input
                        type="radio"
                        name="sortM"
                      checked={sort==="price_asc"}
                      onClick={() => changeSort("price_asc")}
                      />
                      <div className="control__indicator"></div>
                    </label>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div className="shop-top">
            {produstList && produstList.length > 0 && (
              <div className="shop-element left">
                <ul className="js-filter">
                  <li className="filter filter-static hidden-xs hidden-sm">
                    <a style={{ color: "#0a3a32 !important" }}>
                      <i className="zoa-icon-filter"></i>Filter products
                    </a>
                    
                    <div className="dropdown-menu fullw">
                      <div className="col-md-15 col-lg-15 widget-filter filter-cate">
                        <h3 style={{ color: "#0a3a32 !important" }}>
                          Categories
                        </h3>
                        <ul>
                          {categoryList &&
                            categoryList.map((categoryy, categoryIndex) => (
                              <label
                                className="control control--radio"
                                key={categoryIndex}
                              >
                                {categoryy.category_name}
                                <input
                                  type="radio"
                                  name="category"
                                  checked={category===categoryy.category_id}
                                  onClick={() =>
                                    changeCategory(categoryy.category_id)
                                  }
                                />
                                <div className="control__indicator"></div>
                              </label>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-15 col-lg-15 widget-filter filter-cate filter-color">
                        <h3 style={{ color: "#0a3a32" }}>Filter by colors</h3>
                        <ul>
                          {colorList &&
                            colorList.map((colorr, colorIndex) => (
                              <label
                                className="control control--radio"
                                key={colorIndex}
                              >
                                {colorr.attribute_name}
                                <input
                                  type="radio"
                                  name="color"
                                  checked={color===colorr.attribute_value_id}
                                  onClick={() =>
                                    changeColor(colorr.attribute_value_id)
                                  }
                                />
                                <div className="control__indicator"></div>
                              </label>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-15 col-lg-15 widget-filter filter-cate filter-size">
                        <h3 style={{ color: "#0a3a32" }}>Filter by sizes</h3>
                        <ul>
                          {sizeList &&
                            sizeList.map((sizee, sizeIndex) => (
                              <label
                                className="control control--radio"
                                key={sizeIndex}
                              >
                                {sizee.attribute_name}
                                <input
                                  type="radio"
                                  name="size"
                                  checked={size===sizee.attribute_value_id}
                                  onClick={() =>
                                    changeSize(sizee.attribute_value_id)
                                  }
                                />
                                <div className="control__indicator"></div>
                              </label>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-15 col-lg-15 widget-filter filter-cate filter-size">
                        <h3 style={{ color: "#0a3a32" }}>Filter by price</h3>
                        <ul>
                          <label className="control control--radio">
                            0 - AED 50
                            <input
                              type="radio"
                              name="price"
                              checked={price==='0-50'}
                              onClick={() => changePrice("0-50")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            AED 51 - AED 100
                            <input
                              type="radio"
                              name="price"
                              checked={price==='51-100'}
                              onClick={() => changePrice("51-100")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            AED 101 - AED 200
                            <input
                              type="radio"
                              name="price"
                              checked={price==='101-200'}
                              onClick={() => changePrice("101-200")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                         
                        </ul>
                      </div>
                      <div className="col-md-15 col-lg-15 widget-filter filter-cate filter-size">
                        <h3 style={{ color: "#0a3a32" }}>Sort by: </h3>
                        <ul>
                          <label className="control control--radio">
                            Alphabetically, A-Z
                            <input
                              type="radio"
                              name="sort"
                              checked={sort==='letter_asc'}
                              onClick={() => changeSort("letter_asc")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            Alphabetically, Z-A
                            <input
                              type="radio"
                              name="sort"
                              checked={sort==='letter_desc'}
                              onClick={() => changeSort("letter_desc")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            Price, high to low
                            <input
                              type="radio"
                              name="sort"
                              checked={sort==='price_desc'}
                              onClick={() => changeSort("price_desc")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            Price, low to high
                            <input
                              type="radio"
                              name="sort"
                              checked={sort==='price_asc'}
                              onClick={() => changeSort("price_asc")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                        </ul>
                    
                      </div>
                
                    </div>
                  </li>
                 
                </ul>
                <a
                  onClick={() => {
                    handleRemoveFilters(); 
                  }}
                  className="btn-del hover-anchor hidden-sm hidden-xs"
                  style={{
                    fontSize: "12px",
                    margin: "15px 20px",
                    cursor: "pointer",
                  }}
                >
                  <label style={{ marginRight: 5 }}>Clear Filters</label>
                  <i
                    className="ion-ios-close-empty"
                    style={{ fontSize: 14 }}
                  ></i>
                </a>
              </div>
            )}

            <div className="shop-element right">
              {/* <span>Showing 1-15 of 69 products</span> */}
              <div className="view-mode view-group">
                <a
                  className={
                    "list-icon list " + (gridType == "list" ? "active" : "")
                  }
                  onClick={() => changeGridType("list")}
                >
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    "grid-icon col " + (gridType == "grid" ? "active" : "")
                  }
                  onClick={() => changeGridType("grid")}
                >
                  <i className="zoa-icon-view-2"></i>
                </a>
                {/* <!-- <a className="grid-icon col2"><i className="zoa-icon-view-3"></i></a> --> */}
              </div>
            </div>
          </div>

          <div
            className={
              "product-collection-grid product-grid bd-bottom " +
              (gridType == "list" ? "product-list product-grid-v2" : "")
            }
          >
            {produstList && produstList.length > 0 ? (
              <>
                <div className="row engoc-row-equal">
                  {produstList.map((productss, productssIndex) => (
                    <div
                      className="col-xs-6 col-sm-4 col-md-15 col-lg-15 product-item"
                      key={productssIndex}
                    >
                      <div className="product-img">
                        <a href="">
                          <Link
                            to={{
                              pathname: "/product/" + productss.product_id,
                            }}
                          >
                            <img
                              src={productss.product_image}
                              alt=""
                              className="img-responsive"
                              style={{ height: "263px", objectFit: "cover" }}
                            />
                          </Link>
                        </a>
                        {productss.have_offer == "1" ? (
                          <div className="ribbon zoa-hot">
                            <span>SALE</span>
                          </div>
                        ) : null}

                        <div className="product-button-group">
                          {/* <!-- <a className="zoa-btn zoa-quickview">
                                        <span className="zoa-icon-quick-view"></span>
                                    </a>
                                    <a className="zoa-btn zoa-wishlist">
                                        <span className="zoa-icon-heart"></span>
                                    </a> --> */}
                          {/* <a href="cart" className="zoa-btn zoa-addcart">
                        <span
                          className="zoa-icon-cart"
                          style={{ color: "#fff" }}
                        ></span>
                      </a> */}
                        </div>
                      </div>
                      <div className="product-info text-center">
                        <h3 className="product-title">
                          <Link
                            to={{
                              pathname: "/product/" + productss.product_id,
                            }}
                          >
                            {productss.product_name}
                          </Link>
                        </h3>
                        <div className="product-price">
                          {productss.have_offer == "1" ? (
                            <div>
                              <span
                                style={{
                                  color: "#888",
                                  marginRight: "5px",
                                  textDecoration: "line-through",
                                }}
                              >
                                {" "}
                                AED {productss.product_price}
                              </span>
                              <span style={{ color: "#0a3a32" }}>
                                AED {productss.product_offer_price}
                              </span>
                            </div>
                          ) : (
                            <span style={{ color: "#0a3a32" }}>
                              AED {productss.product_price}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  {produstList.length !== 0 &&
                    produstList.length !==
                      products.products.product_total_count && (
                      <button
                        className="zoa-btn btn-loadmore"
                        style={{ color: "#fff !important" }}
                        onClick={loadmore}
                      >
                        Load more
                      </button>
                    )}
                </div>
              </>
            ) : (
              <div style={{ justifyContent: "center", display: "flex", flexDirection:"column",textAlign:"center" }}>
               <p> No Products found!!</p>
                <a
                  onClick={() => {
                    handleRemoveFilters();
                  }}
                  className="btn-del hover-anchor"
                  style={{
                    fontSize: "12px",
                    margin: "15px 20px",
                    cursor: "pointer",
                  }}
                >
                  <label style={{ marginRight: 5 }}>Clear Filters</label>
                  <i
                    className="ion-ios-close-empty"
                    style={{ fontSize: 14 }}
                  ></i>
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
  
}

export default Products;
