import React from "react";

function HomeSlider({ homesliders }) {
  console.log(homesliders);
  return (
    <div>
      {homesliders.map((homeslider) => (
        <div>
          {homeslider.banner_type == "2" ? (
            <video
              width="100%"
              autoplay="autoplay"
              loop
              muted
              playsinline
              style={{
                height: "100vh",

                objectFit: "cover",
              }}
            >
              <source
                src={homeslider.banner_desktop_video_url}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : null}
          <img
            src="img/logo_w.png"
            alt=""
            style={{
              position: "absolute",
              top: "50%",
              margin: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zIndex: "1",
              zIndex: "1",
              bottom: "50%",
            }}
            className=" mobile-center-logo"
          />
          <div
            class="mouse_scroll"
            style={{
              bottom: "0",
              position: "absolute",
              left: "50%",
              right: "50%",
            }}
          >
            <div>
              <span class="m_scroll_arrows unu"></span>
              <span class="m_scroll_arrows doi"></span>
              <span class="m_scroll_arrows trei"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomeSlider;
